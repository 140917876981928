import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { fetchStartupData } from 'entries/base/services/initial-data/fetch-startup-data';
import { connectivityStatusClient } from 'services/connectivity/status/client';
import { initializeApp } from 'services/startup/initialize/initialize-app';

import { connectAndLogin } from './connect-and-login';
import { handleInitializationError } from './handle-initialization-error';
import type { ServerConnectOptions } from './types';

const log = debug(DebugLogsNamespace.AppServerConnection);

export async function reinitializeApp(options: ServerConnectOptions): Promise<void> {
  const { isGhost } = options;

  Sentry.addBreadcrumb({
    type: 'debug',
    category: 'reconnect',
    message: `Reinitializing app`,
    data: {
      navigatorOnline: navigator.onLine,
      internetStatus: connectivityStatusClient.getInternetStatus(),
      networkStatus: connectivityStatusClient.getNetworkStatus(),
    },
  });

  log('Fetching startup data');
  const startupDataResult = await fetchStartupData(isGhost);

  if (startupDataResult.success === false) {
    const error = startupDataResult.failReason;

    await handleInitializationError(error, false);

    // do not attempt to connect any further
    return;
  }

  const { startupData } = startupDataResult;
  initializeApp(startupData);
  log('Startup data fetched and stored');

  await connectAndLogin(options);
}
