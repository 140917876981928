import { css } from '@emotion/css';
import { RadiusToken, ShadowToken, DesignToken } from '@livechat/design-system-react-components';

export const homeScreenTourStep = css`
  width: 380px;
  video {
    width: 340px;
    max-height: 100%;
  }
`;

export const homeScreenBubbleTourStep = css`
  left: 40% !important;
  .lc-light-theme.lc-UserGuideBubbleStep-module__user-guide-bubble-step___85b6K {
    width: 380px;
  }
`; //TODO update this way of selecting by class when DS will release fix for this - https://github.com/livechat/design-system/issues/1601

export const navigationStyles = css`
  background-color: var(${DesignToken.SurfacePrimaryDefault});
  border-radius: var(${RadiusToken.Radius3});
  box-shadow: var(${ShadowToken.ShadowTourAnimationEnd});
  a {
    opacity: 1;
    color: var(${DesignToken.ContentLockedBlack});
  }
`;

export const spotlightStepStyles = css`
  background-color: var(${DesignToken.SurfaceLockedDefault});
  box-shadow: var(${ShadowToken.ShadowTourAnimationEnd});
  color: var(${DesignToken.ContentBasicPrimary}) !important;
  div {
    background-color: unset;
    div {
      background-color: var(${DesignToken.SurfaceModerateHover});
      color: var(${DesignToken.DecorGray900});
    }
  }
`;

export const copilotStepStyles = css`
  box-shadow: var(${ShadowToken.ShadowTourAnimationEnd});
`;
