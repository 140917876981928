// @ts-strict-ignore

import { ChatEventType } from 'constants/chat-event-type';
import { ChatThreadStatus, type ChatThreadVisualStatus } from 'constants/chat-thread-status';
import { ChatType } from 'constants/chat-type';
import { UserType } from 'constants/user-type';
import type { KeyMap } from 'helpers/interface';
import type { IBots } from 'store/entities/bots/interfaces';
import { type IActionWithPayload } from 'store/helper';

import { ChatsEntitiesActionNames } from '../actions';
import {
  type INewAttachmentMessagePayload,
  type INewEventActionPayload,
  type INewMessagePayload,
  type INewSystemMessagePayload,
  type IOtherChat,
  type IStartedThread,
  type ISupervisedChat,
  type IUnassignedChat,
  type ChatEventEntity,
  type ChatThreadEntity,
  type IAttachmentMessage,
  type IMessage,
  type IMyChat,
  type IQueuedChat,
  type IRichMessage,
  type ISurveyMessage,
  type ISystemMessage,
  type ChatEventEntityWithAuthor,
} from '../interfaces';

export function isSystemMessage(event: ChatEventEntity): event is ISystemMessage {
  return event && event.type === ChatEventType.Event;
}

export function isRichMessage(event: ChatEventEntity): event is IRichMessage {
  return event && event.type === ChatEventType.RichMessage;
}

export function isQueuedChat(thread: ChatThreadEntity): thread is IQueuedChat {
  return thread && thread.type === ChatType.Queued;
}

export function isOtherChat(thread: ChatThreadEntity): thread is IOtherChat {
  return thread && thread.type === ChatType.Other;
}

export function isUnassignedChat(thread: ChatThreadEntity): thread is IUnassignedChat {
  return thread && thread.type === ChatType.Unassigned;
}

export function isMyChat(thread: ChatThreadEntity): thread is IMyChat {
  return thread && thread.type === ChatType.My;
}

export function isSupervisedChat(thread: ChatThreadEntity): thread is ISupervisedChat {
  return thread && thread.type === ChatType.Supervised;
}

export function isThreadWithStatus(thread: ChatThreadEntity): thread is IMyChat | ISupervisedChat | IUnassignedChat {
  return isMyChat(thread) || isSupervisedChat(thread) || isUnassignedChat(thread);
}

export function getThreadVisualStatus(thread: ChatThreadEntity): ChatThreadVisualStatus {
  return (thread as IMyChat | IQueuedChat)?.visualStatus;
}

export function isThreadWithStartedTimestamp(
  thread: ChatThreadEntity,
): thread is IMyChat | ISupervisedChat | IUnassignedChat {
  return !!(thread as IStartedThread)?.startedTimestamp;
}

export function isSurvey(event: ChatEventEntity): event is ISurveyMessage {
  return event && event.type === ChatEventType.FilledForm;
}

export function isMessage(event: ChatEventEntity): event is IMessage {
  return event && event.type === ChatEventType.Message;
}

export function isAttachmentMessage(event: ChatEventEntity): event is IAttachmentMessage {
  return event && event.type === ChatEventType.Attachmment;
}

export function isEventWithText(event: ChatEventEntity): event is IRichMessage | ISystemMessage | IMessage {
  return isMessage(event) || isSystemMessage(event) || isRichMessage(event);
}

export function isEventWithStatus(event: ChatEventEntity): event is IMessage | IRichMessage | IAttachmentMessage {
  return isMessage(event) || isRichMessage(event) || isAttachmentMessage(event);
}

export function isEventWithAuthor(
  event: ChatEventEntity,
): event is IRichMessage | IAttachmentMessage | IMessage | ISurveyMessage {
  return isMessage(event) || isRichMessage(event) || isAttachmentMessage(event) || isSurvey(event);
}

export function isClosedThread(thread: ChatThreadEntity): boolean {
  return isThreadWithStatus(thread) && thread.status === ChatThreadStatus.Closed;
}

export function isActiveThread(thread: ChatThreadEntity): boolean {
  return isThreadWithStatus(thread) && thread.status === ChatThreadStatus.Active;
}

export function isClosedThreadType(threadType: ChatType, threadStatus: ChatThreadStatus): boolean {
  const isUnassigned = threadType === ChatType.Unassigned;
  const isMy = threadType === ChatType.My;
  const isSupervised = threadType === ChatType.Supervised;

  return (isMy || isSupervised || isUnassigned) && threadStatus === ChatThreadStatus.Closed;
}

export function isAbleToTransferChat(
  threadType: ChatType,
  threadStatus: ChatThreadStatus,
  isQueuedChatVisuallyClosed: boolean,
): boolean {
  const isMyChat = threadType === ChatType.My;
  const isQueuedChat = threadType === ChatType.Queued && !isQueuedChatVisuallyClosed;
  const isSupervisedChat = threadType === ChatType.Supervised;
  const isUnassignedChat = threadType === ChatType.Unassigned;
  const isClosedThread = isClosedThreadType(threadType, threadStatus);

  const isAbleToTransfer = (isQueuedChat || isMyChat || isUnassignedChat) && !isSupervisedChat && !isClosedThread;

  return isAbleToTransfer;
}

export function isActiveUnassignedChat(thread: ChatThreadEntity): boolean {
  return isUnassignedChat(thread) && !isClosedThread(thread);
}

export function isNewMessageAction(
  action: IActionWithPayload<string, INewEventActionPayload>,
): action is IActionWithPayload<string, INewMessagePayload> {
  return (action.type as ChatsEntitiesActionNames) === ChatsEntitiesActionNames.NEW_MESSAGE;
}

export function isNewSystemMessageAction(
  action: IActionWithPayload<string, INewEventActionPayload>,
): action is IActionWithPayload<string, INewSystemMessagePayload> {
  return (action.type as ChatsEntitiesActionNames) === ChatsEntitiesActionNames.NEW_SYSTEM_MESSAGE;
}

export function isNewAttachmentAction(
  action: IActionWithPayload<string, INewEventActionPayload>,
): action is IActionWithPayload<string, INewAttachmentMessagePayload> {
  return (action.type as ChatsEntitiesActionNames) === ChatsEntitiesActionNames.NEW_ATTACHMENT;
}

export function isNewEventNotificationTriggerAction(
  action: IActionWithPayload<string, INewEventActionPayload>,
): action is IActionWithPayload<string, INewMessagePayload> {
  return isNewMessageAction(action) || isNewSystemMessageAction(action) || isNewAttachmentAction(action);
}

export function getEventsByType<T extends ChatEventEntity = ChatEventEntity>(
  eventsMap: KeyMap<ChatEventEntity>,
  types: ChatEventType[],
): KeyMap<T> {
  return Object.entries(eventsMap).reduce<KeyMap<T>>((acc, [key, event]) => {
    if (types.includes(event.type)) {
      acc[key] = event as T;
    }

    return acc;
  }, {});
}

export function excludeBotMessages(eventsMap: KeyMap<ChatEventEntity>, botsMap: IBots): KeyMap<ChatEventEntity> {
  return Object.entries(eventsMap).reduce<KeyMap<ChatEventEntity>>((acc, [key, value]) => {
    if (isEventWithAuthor(value) && !botsMap[value.authorId]) {
      acc[key] = value;
    }

    return acc;
  }, {});
}

export function isAgentOrSupervisor(event: ChatEventEntityWithAuthor): boolean {
  return event.authorType === UserType.Agent || event.authorType === UserType.Supervisor;
}

export function isCustomer(event: ChatEventEntityWithAuthor): boolean {
  return event.authorType === UserType.Customer;
}

export function isPrivateMessage(event: ChatEventEntityWithAuthor, chattingAgentLogin: string): boolean {
  return event.authorId === chattingAgentLogin && event.authorType === UserType.Supervisor;
}

export function isEventNotFromSupervisor(event: ChatEventEntityWithAuthor, chattingAgentLogin: string): boolean {
  return (!isPrivateMessage(event, chattingAgentLogin) && event.authorId === chattingAgentLogin) || isCustomer(event);
}

export function isEventFromAgent(event: ChatEventEntityWithAuthor, chattingAgentLogin: string): boolean {
  return !isPrivateMessage(event, chattingAgentLogin) && event.authorId === chattingAgentLogin;
}

export function isWelcomeMessage(event: ChatEventEntity): event is IMessage {
  return isMessage(event) && Boolean(event.properties?.lc2?.welcomeMessage);
}
