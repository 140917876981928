import { type TourStep } from '../interfaces';

import { MySavedViewsPageStep, SavedViewsToolbarStep } from './tour-steps';

export enum ReportViewsStep {
  SavedViewsToolbar = 'saved-views-toolbar',
  MySavedViewsPage = 'my-saved-views-page',
}

export const reportViewsSteps: TourStep[] = [
  {
    id: ReportViewsStep.SavedViewsToolbar,
    stepContent: SavedViewsToolbarStep,
    placement: 'bottom-end',
  },
  {
    id: ReportViewsStep.MySavedViewsPage,
    stepContent: MySavedViewsPageStep,
    placement: 'right',
  },
];
