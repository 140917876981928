import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { isNetworkIssuesError, isWithApiError } from 'helpers/request-decoder';
import { isConfigurationApiError } from 'services/connectivity/configuration-api/type-guards';
import { isAccountsApiError } from 'services/connectivity/global-accounts-api/type-guards';
import { handleServerError } from 'services/server/handle-server-error';
import { handleAccountsApiError, handleApiError, handleConfigurationApiError } from 'services/server/login-failure';

import { handleNetworkIssuesError } from './handle-network-issues-error';

const log = debug(DebugLogsNamespace.AppServerConnection);

/**
 * Handles errors that occur during the initialization process (startup or reconnections).
 *
 * @param error - The error object.
 * @param isStartup - Whether the error occurred during the startup process.
 */
export async function handleInitializationError(error: unknown, isStartup: boolean): Promise<void> {
  if (isNetworkIssuesError(error)) {
    log('Received network issues error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Network issues error: ${JSON.stringify(error)}`,
    });
    handleNetworkIssuesError(error, isStartup);
  } else if (isConfigurationApiError(error)) {
    log('Received configuration API error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Configuration API error: ${JSON.stringify(error)}`,
    });
    await handleConfigurationApiError(error, isStartup);
  } else if (isAccountsApiError(error)) {
    log('Received accounts API error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Accounts API error: ${JSON.stringify(error)}`,
    });
    await handleAccountsApiError(error, isStartup);
  } else if (isWithApiError(error)) {
    log('Received status error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `API error: ${JSON.stringify(error)}`,
    });
    await handleApiError(error, isStartup);
  } else {
    log('Critical error fetching startup data', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Unexpected error: ${JSON.stringify(error)}`,
    });
    await handleServerError({
      error: ServerError.UnexpectedError,
      errorObject: error,
      isStartup,
    });
  }
}
