import { getBrowserTimezone } from 'helpers/get-browser-timezone';
import { trimObject } from 'helpers/object';
import type {
  IPeriodData,
  ITagUsagePeriodData,
  ITotalChatPeriod,
  IChatSatisfactionRatingPeriodData,
  IChatSatisfactionRankingPeriodData,
  IChatEngagementPeriodData,
  IGreetingsConversionPeriodData,
  IAgentsPerformance,
  IChatFirstResponseTimePeriodData,
  IChatResponseTimePeriodData,
  IChatAvailabilityPeriod,
  IStatsVisitorsSummaryData,
} from 'interfaces/reports';
import type { ReportData, SerializedJSONFilters } from 'interfaces/reports/api-v3';
import { BaseApi } from 'services/api/base-api';

export class ReportApiV3 extends BaseApi {
  protected readonly prefix = 'reports';
  protected readonly version = '3.5';
  

  fetchTagsList = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<ITagUsagePeriodData>>(
      'chats/tags',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchTotalChats = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<ITotalChatPeriod>>(
      'chats/total_chats',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatSatisfaction = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatSatisfactionRatingPeriodData>>(
      'chats/ratings',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatRanking = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatSatisfactionRankingPeriodData>>(
      'chats/ranking',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatEngagement = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatEngagementPeriodData>>(
      'chats/engagement',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchGreetingsConversion = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IGreetingsConversionPeriodData>>(
      'chats/greetings_conversion',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatDuration = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IPeriodData>>(
      'chats/duration',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatFirstResponseTime = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatFirstResponseTimePeriodData>>(
      'chats/first_response_time',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatResponseTime = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatResponseTimePeriodData>>(
      'chats/response_time',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchAgentsPerformance = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IAgentsPerformance>>(
      'agents/performance',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchChatAvailability = ({ distribution, ...filters }: SerializedJSONFilters) =>
    this.post<ReportData<IChatAvailabilityPeriod>>(
      'agents/availability',
      trimObject({
        filters,
        distribution,
        timezone: getBrowserTimezone(),
      }),
    );

  fetchStatsVisitors = (filters: SerializedJSONFilters) =>
    this.post<ReportData<null, IStatsVisitorsSummaryData>>(
      'stats/visitors',
      trimObject({ filters, timezone: getBrowserTimezone() }),
      {},
      '',
      '3.6',
    );
}
