import { BaseApi } from '../base-api';

import {
  type FetchPricePreviewParams,
  type ICreateRecurringRequestBody,
  type ICreateRecurringRequestResult,
  type IFetchBillingInfoResult,
  type IFetchPricePreviewResult,
} from './interfaces';

export class BillingApi extends BaseApi {
  protected readonly version = '2';
  

  fetchBillingInfo = () => this.get<IFetchBillingInfoResult>('account/livechat/billing_info');
  updateBillingInfo = (params) => this.put<IFetchBillingInfoResult>('account/livechat/billing_info', params);
  updateWithToken = (params) => this.put('account/livechat/billing_info/token', params);
  createWithToken = (params) => this.post('account/livechat', params);
  activeAccountState = (params) => this.put('account/livechat', params);
  collectInvoice = (invoiceId: number, three_d_secure_action_result_token?: string) =>
    this.put(`invoices/livechat/${invoiceId}/collect`, { three_d_secure_action_result_token });

  downloadInvoice = (invoiceId: string) => this.get<string>(`invoices/livechat/${invoiceId}/PDF`);
  fetchRecurrings = (clientId: string) =>
    this.get<{ result: ICreateRecurringRequestResult[] }>('recurrent_charge/livechat', {
      order_client_id: clientId,
      status: 'active',
    });

  createRecurring = (params: ICreateRecurringRequestBody) =>
    this.post<ICreateRecurringRequestResult>('recurrent_charge/livechat', params);

  acceptRecurring = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`recurrent_charge/livechat/${chargeId}/accept`);

  activateRecurring = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`recurrent_charge/livechat/${chargeId}/activate`);

  cancelRecurring = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`recurrent_charge/livechat/${chargeId}/cancel`);

  declineRecurring = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`recurrent_charge/livechat/${chargeId}/decline`);

  acceptDirect = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`direct_charge/livechat/${chargeId}/accept`);

  activateDirect = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`direct_charge/livechat/${chargeId}/activate`);

  declineDirect = (chargeId: string) =>
    this.put<ICreateRecurringRequestResult>(`direct_charge/livechat/${chargeId}/decline`);

  fetchPricePreview = (params: FetchPricePreviewParams) =>
    this.get<IFetchPricePreviewResult>('price_preview/livechat', {
      ...params,
    });
}
