import { type FC, useRef } from 'react';

import { cx } from '@emotion/css';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';

import { useListSuggestions } from 'hooks/use-list-suggestions';

import { deserializeSuggestionWithShortcuts } from '../helpers';

import { ContentEditableWrapper } from './ContentEditableWrapper';
import { AutoFocusPlugin } from './custom-plugins/AutoFocusPlugin';
import { CommandMenuPlugin } from './custom-plugins/CommandMenuPlugin';
import { DraftMessagePlugin } from './custom-plugins/DraftMessagePlugin';
import { EnterDownPlugin } from './custom-plugins/EnterDownPlugin';
import { PreventNewLinePlugin } from './custom-plugins/PreventNewLinePlugin';
import { Placeholder } from './Placeholder';

import * as styles from './styles';

interface IMessageBoxPluginsProps {
  isExpanded: boolean;
}

export const CopilotMessageBoxPlugins: FC<IMessageBoxPluginsProps> = ({ isExpanded }) => {
  const { data } = useListSuggestions();
  const suggestions = deserializeSuggestionWithShortcuts(data);
  const isCommandMenuVisibleRef = useRef(false);

  return (
    <>
      <PlainTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={<ContentEditableWrapper className={cx({ [styles.popoverContentEditable]: !isExpanded })} />}
        placeholder={<Placeholder className={cx({ [styles.popoverPlaceholder]: !isExpanded })} />}
      />
      <HistoryPlugin />
      <AutoFocusPlugin />
      <ClearEditorPlugin />
      <EnterDownPlugin isCommandMenuVisibleRef={isCommandMenuVisibleRef} />
      <DraftMessagePlugin isCommandMenuVisibleRef={isCommandMenuVisibleRef} />
      {!!suggestions.length && (
        <CommandMenuPlugin suggestions={suggestions} isCommandMenuVisibleRef={isCommandMenuVisibleRef} />
      )}
      {!isExpanded && <PreventNewLinePlugin />}
    </>
  );
};
