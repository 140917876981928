// @ts-strict-ignore
import { getTime } from 'date-fns';
import { isbot } from 'isbot';

import { GENERAL_GROUP_ID } from 'constants/groups';
import { FacebookMessengerCustomVariableProperty } from 'constants/integrations/facebook-messenger';
import { VisitorState } from 'constants/visitor-state';
import { toKeyMap } from 'helpers/array';
import { skipCustomVariables } from 'helpers/custom-variables';
import { isNullOrNotArray } from 'helpers/data';
import { type KeyMap } from 'helpers/interface';
import type { SessionFields } from 'interfaces/api/chat';
import {
  type ICustomerStatistics,
  type ICustomerVisitDetails,
  type IGeolocationDetails,
} from 'store/entities/common/interfaces';
import { type ICustomer, type IIntegrationVariables, type IVisitedPage } from 'store/entities/customers/interfaces';
import {
  getBrowserNameForList,
  getDeviceGeneralTypeForList,
  getOperatingSystemForList,
} from 'store/views/traffic/helpers/customers-list';

import type {
  ICustomerPageUpdatedPushEvent,
  ICustomerUpdatedPushEvent,
  IncomingCustomerPushEvent,
  IVisitorPushEventResult,
} from '../interfaces';

export function deserializeGeolocationDetails(
  geolocation?: IVisitorPushEventResult['geolocation'],
): IGeolocationDetails | undefined {
  if (!geolocation) {
    return;
  }

  const { city, region: state, country, country_code: countryCode, latitude, longitude, timezone } = geolocation;

  return {
    city,
    state,
    country,
    countryCode,
    latitude,
    longitude,
    timezone,
  };
}

function deserializeCustomerStatistics(
  statistics?: IncomingCustomerPushEvent['statistics'],
): ICustomerStatistics | undefined {
  if (!statistics) {
    return;
  }

  const {
    chats_count: chatsCount,
    threads_count: threadsCount,
    greetings_accepted_count: acceptedGreetingsCount,
    greetings_shown_count: greetingsCount,
    visits_count: visitsCount,
  } = statistics;

  return {
    acceptedGreetingsCount,
    chatsCount,
    threadsCount,
    greetingsCount,
    visitsCount,
  };
}

export function deserializeVisitDetails(customer: IncomingCustomerPushEvent): ICustomerVisitDetails | undefined {
  const { last_visit: lastVisitData, greeting_id: greetingId, state } = customer;

  if (!lastVisitData) {
    return;
  }

  const {
    user_agent: userAgent,
    started_at: startedAt,
    referrer: cameFromURL,
    previous_visit_started_at: previousVisitTime,
  } = lastVisitData;

  const browserName = getBrowserNameForList(userAgent);
  const deviceType = getDeviceGeneralTypeForList(userAgent);
  const operatingSystem = getOperatingSystemForList(userAgent);
  const isWebCrawler = isbot(userAgent);
  const visitStartedTimestampInMs = startedAt ? getTime(startedAt) : undefined;
  const targetedMessageId = greetingId?.toString();
  const wasInvited = state === VisitorState.Invited;
  const lastVisitTimestampInMs = previousVisitTime ? getTime(previousVisitTime) : undefined;

  return {
    cameFromURL,
    lastVisitTimestampInMs,
    targetedMessageId,
    userAgent,
    visitStartedTimestampInMs,
    operatingSystem,
    deviceType,
    browserName,
    isWebCrawler,
    wasInvited,
  };
}

export function deserializeIntegrationVariables(
  data: IVisitorPushEventResult['details'],
): ICustomer['integrationVariables'] | undefined {
  if (isNullOrNotArray(data)) {
    return;
  }

  return toKeyMap(data, 'id');
}

export function deserializeSessionFields(fields: SessionFields[]): {
  customVariables?: KeyMap<string>;
  integrationVariables?: KeyMap<IIntegrationVariables>;
} {
  if (!fields) {
    return {};
  }

  const reducedFields = fields.reduce<SessionFields>((prev, next) => {
    return { ...prev, ...next };
  }, {});

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __details_json, ...customVariables } = reducedFields;

  // workaround until https://livechatinc.atlassian.net/browse/AA-13601 is done
  const sortedCustomVariables = Object.keys(customVariables).reduce((acc, key) => {
    acc[key] = customVariables[key];

    return acc;
  }, {});

  /*
    __details_json is a special key in the map, it contains integration variables that need to be additionally deserialized
    */
  let integrationVariables = {};
  try {
    const details = JSON.parse(__details_json);
    integrationVariables = deserializeIntegrationVariables(details);
  } catch {
    integrationVariables = {};
  }

  return {
    customVariables: skipCustomVariables(sortedCustomVariables, FacebookMessengerCustomVariableProperty.Prefix),
    integrationVariables,
  };
}

export function deserializeCustomerPageUpdatedPushEvent(data: ICustomerPageUpdatedPushEvent): IVisitedPage {
  return {
    pageURL: data.url,
    timestampInMS: getTime(data.opened_at),
    title: data.title,
  };
}

export function deserializeCustomerUpdatedPushEvent(data: ICustomerUpdatedPushEvent): Partial<ICustomer> {
  const { id, email, avatar, name, session_fields: sessionFieldsData } = data;
  const { customVariables, integrationVariables } = deserializeSessionFields(sessionFieldsData);

  return {
    id,
    ...(email && { email }),
    ...(avatar && { avatarUrl: avatar }),
    ...(name && { name }),
    ...(integrationVariables && { integrationVariables }),
    ...(customVariables && { customVariables }),
  };
}

function deserializeRevokeChatId(chats: IncomingCustomerPushEvent['chats'] = []): string | undefined {
  const recentChats = chats
    .slice()
    .sort((a, b) => getTime(b.last_thread_started_at) - getTime(a.last_thread_started_at));

  return recentChats[0]?.chat_id;
}

export function deserializeIncomingCustomerPushEvent(data: IncomingCustomerPushEvent): ICustomer {
  const {
    id,
    email,
    name,
    group_ids: groupIds,
    last_visit: lastVisitData,
    chats,
    statistics: statisticsData,
    session_fields: sessionFields,
  } = data;

  const ip = lastVisitData?.ip;
  const revokeChatId = deserializeRevokeChatId(chats);
  const geolocation = deserializeGeolocationDetails(lastVisitData?.geolocation);
  const statistics = deserializeCustomerStatistics(statisticsData);
  const visitDetails = deserializeVisitDetails(data);
  const { customVariables, integrationVariables } = deserializeSessionFields(sessionFields);

  return {
    email: email ?? undefined,
    id,
    groupIds: groupIds ? groupIds.map(String) : [GENERAL_GROUP_ID],
    name: name ?? undefined,
    ip,
    revokeChatId,
    ...(visitDetails && { visitDetails }),
    ...(geolocation && { geolocation }),
    ...(statistics && { statistics }),
    ...(customVariables && { customVariables }),
    ...(integrationVariables && { integrationVariables }),
  };
}
