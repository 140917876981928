import { css } from '@emotion/css';

import { TOP_BAR_HEIGHT, MAIN_SECTION_PADDING, MOBILE_TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';
import {
  NOTIFICATIONS_BAR_HEIGHT,
  NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN,
  NOTIFICATIONS_BAR_SIDE_MARGIN,
  NOTIFICATIONS_BAR_TOP_MARGIN,
} from 'constants/notifications-bar';
import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';
import { CONTENT_PAGE_MARGIN, SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';

const calculatedMobileContentHeight = `calc(100% - ${MOBILE_TOP_BAR_HEIGHT})`;
const calculatedTabletContentHeight = `calc(100% - ${TOP_BAR_HEIGHT} - ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN})`;
const calculatedDesktopContentHeight = `calc(100% - ${TOP_BAR_HEIGHT} - ${NOTIFICATIONS_BAR_HEIGHT_WITH_MARGIN} - ${MAIN_SECTION_PADDING})`;

const heightAnimation = css`
  transition: height 0.3s cubic-bezier(0.33, 0, 0.67, 1);
`;

const noAnimation = css`
  transition: none;
  transition-delay: 0;
`;

const baseStyles = css`
  width: 100%;

  #notifications-bar-container {
    overflow: hidden;
    display: block;
  }
`;

const collpasedNotificationBar = css`
  ${baseStyles};

  #notifications-bar-container {
    height: 0 !important;
    margin: 0;
    ${heightAnimation};
  }

  #content {
    height: calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px);
    ${heightAnimation};
    transition-delay: 0.4s;
  }
`;

const collapseWithoutAnimation = css`
  ${collpasedNotificationBar};

  #notifications-bar-container {
    height: 0 !important;
    margin: 0;
    ${noAnimation};
  }

  #content {
    height: calc(100% - ${TOP_BAR_HEIGHT} - ${CONTENT_PAGE_MARGIN}px);
    ${noAnimation};
  }
`;

const expandedNotificationBar = css`
  ${baseStyles};

  #notifications-bar-container {
    ${heightAnimation};
    height: ${NOTIFICATIONS_BAR_HEIGHT};
    margin: ${NOTIFICATIONS_BAR_TOP_MARGIN} ${NOTIFICATIONS_BAR_SIDE_MARGIN} 0 0;

    @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
      ${heightAnimation};
      margin: 0;
    }

    @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
      height: ${MOBILE_TOP_BAR_HEIGHT};
      ${heightAnimation};
    }
  }

  #content {
    height: ${calculatedDesktopContentHeight};
    ${heightAnimation};
    transition-delay: 0.5s;

    @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
      height: ${calculatedTabletContentHeight};
      ${heightAnimation};
      transition-delay: 0.5s;
    }

    @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
      height: ${calculatedMobileContentHeight};
      ${heightAnimation};
    }
  }
`;

function setNotififcationsBarStyles(newStyles: string, stylesToRemove: string[]): void {
  const notificationsBarContainer = document.getElementById('page-content-container');
  if (notificationsBarContainer) {
    if (notificationsBarContainer.classList.length > 0) {
      notificationsBarContainer.classList.remove(...stylesToRemove);
    }
    notificationsBarContainer.classList.add(newStyles);
  }
}

export function collapseNotificationBar(): void {
  setNotififcationsBarStyles(collpasedNotificationBar, [expandedNotificationBar, collapseWithoutAnimation]);
}

export function collapseNotificationBarWithoutAnimation(): void {
  setNotififcationsBarStyles(collapseWithoutAnimation, [collpasedNotificationBar, expandedNotificationBar]);
}

export function expandNotificationBar(): void {
  setNotififcationsBarStyles(expandedNotificationBar, [collpasedNotificationBar, collapseWithoutAnimation]);
}
