import { useCallback, type FC } from 'react';

import { Badge, SideNavigationGroup, SideNavigationItem } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { navigateToMarketplace, preloadMarketplacePage } from 'helpers/routing';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { getCartItemsCount } from 'store/entities/product-cart/selectors';
import { getIsSubscriptionInTrialAndNotSubscribed } from 'store/entities/subscription/selectors';
import { AppsViewActions } from 'store/views/apps/actions';
import { getCurrentMarketplaceRoute } from 'store/views/apps/selectors';

import { MARKETPLACE_LINKS } from './constants';
import { checkIfActive } from './helpers';

const [exploreApps] = MARKETPLACE_LINKS;
const ExploreAppsIcon = exploreApps.icon;

export const ExploreApps: FC = () => {
  const cartItemsCount = useSelector(getCartItemsCount);
  const isTrial = useSelector(getIsSubscriptionInTrialAndNotSubscribed);
  const shouldDisplayCartItemsBadge = cartItemsCount && !isTrial;
  const currentMarketplaceRoute = useSelector(getCurrentMarketplaceRoute);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (link: string): void => {
      navigateToMarketplace(link);
      dispatch(AppsViewActions.setMarketplaceRoute(link));
      trackAppsSectionEvent(AppsEvent.ExploreAppsClicked, AppsSection.SideNavigation);
    },
    [dispatch],
  );

  return (
    <SideNavigationGroup>
      <SideNavigationItem
        isActive={checkIfActive(exploreApps.link, currentMarketplaceRoute, true)}
        url={exploreApps.link}
        leftNode={<ExploreAppsIcon />}
        label={exploreApps.label}
        onClick={() => handleClick(exploreApps.link)}
        rightNode={shouldDisplayCartItemsBadge ? <Badge count={cartItemsCount} /> : null}
        onItemHover={() => preloadMarketplacePage(exploreApps.link)}
      />
    </SideNavigationGroup>
  );
};
