import type { RequestResult } from 'interfaces/api/client';
import { type ICampaign } from 'store/features/applications/interfaces';

import { BaseApi } from '../base-api';

export class CampaignsApi extends BaseApi {
  protected readonly prefix = 'campaigns';
  protected readonly passVersionInPrefix = false;

  fetchAll = () => this.get('');
  fetch = (campaignId: string): Promise<RequestResult<ICampaign>> => this.get(campaignId);
}
