import { type TourStep } from '../interfaces';

import {
  BubbleStep,
  ChatsStep,
  EngageStep,
  AutomateStep,
  SettingsStep,
  SpotlightStep,
  CopilotStep,
} from './tour-steps';

import * as styles from './styles';

export enum HomeScreenTourSteps {
  Bubble = 'Bubble',
  Chats = 'Chats',
  Engage = 'Engage',
  Automate = 'Automate',
  Settings = 'Settings',
  Spotlight = 'Spotlight',
  Copilot = 'Copilot',
}

export const homeScreenSteps: TourStep[] = [
  {
    id: HomeScreenTourSteps.Bubble,
    bubbleStepContent: BubbleStep,
    isFirstStep: true,
    className: styles.homeScreenBubbleTourStep,
    cursorTiming: 'moderate2',
  },
  {
    id: HomeScreenTourSteps.Chats,
    elementClassName: styles.navigationStyles,
    stepContent: ChatsStep,
    placement: 'right-start',
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
  {
    id: HomeScreenTourSteps.Engage,
    elementClassName: styles.navigationStyles,
    stepContent: EngageStep,
    placement: 'right-start',
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
  {
    id: HomeScreenTourSteps.Automate,
    elementClassName: styles.navigationStyles,
    stepContent: AutomateStep,
    placement: 'right-start',
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
  {
    id: HomeScreenTourSteps.Settings,
    elementClassName: styles.navigationStyles,
    stepContent: SettingsStep,
    placement: 'right-end',
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
  {
    id: HomeScreenTourSteps.Spotlight,
    stepContent: SpotlightStep,
    elementClassName: styles.spotlightStepStyles,
    placement: 'bottom',
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
  {
    id: HomeScreenTourSteps.Copilot,
    stepContent: CopilotStep,
    placement: 'bottom-end',
    elementClassName: styles.copilotStepStyles,
    stepClassName: styles.homeScreenTourStep,
    cursorTiming: 'moderate2',
    typingAnimation: true,
  },
];
