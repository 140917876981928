import type { FC } from 'react';

import {
  type CopilotEvent as CopilotEventType,
  type CopilotMessageEvent as CopilotMessageEventType,
} from 'store/entities/copilot/interfaces';

import { CopilotErrorEvent } from './CopilotErrorEvent';
import { CopilotMessageEvent } from './CopilotMessageEvent';

import { virtuosoEmptyElement } from './styles';

interface IProps {
  event: CopilotEventType;
  isAgentMessage: boolean;
}

export const CopilotEvent: FC<IProps> = ({ event, isAgentMessage }) => {
  switch (event.type) {
    case 'text-message': {
      return <CopilotMessageEvent event={event as CopilotMessageEventType} isAgentMessage={isAgentMessage} />;
    }

    case 'error-message': {
      return <CopilotErrorEvent eventId={event.eventId} />;
    }

    default:
      // We must return empty element with fixed height for Virtuoso to work properly
      return <div className={virtuosoEmptyElement} />;
  }
};
