export const COPILOT_MESSAGE_BOX_HEIGHT = 133;
export const BOTTOM_BAR_HEIGHT = 32;

export const TOP_SOURCES_LIMIT = 5;

export const GOOGLE_FEEDBACK_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSexJCDytciGA88XP1eR1x5jLCYzBjba-RHAFr8YDeGVPQEnOw/viewform';

export const COPILOT_LIGHT_BOX_SHADOW = '0 6px 28px rgba(19, 19, 23, 0.3)';
export const COPILOT_DARK_BOX_SHADOW =
  '0 4px 32px 1px hsla(0, 0%, 0%, 0.5), 0 0 0 1px hsla(0, 0%, 100%, 0.15) inset, 0 0 2px 1px hsla(0, 0%, 0%, 0.15)';

export const COPILOT_MESSAGE_TOAST_DURATION = 4000;
export const EXPAND_ANIMATION_DURATION = 0.4;
export const COLLAPSE_ANIMATION_DURATION = 0.3;
export const COPILOT_SCROLL_TIMEOUT = 50;
export const COPILOT_ANIMATION_TIMING_FUNCTION = 'cubic-bezier(0.345, 0.005, 0.135, 1)';

export const COPILOT_MODAL_TEST_ID = 'copilot-modal';
export const COPILOT_CLOSE_MODAL_TEST_ID = 'copilot-close-modal';
export const COPILOT_STOP_GENERATING_BTN_TEST_ID = 'copilot-stop-generating';
export const COPILOT_TOGGLE_MODAL_BTN_TEST_ID = 'copilot-toggle-modal';
export const COPILOT_MODAL_DETAILS_BTN_TEST_ID = 'copilot-modal-details';
export const COPILOT_CHAT_FEED_ID = 'copilot-chat-feed-id';
export const HEADER_TOOLTIP_HOVER_DELAY = 300;
export const COPILOT_POPOVER_SUGGESTIONS_TEST_ID = 'copilot-popover-suggestions';
