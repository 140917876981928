import type { RequestResult } from 'interfaces/api/client';
import { type IFacebookPage } from 'store/entities/facebook/interfaces';

import { BaseApi } from '../base-api';

type IFetchConnectedPagesResponse = IFacebookPage[];

export class FacebookMessengerApi extends BaseApi {
  protected readonly prefix = 'fanpages';
  protected readonly passVersionInPrefix = false;

  fetchConnectedPages = (): Promise<RequestResult<IFetchConnectedPagesResponse>> => {
    return this.get();
  };
}
