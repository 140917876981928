import { type FC, type MouseEventHandler } from 'react';

import { useFetchFeatures } from 'hooks/api/feature-control/use-fetch-features';
import { useTeamchatNotifications } from 'hooks/use-teamchat-notifications';

import { TeamChatNotificationsList } from './TeamChatNotificationsList';

import * as styles from './styles';

export const TeamChatNotifications: FC = () => {
  const { hasVisibleNotifications, teamchatNotifications, handleNotificationClick, removeNotifications } =
    useTeamchatNotifications();
  const {
    data: { showTeamChatProduct },
  } = useFetchFeatures();

  const handleClose: MouseEventHandler = (e): void => {
    e.stopPropagation();
    removeNotifications();
  };

  if (!showTeamChatProduct) {
    return null;
  }

  return (
    <div className="lc-dark-theme">
      <div className={styles.notificationsWrapper(hasVisibleNotifications)}>
        <TeamChatNotificationsList
          notifications={teamchatNotifications}
          onNotificationClick={handleNotificationClick}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};
