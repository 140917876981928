import { css } from '@emotion/css';
import {
  DesignToken,
  RadiusToken,
  ShadowToken,
  SpacingToken,
  TransitionDurationToken,
  TransitionTimingToken,
} from '@livechat/design-system-react-components';

import { TOP_BAR_HEIGHT } from 'components/notifications-bar/constants';

export const notificationsWrapper = (hasNotifications = false): string => css`
  position: fixed;
  width: 296px;
  top: ${hasNotifications ? `calc(60px + ${TOP_BAR_HEIGHT})` : `60px`};
  right: 18px;
  z-index: 120;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
`;

export const notificationAppearSlide = css`
  transform: translateX(100%);
  opacity: 0;
`;

export const notificationAppearActiveSlide = css`
  transform: translateX(0);
  opacity: 1;
  transition:
    transform var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseIn}),
    opacity var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseIn});
`;

export const notificationExitSlide = css`
  transform: translateX(0);
  opacity: 1;
`;

export const notificationExitActiveSlide = css`
  transform: translateX(20px) scale(0.95);
  opacity: 0;
  transition:
    transform var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseOut}),
    opacity var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseOut});
`;

export const notification = css`
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  gap: var(${SpacingToken.Spacing3});
  align-items: center;
  justify-content: flex-start;
  border-radius: var(${RadiusToken.Radius4});
  box-shadow: var(${ShadowToken.PopOver});
  padding: var(${SpacingToken.Spacing4});
  min-width: 296px;
  max-width: 296px;
  line-height: 22px;
  font-size: 15px;
  color: var(${DesignToken.ContentBasicPrimary});
  background-color: var(${DesignToken.SurfacePrimaryActive});
  z-index: 10;

  .close-button-wrapper {
    opacity: 0;
    transition: opacity var(${TransitionDurationToken.Fast2}) var(${TransitionTimingToken.EaseIn});
  }

  :hover {
    .close-button-wrapper {
      opacity: 1;
    }
  }
`;

export const stackedNotification: string = css`
  transition: transform var(${TransitionDurationToken.Moderate1}) var(${TransitionTimingToken.EaseIn});
  transform: translateY(8px) scale(0.95);
  background-color: var(${DesignToken.SurfaceSecondaryHover});
  z-index: 9;
`;

export const avatar = css`
  position: relative;
`;

export const avatarLogo = css`
  position: absolute;
  background-color: var(${DesignToken.ProductsHello});
  padding: 2px;
  border-radius: 5px;
  border: 1px solid var(${DesignToken.SurfaceSecondaryHover});
  width: 12px;
  height: 12px;
  right: -2px;
  bottom: -2px;
`;

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const header = css`
  display: flex;
  gap: var(${SpacingToken.Spacing3});
`;

export const headerAuthor = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const headerTime = css`
  color: var(${DesignToken.ContentBasicDisabled});
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`;

export const message = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
