import { BaseApi } from '../base-api';

import type { DeveloperProfile } from './interfaces';

export class DevelopersApi extends BaseApi {
  protected readonly passVersionInPrefix = false;
  protected readonly prefix: string = 'developers';

  fetchDeveloperProfile = (ownerId: string) => this.get<DeveloperProfile>(`${ownerId}/profile`);
}
