import { Section } from 'constants/section';

export enum AppsSection {
  Apps = 'Apps',
  Marketplace = 'Marketplace',
  SideNavigation = 'Side Navigation',
  PlatformApi = 'Platform API',
  Overview = 'Overview',
  Settings = 'Settings',
  Webhooks = 'Webhooks',
  ApiTab = 'API tab',
  ConfirmUninstallModal = 'Confirm uninstall modal',
}

export const SectionToAppsSectionMap = {
  [Section.Apps]: AppsSection.Apps,
  [Section.AppsSettings]: AppsSection.Settings,
  [Section.AppsOverview]: AppsSection.Overview,
} as const;

export enum AppsEvent {
  SectionOpened = 'Section opened',
  OpenChatsClicked = 'Go to Chats button clicked',
  OpenFullscreenAppClicked = 'Open fullscreen app button clicked',
  TutorialLinkClicked = 'Tutorial link clicked',
  OpenReportsClicked = 'Open reports button clicked',
  OpenSettingsClicked = 'Open settings button clicked',
  OpenAppInMarketplaceActionItemClicked = 'Open app in Marketplace action menu item clicked',
  OpenAppSettingsActionItemClicked = 'Open app settings action menu item clicked',
  OpenAppActionItemClicked = 'Open app action menu item clicked',
  SetupInstructionsActionItemClicked = 'Setup instructions action menu item clicked',
  FinishInstallationActionItemClicked = 'Finish installation action menu item clicked',
  ContactDeveloperActionItemClicked = 'Contact developer action menu item clicked',
  UninstallAppActionItemClicked = 'Uninstall app action menu item clicked',
  InstallAppActionItemClicked = 'Install app action menu item clicked',
  SubmitAndUninstallButtonClicked = 'Submit and uninstall button clicked',
  ExploreButtonClicked = 'Explore button clicked',
  StartBuildingButtonClicked = 'Start building button clicked',
  ApiUsageButtonClicked = 'API Usage button clicked',
  ExploreApiConsoleButtonClicked = 'Explore API Console button clicked',
  CheckApiButtonClicked = 'Check API button clicked',
  GoToConsoleButtonClicked = 'Go to Console button clicked',
  ReadTutorialButtonClicked = 'Read tutorial button clicked',
  ExploreAppsClicked = 'Explore apps clicked',
  ExternalIntegrationsClicked = 'External integrations clicked',
  SectionItemClicked = 'Section item clicked',
  GoToCartClicked = 'Go to Cart clicked',
  OverviewAppClicked = 'Application Overview app clicked',
  FullscreenAppClicked = 'Application Fullscreen app clicked',
  SettingsClicked = 'Application Settings clicked',
  YourAppsChevronItemClicked = 'Your Apps item clicked',
  YourAppsConfigurationListOpened = 'Your Apps configuration list opened',
  YourAppsConfigurationListClosed = 'Your Apps configuration list closed',
  FullscreenWidgetPinned = 'Fullscreen widget pinned',
  FullscreenWidgetUnpinned = 'Fullscreen widget unpinned',
}
