import { type CreateCartResult, type DeleteCartResult, type ProductsCart } from '../../../interfaces/product-cart';
import { BaseApi } from '../base-api';


export class ProductCartApi extends BaseApi {
  protected readonly passVersionInPrefix = false;
  protected readonly prefix = 'products-carts';

  create = () => this.post<CreateCartResult>('');
  deleteCart = (cartId: string) => this.delete<DeleteCartResult>(`${cartId}`);

  fetchAll = () => this.get<ProductsCart[]>('');
  fetchRecent = () => this.get<ProductsCart>('recent');

  addApplication = (cartId: string, applicationId: string) =>
    this.patch<CreateCartResult>(`${cartId}/applications/${applicationId}`);

  removeApplication = (cartId: string, applicationId: string) =>
    this.delete<CreateCartResult>(`${cartId}/applications/${applicationId}`);

  chooseProductPlan = (cartId: string, applicationId: string, plan: string) =>
    this.patch<CreateCartResult>(`${cartId}/applications/${applicationId}/plan/${plan}`);

  startCheckout = (cartId: string) => this.patch<CreateCartResult>(`${cartId}/checkout`);
  rejectCheckout = (cartId: string) => this.delete<CreateCartResult>(`${cartId}/checkout`);

  purchase = (cartId: string) => this.patch<CreateCartResult>(`${cartId}/purchase`);
}
