import { AppsEvent } from 'constants/apps/events';

export const enum YourAppsListSubItem {
  Overview = 'Overview',
  FullscreenApp = 'Fullscreen app',
  Settings = 'Settings',
}

export const ItemEventMap: Record<YourAppsListSubItem, AppsEvent> = {
  [YourAppsListSubItem.Overview]: AppsEvent.OverviewAppClicked,
  [YourAppsListSubItem.FullscreenApp]: AppsEvent.FullscreenAppClicked,
  [YourAppsListSubItem.Settings]: AppsEvent.SettingsClicked,
};
