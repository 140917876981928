import { css } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';

export const labelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  user-select: none;
  color: var(${DesignToken.ContentBasicPrimary});

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const dragIcon = css`
  flex-shrink: 0;
`;

export const icon = css`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const listItemIcon = css`
  border-radius: var(${RadiusToken.Radius1});
`;

export const listItemLabel = css`
  opacity: 0.6;
`;

export const itemDragging = css`
  box-sizing: border-box;
  border-radius: var(${RadiusToken.Radius3});
  overflow: hidden;

  label {
    vertical-align: middle;
  }
`;
