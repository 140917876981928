export enum ArchivesEvent {
  AddTagButtonClicked = 'Add tag button clicked',
  ChatStartedFromHistory = 'Chat started from history',
  ChatsSorted = 'Chats sorted',
  ChatTagged = 'Chat tagged',
  ConfigurationMenuClicked = 'Configuration menu clicked',
  DownloadTranscriptOptionSelected = 'Download transcript option selected',
  FilterApplied = 'Filter applied',
  InstallCodeEmptyStateClicked = 'Install code empty state clicked',
  ItemPositionChanged = 'Item position changed',
  ItemToggle = 'Item toggle',
  ListViewSetupOpened = 'List view setup opened',
  ListViewSetupSelected = 'List view setup selected',
  OngoingChatViewed = 'Ongoing chat viewed',
  RelatedTicketsLinkClicked = 'Related tickets link clicked',
  SearchCleared = 'Search cleared',
  SearchUsed = 'Search used',
  ShortcutUsed = 'Shortcut used',
  TicketCreated = 'Ticket created',
  TrafficSectionOpened = 'Traffic section opened',
  ViewAllChatsButtonClicked = 'View all chats button clicked',
  ViewAllChatsInNewTabButtonClicked = 'View all chats in new tab button clicked',
  WidgetAppActionTaken = 'Widget app action taken',
  //Priority chat
  PriorityFilterSelected = 'Priority filter selected',
}
