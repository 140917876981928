import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import type { IBanCustomerPayload, IUnbanCustomerPayload, IFetchBanListResult } from './interfaces';

export class BannedCustomersApi extends BaseApi {
  protected readonly prefix = 'banlist';
  protected readonly passAppVersionHeader = true;

  banCustomer = <Result>(payload: IBanCustomerPayload): Promise<RequestResult<Result>> => {
    const { customerIp, banDays } = payload;
    const body = {
      ip: customerIp,
      days: banDays,
    };

    return this.post<Result>('', body);
  };

  unbanCustomer = <Result>(payload: IUnbanCustomerPayload): Promise<RequestResult<Result>> => {
    const { customerIp } = payload;

    return this.delete<Result>(customerIp);
  };

  fetchBanList = (): Promise<RequestResult<IFetchBanListResult>> => this.get();
}
