import { BaseApi } from '../base-api';

import { type ICompanyDetails } from './interfaces';

export class CompanyDetailsWithEnrichmentApi extends BaseApi {
  protected readonly prefix = 'configuration/action';
  protected readonly version = '3.6';
  

  update = (companyDetails: ICompanyDetails, enrich = false) =>
    this.post('update_company_details', {
      ...companyDetails,
      enrich,
    });
}
