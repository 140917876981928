import { BaseApi } from '../base-api';

import { type IFetchCannedResult, type ICannedCreateBody, type ICannedUpdateBody } from './interfaces';

export class CannedResponseApi extends BaseApi {
  protected readonly prefix = 'canned_responses';
  protected readonly passAppVersionHeader = true;

  fetchAll = (groupIds: string[], getPrivate: boolean) =>
    this.get<IFetchCannedResult[]>('', { group: groupIds, private: Number(getPrivate).toString() });

  save = (body: ICannedCreateBody) => this.post('', body);
  update = (id: number, body: ICannedUpdateBody) => this.put(id?.toString(), body);
  remove = (id: string) => this.delete(id);
}
