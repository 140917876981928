import { isPast } from 'date-fns';
import { createSelector } from 'reselect';

import { getConfig } from 'helpers/config';
import { isApplicationInstalled, type IWithApplicationsState } from 'store/entities/applications/selectors';
import {
  getAppInterviewCallScheduledTo,
  type IWithAgentCustomPropertiesState,
} from 'store/features/agent-custom-properties/selectors';
import {
  ALLOWED_SECTIONS,
  APP_INTERVIEW_AGENT_CUSTOM_PROPERTY,
  type AppWithInterviewModalName,
} from 'store/features/app-interview/constants';
import { getCurrentSection } from 'store/features/routing/selectors';
import { getIsOnTrial, getIsStarterPlan } from 'store/features/session/selectors';

const getIsOnAllowedSection = createSelector([getCurrentSection], (section) =>
  ALLOWED_SECTIONS.some((s) => section?.startsWith(s)),
);

export const getShouldShowAppInterviewModal = createSelector(
  [getIsOnAllowedSection, getIsOnTrial, getIsStarterPlan],
  (isOnAllowedSection, isOnTrial, isOnStarter) => isOnAllowedSection && !isOnTrial && !isOnStarter,
);

export const getAppForInterviewModal = (
  state: IWithApplicationsState & IWithAgentCustomPropertiesState,
): { id: string; name: AppWithInterviewModalName } | null => {
  const app = getConfig().sellingExperienceApps.find(({ id, name, interviewModal }) => {
    if (!interviewModal) {
      return false;
    }

    if (!isApplicationInstalled(state, id)) {
      return false;
    }

    const scheduledTo = getAppInterviewCallScheduledTo(
      state,
      APP_INTERVIEW_AGENT_CUSTOM_PROPERTY[name as AppWithInterviewModalName],
    );

    return scheduledTo !== 0 && (scheduledTo === null || isPast(scheduledTo));
  });

  if (!app) {
    return null;
  }

  return {
    ...app,
    name: app.name as AppWithInterviewModalName,
  };
};
