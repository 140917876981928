import { css } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

interface IStyledItemProps {
  folded: boolean;
  active: boolean;
  hasIcon: boolean;
  hasChildren: boolean;
  iconColor?: string;
}

interface IStyledChevronProps {
  rotated: boolean;
}

interface IBadgeWrapper {
  padded: boolean;
  isChild?: boolean;
}

interface IOnOffBadgeProps {
  isOn: boolean;
}

export const divider = css`
  margin: 16px 14px;
  height: 1px;
  background: var(${DesignToken.BorderBasicTertiary});
`;

export const item = ({ active, folded, hasChildren, hasIcon }: IStyledItemProps): string => css`
  list-style: none;
  position: relative;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    padding: 13px 10px;
    font-size: 14px;
    line-height: 22px;
    color: var(${DesignToken.ContentBasicPrimary});
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(${DesignToken.ActionPrimaryDefault});
    }
  }

  > a {
    ${!active && `color: var(${DesignToken.ContentBasicPrimary})`};
    ${active && ((folded && hasChildren) || !hasChildren) && `color: var(${DesignToken.ActionPrimaryDefault})`};
  }

  ul {
    margin-left: 22px;
    height: 0;

    ${!folded && 'height: auto'};

    li {
      position: relative;

      &.active a {
        color: var(${DesignToken.ActionPrimaryDefault});
        font-weight: 600;
      }

      a {
        padding-top: 7px;
        padding-bottom: 11px;
        ${hasIcon && 'padding-left: 32px;'};
      }
    }
  }
`;

const icon = css`
  position: absolute;
  z-index: 1;
  padding: 10px;
  top: 7px;
  right: 4px;
  cursor: pointer;
`;

export const chevron = ({ rotated }: IStyledChevronProps): string => css`
  ${icon}
  top: 10px;
  transition: transform 0.2s ease-in-out;
  ${!rotated && 'transform: rotate(-90deg);'};
`;

export const badgeWrapper = ({ padded, isChild }: IBadgeWrapper): string => css`
  position: absolute;
  top: ${isChild ? '10' : '15'}px;
  right: ${padded ? '40' : '10'}px;
`;

export const newBadge = css`
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  color: var(${DesignToken.ActionNegativeDefault});
  display: inline-block;
`;

export const onOffBadgeWrapper = ({ isOn }: IOnOffBadgeProps): string => css`
  position: absolute;
  right: 16px;
  font-size: 12px;
  font-weight: 600;
  color: ${isOn ? withVar(DesignToken.ActionPositiveDefault) : withVar(DesignToken.ContentBasicPrimary)};
  border: solid 1px ${isOn ? withVar(DesignToken.ActionPositiveDefault) : withVar(DesignToken.BorderBasicPrimary)};
  background-color: ${isOn ? 'transparent' : withVar(DesignToken.SurfaceSecondaryDefault)};
  text-transform: uppercase;
  line-height: 18px;
  width: 37px;
  text-align: center;
  border-radius: 4px;
`;

export const sideNavigationItems = css`
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  max-height: 100%;
  overflow-y: auto;
`;

export const childRowItem = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const betaBadge = css`
  display: inline-block;
  margin-left: 12px;
  line-height: 16px;
`;
