import { Fragment, type FC, type ReactNode } from 'react';

import { type IconSource, Tooltip } from '@livechat/design-system-react-components';

import { type FilterCompareLabelValues } from 'components/filters/interfaces';
import { type FilterLabelValueType } from 'components/filters/SelectedFilterLabel';
import { FilterOperator } from 'constants/filters/filter';

import { FilterButtonTrigger } from './FilterButtonTrigger';
import { getFilterOperatorLabel } from './helpers';
import type { FilterButton as Button } from './types';

import * as styles from './styles';

const TOOLTIP_TRANSITION_DELAY = 1000;

interface FilterButtonProps extends Button {
  selectedValuesLabel: ReactNode;
  title?: string;
  shouldHideTitle?: boolean;
  icon?: IconSource;
  removable: boolean;
  onRemove: (e?: React.MouseEvent<HTMLSpanElement>) => void;
  value?: FilterLabelValueType;
  operator?: FilterOperator;
  filterCompareLabelValues?: FilterCompareLabelValues | null;
}

const renderOperator = (operator: FilterOperator | ''): ReactNode => {
  return <span className={styles.operator}>{operator === FilterOperator.Exclude ? FilterOperator.Or : operator}</span>;
};

const renderOperatorWithBreak = (operator: FilterOperator | ''): ReactNode => {
  return (
    <span className={styles.operator}>
      {' '}
      {renderOperator(operator)}
      <br />
    </span>
  );
};

const generateTooltipContent = ({ operator = FilterOperator.Or, value }: FilterButtonProps): ReactNode => {
  const isArray = Array.isArray(value);

  if (isArray) {
    return (
      <>
        {value &&
          Array.isArray(value) &&
          value.map((item: { label: string }, index: number) => {
            const isNotLastElement = index < value.length - 1;

            return (
              <Fragment key={item.label}>
                <strong>{item.label}</strong>
                {isNotLastElement && renderOperatorWithBreak(operator)}
              </Fragment>
            );
          })}
      </>
    );
  }

  return <>{value}</>;
};

const renderValues = (values: string[], prefix: string, operator: FilterOperator | ''): ReactNode =>
  values.map((value, index) => {
    const isNotLastElement = index < values.length - 1;

    return (
      <Fragment key={`${prefix}-${value}`}>
        <b>{value}</b>
        {isNotLastElement && renderOperatorWithBreak(operator)}
      </Fragment>
    );
  });

const generateCompareTooltipContent = ({ filterCompareLabelValues }: FilterButtonProps): ReactNode => {
  if (!filterCompareLabelValues) {
    return null;
  }

  const { operator = FilterOperator.Or, firstValues, secondValues } = filterCompareLabelValues;

  return (
    <>
      {renderValues(firstValues, 'first', operator)}
      <br />
      {secondValues && secondValues.length > 0 && (
        <>
          <span> compare to </span>
          <br />
        </>
      )}
      {renderValues(secondValues, 'second', operator)}
    </>
  );
};

export const FilterButton: FC<FilterButtonProps> = (props) => {
  const { title, operator = FilterOperator.Is } = props;

  const renderTitle = (): ReactNode => {
    if (title) {
      return (
        <span>
          {title} {operator && getFilterOperatorLabel(operator)}:
        </span>
      );
    }
  };

  return (
    <Tooltip
      placement="top"
      transitionDelay={TOOLTIP_TRANSITION_DELAY}
      className={styles.tooltip}
      kind="invert"
      useClickHookProps={{ enabled: false }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      triggerRenderer={<FilterButtonTrigger {...props} />}
    >
      {renderTitle()}
      <br />
      {props.filterCompareLabelValues ? generateCompareTooltipContent(props) : generateTooltipContent(props)}
    </Tooltip>
  );
};
