import { BaseApi } from '../base-api';

import { type IFeaturesResponse } from './interfaces';

export class FeatureControlApi extends BaseApi {
  protected readonly prefix = 'features/livechat';
  protected readonly version = '1';
  

  fetchAll = () => this.get<IFeaturesResponse>();
}
