// @ts-strict-ignore
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { Feature } from 'constants/feature';
import { type PlanType } from 'constants/plan-type';
import {
  getABCAppId,
  getAutoChatSummaryAppId,
  getchatAnalyserAppId,
  getChatSentimentAppId,
  getFBMessengerAppId,
  getGoogleAnalyticsAppId,
  getHelpDeskAppId,
  getInactivityReasonAppId,
  getInsightsAppId,
  getRtmSentimentWebhooksAppId,
  getWhatsAppAppId,
  getSellingExperienceAppsIds,
} from 'helpers/applications';
import { getConfig } from 'helpers/config';
import { getTelegramBusinessAppId, getTwilioAppId } from 'helpers/integration';
import { type KeyMap, type Nullable } from 'helpers/interface';
import { type CustomSectionDefinition } from 'interfaces/custom-section';
import { type ICustomSections } from 'interfaces/customer-details';
import { isValidSectionDefinition } from 'services/custom-section/validators';
import { type IAccount } from 'store/entities/accounts/interfaces';
import { type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';
import {
  getCanManageApplicationPayments,
  getCanManageApplications,
  getCanUseFeature,
  getIsStarterPlan,
  isOwner,
  type IWithSessionState,
} from 'store/features/session/selectors';
import { sortByName } from 'store/helper';
import {
  createHasFetchedSelector,
  createHasFetchFailedSelector,
  createRequestErrorSelector,
  createRequestFetchingSelector,
  type IWithRequestsState,
} from 'store/requests/selectors';

import { getApplicationsManagers, getPaidApplicationsManagers, type WithAccountsState } from '../accounts/selectors';
import { getOwner } from '../agents/selectors';
import { type IExternalPaymentProvider } from '../integration-license-properties/interfaces';
import { getExternalPaymentProvider } from '../integration-license-properties/selectors';

import { ApplicationsActionNames } from './actions';
import { ENGAGE_APPLICATIONS } from './constants';
import {
  ApplicationWidgetPlacement,
  ChatApplicationDisabledFeatures,
  type IApplication,
  type IApplicationButton,
  type IApplicationsState,
  type IApplicationWidget,
  type IChatActionButton,
  type IChatApplication,
} from './interfaces';

export interface IWithApplicationsState {
  entities: {
    applications: IApplicationsState;
  };
}
export interface IWithLicenseState {
  features: {
    session: {
      license: { plan: PlanType };
    };
  };
}

type ICustomerDetailsSection = {
  widget: IApplicationWidget;
  initialState: { customerDetailsSections: CustomSectionDefinition[] };
};

export type IAppsMap = KeyMap<IApplication>;
type IChatAppsMap = KeyMap<IChatApplication>;

const applicationsSupportingEmailWithConfirmation = [
  'getresponse',
  'mailchimp',
  'campaignmonitor',
  'icontact',
  'hubspot',
  'activecampaign',
  'constantcontact',
];

export const applicationsSelector = (state: IWithApplicationsState): IAppsMap =>
  state.entities.applications.installed.byIds;

const privateApplicationsSelector = (state: IWithApplicationsState): IApplication[] =>
  state.entities.applications.private;

const inReviewApplicationsSelector = (state: IWithApplicationsState): IApplication[] =>
  state.entities.applications.inReview;

const chatChannelsApplications = (state: IWithApplicationsState): IChatAppsMap =>
  state.entities.applications.installed.channels.byClientIds;

export const appIdByWidgetIdSelector = (state: IWithApplicationsState): KeyMap<string> =>
  state.entities.applications.appIdByWidgetId;

const getApplications = (appsMap: IAppsMap): IApplication[] => Object.values(appsMap);

function createCustomSectionName(widgetId: string, sectionTitle: string): string {
  return `${widgetId}-${sectionTitle.replace(/\s+/g, '-')}`;
}

const getWidgets = (appsMap: IAppsMap): IApplicationWidget[] =>
  getApplications(appsMap).reduce(
    (acc: IApplicationWidget[], app) => acc.concat(Object.values(app.elements.widgets)),
    [],
  );

export const getWidgetsWithPlacement: (state: IWithApplicationsState, placement: string) => IApplicationWidget[] =
  createCachedSelector(
    applicationsSelector,
    (state, placement: string) => placement,
    (appsMap, placement) => getWidgets(appsMap).filter((w) => (w.placement as string) === placement),
  )((state, placement) => placement);

export const getFullscreenWidgets = createSelector([applicationsSelector], (apps) =>
  getWidgets(apps)
    .filter(
      ({ placement, id }) =>
        placement === ApplicationWidgetPlacement.FullScreen && !ENGAGE_APPLICATIONS.some((app) => app.widgetId === id),
    )
    .sort((a, b) => Number(b.isInternalProduct) - Number(a.isInternalProduct)),
);

export const getSettingsWidgets = createSelector([applicationsSelector], (apps) =>
  getWidgets(apps)
    .filter(({ placement }) => placement === ApplicationWidgetPlacement.Settings)
    .sort((a, b) => Number(b.isInternalProduct) - Number(a.isInternalProduct)),
);

export const getEngageWidgets: (state: IWithApplicationsState) => IApplicationWidget[] = createSelector(
  [applicationsSelector],
  (apps) => getWidgets(apps).filter(({ id }) => ENGAGE_APPLICATIONS.some(({ widgetId }) => id === widgetId)),
);

export const getSubscriptionWidgets: (state: IWithApplicationsState) => IApplicationWidget[] = createSelector(
  [applicationsSelector],
  (apps) => getWidgets(apps).filter(({ placement }) => placement === ApplicationWidgetPlacement.Subscription),
);

const getWidgetsContainingInitialState = (appsMap: IAppsMap): ICustomerDetailsSection[] =>
  getWidgets(appsMap)
    .filter((w) => w.shortcut && w.shortcut.initialState)
    .map((w) => {
      let { initialState } = w.shortcut;

      // The API returns the initial state as a stringifed JSON string
      // (e.g. `"{\"customSections\": [ { \"title\": \"Example section\", \"components\": [] } ] }"`),
      // so we need to parse it two times.
      if (/^"(.*)"$/.test(w.shortcut.initialState)) {
        initialState = JSON.parse(initialState);
      }

      try {
        return {
          widget: w,
          initialState: JSON.parse(initialState),
        };
      } catch {
        return null;
      }
    })
    .filter(Boolean)
    .filter(
      (w: ICustomerDetailsSection) =>
        w.initialState && typeof w.initialState === 'object' && Array.isArray(w.initialState.customerDetailsSections),
    );

export const getCustomerDetailsSections = createSelector(
  [applicationsSelector],
  (appsMap): ICustomSections =>
    getWidgetsContainingInitialState(appsMap).reduce((acc, w: ICustomerDetailsSection) => {
      acc[w.widget.id] = w.initialState.customerDetailsSections.reduce(
        (sections, section: CustomSectionDefinition) =>
          isValidSectionDefinition(section)
            ? {
                ...sections,
                [section.title]: {
                  name: createCustomSectionName(w.widget.id, section.title),
                  definition: section,
                },
              }
            : sections,
        {},
      );

      return acc;
    }, {}),
);

export const getChatActionButtons: (state) => IChatActionButton[] = createSelector(
  applicationsSelector,
  (appsMap: IAppsMap) =>
    getApplications(appsMap).reduce(
      (acc: IChatActionButton[], app: IApplication) =>
        acc.concat(Object.values(app.elements.buttons as KeyMap<IChatActionButton>)),
      [],
    ),
);

export const getIsFetchingApplications = createRequestFetchingSelector(['FETCH_COLLECTION_APPLICATION']);
export const getIsFetchingPrivateApplications = createRequestFetchingSelector([
  ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS,
]);

export const hasFetchedApplications = createHasFetchedSelector(['FETCH_COLLECTION_APPLICATION']);
export const hasFailedApplications = createHasFetchFailedSelector(['FETCH_COLLECTION_APPLICATION']);
export const getApplicationsRequestError = createRequestErrorSelector(['FETCH_COLLECTION_APPLICATION']);
export const hasFetchedPrivateApplications = createHasFetchedSelector([
  ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS,
]);
export const hasFailedPrivateApplications = createRequestErrorSelector([
  ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS,
]);
export const hasInitializedApplications = (state: IWithApplicationsState): boolean =>
  state.entities.applications.isInitialized;

export const isApplicationInstalled = (state: IWithApplicationsState, applicationId: Nullable<string>): boolean => {
  if (!applicationId) {
    return false;
  }

  return !!applicationsSelector(state)[applicationId];
};

export const getIsInactivityReasonInstalled = (state: IWithApplicationsState): boolean =>
  isApplicationInstalled(state, getInactivityReasonAppId());

export const isFacebookMessengerInstalled = (state: IWithApplicationsState): boolean =>
  isApplicationInstalled(state, getFBMessengerAppId());

export const isABCInstalled = (state: IWithApplicationsState): boolean => isApplicationInstalled(state, getABCAppId());
export const getIsGoogleAnalyticsInstalled = (state: IWithApplicationsState): boolean =>
  isApplicationInstalled(state, getGoogleAnalyticsAppId());

export const getIsHelpDeskInstalled = (state: IWithApplicationsState): boolean =>
  isApplicationInstalled(state, getHelpDeskAppId());

export const getIsInsightsInstalled = (state: IWithApplicationsState & IWithSessionState): boolean => {
  const isAppInstalled = isApplicationInstalled(state, getInsightsAppId());
  const isStarter = getIsStarterPlan(state);

  return isAppInstalled && !isStarter;
};

export const getIsChatAnalyserInstalled = (state: IWithApplicationsState): boolean =>
  isApplicationInstalled(state, getchatAnalyserAppId());

export const isAnyEmailConfirmationAppInstalled = createSelector(
  (state: IWithApplicationsState) => state,
  (state) => applicationsSupportingEmailWithConfirmation.some((app) => isApplicationInstalled(state, app)),
);

export const getIsChatSentimentEnabled = (state: IWithApplicationsState & IWithSessionState): boolean => {
  const canUseChatsSentiment = getCanUseFeature(state, Feature.ChatsSentiment);

  return canUseChatsSentiment && isApplicationInstalled(state, getChatSentimentAppId());
};

export const getInstalledApplications: (state: IWithApplicationsState) => IApplication[] = createSelector(
  applicationsSelector,
  getApplications,
);

export const getInstalledIntegrations: (state: IWithApplicationsState) => IApplication[] = createSelector(
  getInstalledApplications,
  (installedApps: IApplication[]) =>
    installedApps.filter(
      (app) =>
        app.id === getFBMessengerAppId() ||
        app.id === getWhatsAppAppId() ||
        app.id === getTwilioAppId() ||
        app.id === getTelegramBusinessAppId(),
    ),
);

export const getIsAutomatedCodeInstallationSupported: (state: IWithApplicationsState) => boolean = createSelector(
  getInstalledApplications,
  (installedApps: IApplication[]) => installedApps.some((app) => getSellingExperienceAppsIds().includes(app.id)),
);

export const getInstalledApplicationsForChatNotInstalledBanner: (state: IWithApplicationsState) => IApplication[] =
  createSelector(getInstalledApplications, (installedApps: IApplication[]) =>
    installedApps.filter((app) => getSellingExperienceAppsIds().includes(app.id)),
  );

/**
 * Our internal apps that should not visible in the marketplace
 */
const isBackendApp = (app: IApplication): boolean => {
  const backendAppsIds = [getChatSentimentAppId(), getAutoChatSummaryAppId(), getRtmSentimentWebhooksAppId()];

  return backendAppsIds.includes(app.id);
};

const filterInstalledApps = (app: IApplication): boolean => {
  return !app.isService && !app.hasSettingsHidden && !isBackendApp(app);
};

const getFilteredInstalledApplications: (state: IWithApplicationsState) => IApplication[] = createSelector(
  getInstalledApplications,
  (installedApps: IApplication[]) => installedApps.filter(filterInstalledApps),
);

const getInstalledPrivateApplications: (state: IWithApplicationsState) => IApplication[] = createSelector(
  privateApplicationsSelector,
  applicationsSelector,
  (privateApps: IApplication[], installedApps) => privateApps.filter((app) => !!installedApps[app.id]),
);

export const getFilteredInstalledAndPrivateSortedApplications: (state: IWithApplicationsState) => IApplication[] =
  createSelector(
    getFilteredInstalledApplications,
    getInstalledPrivateApplications,
    (apps: IApplication[], privateApps: IApplication[]) => {
      const sortedApps = [...apps].sort(sortByName);
      const sortedPrivateApps = [...privateApps].sort(sortByName);

      return [...sortedApps.filter((app) => !privateApps.find(({ id }) => id === app.id)), ...sortedPrivateApps];
    },
  );

export const getFilteredInstalledAndPrivateSortedApplicationsIds: (state: IWithApplicationsState) => string[] =
  createSelector(getFilteredInstalledAndPrivateSortedApplications, (apps: IApplication[]) => {
    return apps.map(({ id }) => id);
  });

export const getApplication = (state: IWithApplicationsState, appId: string): IApplication => {
  const apps = applicationsSelector(state);

  return apps[appId];
};

export const getPrivateApplication = (state: IWithApplicationsState, appId: string): IApplication => {
  const apps = privateApplicationsSelector(state);

  return apps.find(({ id }) => id === appId);
};

export const getInReviewApplication = (state: IWithApplicationsState, appId: string): IApplication => {
  const apps = inReviewApplicationsSelector(state);

  return apps.find(({ id }) => id === appId);
};

const getInstalledApplicationBySlug = (state: IWithApplicationsState, appSlug: string): IApplication => {
  const apps = getInstalledApplications(state) || [];

  return apps.find(({ slug }) => slug === appSlug);
};

export const getChatApplication = (state: IWithApplicationsState, clientId: string): IChatApplication => {
  const apps = chatChannelsApplications(state);

  return apps[clientId] || null;
};

export const getChattingApplications: (state: IWithApplicationsState) => IApplication[] = createSelector(
  applicationsSelector,
  chatChannelsApplications,
  (appsMap: IAppsMap, chatAppsMap: IChatAppsMap) => {
    return getApplications(appsMap).filter((app) => app.clientId && !!chatAppsMap[app.clientId]);
  },
);

export const getIsBanCustomerDisabledForChatApplication = (
  state: IWithApplicationsState,
  clientId: string,
): boolean => {
  const app = getChatApplication(state, clientId);

  return app && app.disabledFeatures && app.disabledFeatures.includes(ChatApplicationDisabledFeatures.BanCustomer);
};

export const getApplicationButton = (
  state: IWithApplicationsState,
  appId: string,
  buttonId: string,
): IApplicationButton => {
  const application = getApplication(state, appId);
  if (!application) {
    return null;
  }

  return application.elements.buttons[buttonId] || null;
};

export const getApplicationOpenWidgetButton = (
  state: IWithApplicationsState,
  appId: string,
): IApplicationButton | null => {
  const application = getApplication(state, appId);
  if (!application) {
    return null;
  }

  const buttons = Object.values(application.elements.buttons || {});

  return buttons.find(({ action }) => action === 'openWidget');
};

export const getApplicationWidget = (
  state: IWithApplicationsState,
  appId: string,
  widgetId: string,
): IApplicationWidget => {
  const application = getApplication(state, appId);
  if (!application) {
    return null;
  }

  return application.elements.widgets[widgetId] || null;
};

const getNotPublishedApplicationBySlug = (state: IWithApplicationsState, slug: string): IApplication => {
  return getPrivateApplication(state, slug) || getInReviewApplication(state, slug) || getApplication(state, slug);
};

export const getApplicationBySlug = (state: IWithApplicationsState, slug?: string): IApplication | null => {
  if (!slug) {
    return null;
  }

  const publishedApp = getInstalledApplicationBySlug(state, slug);
  const notPublishedApp = getNotPublishedApplicationBySlug(state, slug);

  return publishedApp || notPublishedApp;
};

export const hasFetchedAllApplicationLists = (state: IWithRequestsState): boolean => {
  const isInstalledAppsFetched = hasFetchedApplications(state);
  const hasPrivateAppsFinishedFetching = hasFetchedPrivateApplications(state) || !!hasFailedPrivateApplications(state);

  return isInstalledAppsFetched && hasPrivateAppsFinishedFetching;
};

export const hasInitializedAllApplicationLists = (state: IWithApplicationsState & IWithRequestsState): boolean => {
  const isInstalledAppsInitialized = hasInitializedApplications(state);
  const isPrivateAppsFetched = hasFetchedPrivateApplications(state) || !!hasFailedPrivateApplications(state);

  return isInstalledAppsInitialized && isPrivateAppsFetched;
};

export const getCanUninstallApplication = (state: IWithApplicationsState & IWithSessionState, id: string): boolean => {
  const { isPaidApp, isUninstallableOnlyByOwners } = getApplication(state, id);

  const canManageApplications = getCanManageApplications(state);
  const canManagePayments = getCanManageApplicationPayments(state);
  const canUninstallApp = isPaidApp ? canManagePayments && canManageApplications : canManageApplications;

  if (isUninstallableOnlyByOwners) {
    return isOwner(state) && canUninstallApp;
  }

  return canUninstallApp;
};

export const getManagersList = (state: IWithApplicationsState & WithAccountsState, id: string): IAccount[] => {
  const { isPaidApp, isUninstallableOnlyByOwners } = getApplication(state, id);

  if (isUninstallableOnlyByOwners) {
    const { accountId, name, login } = getOwner(state);

    return [
      {
        accountId,
        name,
        email: login,
        roles: [],
        createdAt: '',
      },
    ];
  }

  if (isPaidApp) {
    return getPaidApplicationsManagers(state);
  }

  return getApplicationsManagers(state);
};

const getCustomerDetailsSectionsDefinitions = createSelector(
  [applicationsSelector],
  (appsMap): KeyMap<CustomSectionDefinition> =>
    getWidgetsContainingInitialState(appsMap).reduce((acc, w: ICustomerDetailsSection) => {
      w.initialState.customerDetailsSections
        .filter(isValidSectionDefinition)
        .forEach((section: CustomSectionDefinition) => {
          const name = createCustomSectionName(w.widget.id, section.title);

          return (acc[name] = { ...section, id: name });
        });

      return acc;
    }, {}),
);

const getCustomWidgets = (state: IWithApplicationsState): KeyMap<CustomSectionDefinition> =>
  state.entities.applications.privateWidgets.byIds;

export const getCustomSectionsDefinitions = createSelector(
  [getCustomerDetailsSectionsDefinitions, getCustomWidgets],
  (customerDetailsSectionsDefinitions, customWidgets) => ({ ...customerDetailsSectionsDefinitions, ...customWidgets }),
);

export const getWidgetById = createSelector(
  [applicationsSelector, appIdByWidgetIdSelector, (state, widgetId: string) => widgetId],
  (appsMap, appIdByWidgetId, widgetId) => {
    const appId = appIdByWidgetId[widgetId];
    const app = appsMap[appId];

    return (app && app.elements.widgets[widgetId]) || null;
  },
);

export const getPaymentProviderWidget = createSelector(
  [getSubscriptionWidgets, getExternalPaymentProvider],
  (widgets, provider: IExternalPaymentProvider) => {
    if (!provider.widgetId) {
      return null;
    }

    const whitelistedAppsIds = getConfig().externalPaymentProviders.whitelistedAppsIds;

    return (
      widgets.find((widget) => widget.id === provider.widgetId && whitelistedAppsIds.includes(widget.appId)) || null
    );
  },
);

export const getAlternativeFullscreenWidgetPath = (state: IWithApplicationsState, appId: string): string => {
  const application = getApplication(state, appId);

  return application?.alternativeFullscreenWidgetPath;
};

export const getInstalledApplicationByClientId = createSelector(
  [applicationsSelector, (_, clientId: string) => clientId],
  (appsMap, clientId) => {
    const apps = Object.values(appsMap);

    return apps.find((app) => app?.clientId === clientId) || null;
  },
);

export const getInitialSelectedApplicationIds = (state: IWithApplicationsState): string[] =>
  state.entities.applications.selectedApplications;

export const getInitialUnselectedApplicationIds = (state: IWithApplicationsState): string[] =>
  state.entities.applications.unselectedApplications;

export const getSelectedApplications = createSelector(
  [getInitialSelectedApplicationIds, getFilteredInstalledAndPrivateSortedApplications],
  (initialSelectedAppsIds, installedApplications): IApplication[] =>
    installedApplications.filter(({ id }) => initialSelectedAppsIds.includes(id)),
);

const getSelectedApplicationsIds = (state: IWithApplicationsState & IWithAgentCustomPropertiesState): string[] =>
  getSelectedApplications(state).map(({ id }) => id);

export const getUnselectedApplications = createSelector(
  [getInitialSelectedApplicationIds, getFilteredInstalledAndPrivateSortedApplications],
  (selectedApplicationsIds: string[], installedApplications: IApplication[]): IApplication[] =>
    selectedApplicationsIds.length
      ? installedApplications.filter(({ id }) => !selectedApplicationsIds.includes(id))
      : installedApplications,
);

const getUnselectedApplicationsIds = (state: IWithApplicationsState): string[] =>
  getUnselectedApplications(state).map(({ id }) => id);

export const getCustomApplicationsOrder = (state: IWithApplicationsState): string[] =>
  state.entities.applications.applicationsOrder;

const getDefaultApplicationsOrder = (state: IWithApplicationsState & IWithAgentCustomPropertiesState): string[] => {
  const selectedApplications = getSelectedApplicationsIds(state);
  const unselectedApplications = getUnselectedApplicationsIds(state);

  return [...selectedApplications, ...unselectedApplications];
};

export const getApplicationsOrder = (state: IWithApplicationsState & IWithAgentCustomPropertiesState): string[] => {
  const customOrder = getCustomApplicationsOrder(state);
  const defaultOrder = getDefaultApplicationsOrder(state);

  return customOrder.length ? customOrder : defaultOrder;
};
