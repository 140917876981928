import * as Sentry from '@sentry/browser';

import { type StartupDataResultFailure } from 'entries/base/interfaces/startup-data';
import { AppStateProvider } from 'services/app-state-provider';
import { renderMainLayout } from 'services/render-main-layout';
import { handleInitializationError } from 'services/server/handle-initialization-error';
import { initializeQueryClient } from 'services/startup/initialize/initialize-query-client';
import { initializeStore } from 'services/startup/initialize/initialize-store';
import { initializeTrackingTools } from 'services/startup/initialize/initialize-tracking-tools';
import { SessionActions } from 'store/features/session/actions';

import { initializeBase } from './initialize-base';

export async function initializeWithError(errorResult: StartupDataResultFailure): Promise<void> {
  const { failReason: error, startupData } = errorResult;

  Sentry.addBreadcrumb({
    type: 'debug',
    category: 'startup-error',
    message: `Error initializing app: ${JSON.stringify(error)}`,
  });

  initializeBase();

  if (startupData) {
    initializeQueryClient(startupData.licenseProperties);
    initializeStore(startupData);
  }

  initializeTrackingTools();

  AppStateProvider.dispatch(SessionActions.updateRights());

  renderMainLayout();

  await handleInitializationError(error, true);
}
