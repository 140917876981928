// @ts-strict-ignore
import { ChatRecipients } from 'constants/chat-recipients';
import { UserType } from 'constants/user-type';
import type { ChatUserResultType } from 'interfaces/api/chat';
import type { ThreadEventResult } from 'interfaces/api/event/thread';
import { type Event } from 'services/connectivity/agent-chat-api/types/event';

export function deserializeEventUserType(event?: ThreadEventResult | Event, user?: ChatUserResultType): UserType {
  if (event) {
    const isSupervisorMessage = event.visibility === ChatRecipients.Agents;

    if (isSupervisorMessage) {
      return UserType.Supervisor;
    }
  }

  return user?.type;
}
