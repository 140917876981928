export const sourcesThatInstallsCodeAutomatically = [
  'shopify.com',
  'weebly.com',
  'wordpress.org',
  'drupal.org',
  'joomla.org',
  'whmcs.com',
  'wix.com',
  'weebly.com',
  '1and1.com',
  'app.site123.com',
  'ecwid.com',
  'magento.com',
  'marketplace.magento.com',
  'shopify.com',
  'volusion.com',
  'woocommerce.com',
  'bigcommerce.com',
  'nopcommerce.com',
  'cs-cart.com',
  'prestashop.com',
  'easydigitaldownloads.com',
  'opencart.com',
  'oscommerce.com',
  'shoplo.com',
  'squarespace.com',
  'squareup.com',
  'webflow.com',
];

export const sourcesThatDisallowsDesktopApp = ['shopify.com'];
