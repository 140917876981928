import { type MouseEvent, type FC } from 'react';

import { cx } from '@emotion/css';
import { Search } from '@livechat/design-system-icons';
import { Icon, Tag, Text } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalModal } from 'constants/global-modal';
import { SpotlightTrackEvent } from 'constants/spotlight';
import { getModKey } from 'helpers/keyboard/keyboard';
import { trackEvent } from 'services/event-tracking';
import { HomeScreenTourSteps } from 'services/tour/home-screen/steps';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { GlobalModalActions } from 'store/features/global-modals/actions';

import * as styles from '../styles';

import { SPOTLIGHT_TRIGGER_TEST_ID } from './constants';

export const SpotlightTrigger: FC = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector(isDarkModeEnabled);
  const tourId = HomeScreenTourSteps.Spotlight;

  const handleTriggerClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    dispatch(GlobalModalActions.showModal(GlobalModal.Spotlight));

    trackEvent(SpotlightTrackEvent.SpotlightTrigger, null, {
      source: 'search input click',
    });
  };

  return (
    <div className={styles.spotlightWrapper}>
      <div
        className={styles.spotlightTrigger(isDarkMode)}
        onClick={handleTriggerClick}
        data-testid={SPOTLIGHT_TRIGGER_TEST_ID}
        data-is-spotlight="true"
        id={tourId}
      >
        <div className={cx('spotlightTrigger', styles.triggerBackground(isDarkMode))} />
        <div className={styles.searchWrapper}>
          <Icon source={Search} />
          <Text>Search or ask</Text>
        </div>
        <div className={styles.shortcutWrapper}>
          <Tag className={styles.blackTag}>
            <Text>{getModKey(true)}</Text>
          </Tag>
          <Tag className={styles.blackTag}>
            <Text>K</Text>
          </Tag>
        </div>
      </div>
    </div>
  );
};
