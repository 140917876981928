import { type Button, type ButtonKind } from '@livechat/design-system-react-components';

import { type EventPlace } from 'helpers/analytics';
import { type KeyMap } from 'helpers/interface';
import { type IReportsViewUpdateFiltersPayload } from 'interfaces/reports';
import { type AmplitudeEventProperties } from 'services/amplitude';

type AuthorType = 'live-assistant' | 'agent';

type CopilotMessageType = 'text-message' | 'error-message' | 'image-message';

type ButtonIconType = 'open-in-new' | 'add';

export enum CopilotButtonTypes {
  CONVERSATION = 'conversation',
  NAVIGATE = 'navigate',
}

type CopilotButtonType = CopilotButtonTypes.CONVERSATION | CopilotButtonTypes.NAVIGATE;

interface CopilotButtonBase {
  id: string;
  content: string;
  type: CopilotButtonType;
  kind?: ButtonKind;
  icon?: ButtonIconType;
  iconPosition?: React.ComponentProps<typeof Button>['iconPosition'];
  event?: {
    name: string;
    eventPlace: EventPlace;
    eventProperties?: AmplitudeEventProperties;
  };
}

export interface CopilotNavigateButton extends CopilotButtonBase {
  type: CopilotButtonTypes.NAVIGATE;
  navigate: {
    responseType?: 'final-message' | 'custom';
    url: string;
    isExternal?: boolean;
    delay?: number;
  };
}

export interface CopilotConversationButton extends CopilotButtonBase {
  type: CopilotButtonTypes.CONVERSATION;
  conversation: {
    agentMessage: string;
    copilotMessage: string;
    delay?: number;
  };
}

export type CopilotButton = CopilotNavigateButton | CopilotConversationButton;

export interface CopilotNavigateActionPayload {
  eventId: string;
  content: string;
  props: CopilotNavigateButton['navigate'];
}

interface CopilotConversationMessage {
  event: CopilotEvent;
  delay?: number;
}

export type CopilotAddConversationPayload = CopilotConversationMessage[];

export type CopilotProperties = {
  buttons?: CopilotButton[];
  sources?: Source[];
  withoutActions?: boolean;
  rated?: 'good' | 'bad';
  hasCancelledRequest?: boolean;
  isOnboarding?: boolean;
};

interface WithCopilotEventType {
  type: CopilotMessageType;
  eventId: string;
  traceId?: string;
  sessionId?: string;
  authorId: string;
  authorType: AuthorType;
  timestampInMs: number;
}

export interface CopilotMessageEvent extends WithCopilotEventType {
  header: string;
  text: string;
  properties?: CopilotProperties;
}

export interface CopilotErrorEvent extends WithCopilotEventType {
  properties?: CopilotProperties;
}

export type CopilotEvent = CopilotMessageEvent | CopilotErrorEvent;

export type CopilotEntitiesState = {
  currentSessionId: string | null;
  events: {
    byIds: KeyMap<CopilotEvent>;
    groupedIds: string[][];
    messageWithCopilotQuestions?: string;
  };
  isError?: boolean;
  failedMessageContent?: string;
  isMessageFromSpotlight: boolean;
};

export type WithCopilotEntitiesState = {
  entities: {
    copilot: CopilotEntitiesState;
  };
};

type BaseSource = {
  name: string;
};

export type URLSource = BaseSource & {
  type: 'url';
  url: string;
  skillId: string;
};

export type APISource = BaseSource & {
  type: 'api_call';
  url: string;
  params: IReportsViewUpdateFiltersPayload;
};

export type PDFSource = BaseSource & {
  type: 'file';
  fileType: 'pdf';
  fileName: string;
  skillId: string;
};

export type Source = URLSource | APISource | PDFSource;
