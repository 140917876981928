import AccountsSDK from '@livechat/accounts-sdk';
import * as Sentry from '@sentry/browser';

import { ToastContent } from 'constants/toasts';
import { getConfig } from 'helpers/config';
import { redirectToAccounts } from 'helpers/redirect-to-accounts';
import { showToast } from 'helpers/toast';
import { storeCredentials } from 'services/auth/auth-storage-manager';
import { isError } from 'services/connectivity/http/type-guards';
import { reinitializeApp } from 'services/server/reinitialize-app';
import { AgentDisconnectedReason } from 'services/socket-lc3/agent/interfaces';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { runAppSagas, stopAppSagas } from 'store/sagas/app-sagas';

import { getWebsocketConnection } from './websocket-connection';

/* eslint-disable @typescript-eslint/naming-convention */
interface ReauthorizeError {
  description: string;
  identity_exception: string;
}

interface ReauthorizeOptions {
  client_id: string;
  server_url: string;
}

interface ReauthorizeResponse {
  access_token: string;
  scope: string;
  expires_in: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export async function reauthorize(): Promise<ReauthorizeResponse> {
  const { accountsClientId, accounts } = getConfig();
  const options: ReauthorizeOptions = {
    /* eslint-disable @typescript-eslint/naming-convention */
    client_id: accountsClientId,
    server_url: accounts,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const sdk = new AccountsSDK(options);

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const response: ReauthorizeResponse = await sdk.iframe(options).authorize();

    return response;
  } catch (error: unknown) {
    if (isError(error) && error.message === 'timeout') {
      return reauthorize();
    }

    throw error;
  }
}

export async function reauthorizeAndReinitialize(reason: AgentDisconnectedReason, cause?: string): Promise<void> {
  Sentry.addBreadcrumb({
    type: 'debug',
    level: 'log',
    category: 'reauthorize',
    message: `Reauthorizing. Reason - ${reason}.`,
    data: {
      reason,
      cause,
    },
  });

  getWebsocketConnection().disconnect();
  stopAppSagas();

  let response: ReauthorizeResponse | null = null;

  try {
    response = await reauthorize();
  } catch (error: unknown) {
    if ((error as ReauthorizeError).identity_exception !== 'unauthorized') {
      Sentry.captureException(error, {
        tags: {
          action: 'reauthorizeAndReinitialize',
          reason,
          cause,
        },
      });
    }

    redirectToAccounts();

    return;
  }

  storeCredentials({
    token: response.access_token,
    scopesString: response.scope,
    isGhost: false,
    expiresIn: response.expires_in,
  });

  runAppSagas();

  await reinitializeApp({
    accessToken: response.access_token,
    isGhost: false,
  });

  if (reason === AgentDisconnectedReason.RolePermissionChanged) {
    showToast(ToastVariant.Info, ToastContent.YOU_HAVE_BEEN_RE_LOGGED);
  }
}
