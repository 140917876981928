import { type FC } from 'react';

import { useDispatch } from 'react-redux';

import { uniqueId } from 'helpers/string';
import { CopilotEntitiesActions } from 'store/entities/copilot/actions';

import { COPILOT_POPOVER_SUGGESTIONS_TEST_ID } from '../constants';
import { useCopilotSuggestions } from '../hooks/use-copilot-suggestions';

import { STATIC_MESSAGE, STATIC_SUGGESTION_DELAY, STATIC_QUESTION } from './constants';
import { PopoverSuggestion } from './PopoverSuggestion';

import * as styles from './styles';

export const PopoverSuggestions: FC = () => {
  const { randomizedSuggestions, shouldShowSuggestions } = useCopilotSuggestions();
  const dispatch = useDispatch();

  if (!shouldShowSuggestions || !randomizedSuggestions.length) {
    return null;
  }

  const handleOnClick = (): void => {
    dispatch(
      CopilotEntitiesActions.addCopilotConversation([
        {
          event: {
            authorId: 'agent',
            authorType: 'agent',
            eventId: uniqueId(),
            text: STATIC_QUESTION,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
          delay: STATIC_SUGGESTION_DELAY,
        },
        {
          event: {
            authorId: 'live-assistant',
            authorType: 'live-assistant',
            eventId: uniqueId(),
            text: STATIC_MESSAGE,
            timestampInMs: Date.now(),
            type: 'text-message',
          },
        },
      ]),
    );
  };

  return (
    <div className={styles.popoverSuggestionsWrapper} data-testid={COPILOT_POPOVER_SUGGESTIONS_TEST_ID}>
      {randomizedSuggestions.map(({ message }) => (
        <PopoverSuggestion key={message} message={message} />
      ))}
      <PopoverSuggestion message={STATIC_QUESTION} onClick={handleOnClick} />
    </div>
  );
};
