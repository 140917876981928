import { type FC, Suspense, useEffect, useState } from 'react';

import { ThemeProvider } from '@livechat/design-system-react-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ApplicationsDataSuspender } from 'components/ApplicationsDataSuspender';
import { DiscardChangesModalContainer } from 'components/discard-changes-modal/DiscardChangesModal';
import { FullPageWrapper } from 'components/full-page-wrapper/FullPageWrapper';
import { NavigateWithParams } from 'components/navigate-with-params/NavigateWithParams';
import { ReactQueryDevTools } from 'components/react-query-dev-tools/ReactQueryDevTools';
import { SuspenseWrapper } from 'components/suspense-wrapper/SuspenseWrapper';
import { ToastContainer } from 'components/toast/ToastContainer';
import { NavigationPath } from 'constants/navigation';
import { ThemeName } from 'constants/themes';
import { shouldApplySelectedTheme } from 'helpers/dark-mode';
import { getSectionFromPathname } from 'helpers/routing';
import { useAppTheme } from 'hooks/use-app-theme';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useRouteOverride } from 'hooks/use-route-override';
import { useUpdateOnlyEffect } from 'hooks/use-update-only-effect';
import { SubscriptionSkeleton } from 'routes/subscription/subscription-skeleton/SubscriptionSkeleton';
import { getThemeAvailableOnCurrentSection } from 'services/dark-mode';
import { lazyWithRetry } from 'services/lazy-load-components';
import { LocalStorageKey } from 'services/local-storage';
import { getQueryClient } from 'services/query-client/client';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { getIsHelpDeskInstalled } from 'store/entities/applications/selectors';
import { selectIsDisableNativeTicketsExperiment } from 'store/entities/experiments/selectors';
import { getCurrentTheme } from 'store/features/agent-custom-properties/selectors';
import { RoutingActions } from 'store/features/routing/actions';
import { ToastHorizontalPosition, ToastVerticalPosition } from 'store/features/toasts/interfaces';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';
import { getSignInError } from 'store/views/startup-error/selectors';

import { CopilotMessageContextProvider } from './copilot/CopilotMessageContext';
import CopilotModal from './copilot/CopilotModal';
import { CopilotNewMessageToast } from './copilot/CopilotNewMessageToast';
import { CopilotNotifications } from './copilot/CopilotNotifications';
import NetworkIssues from './error-pages/network-issues/NetworkIssues';
import StartupError from './error-pages/startup-error/StartupError';
import { HelpDeskInLivechat } from './helpdesk-in-livechat/HelpDeskInLivechat';
import { NoNavigationRouteView } from './NoNavigationRouteView';
import { NotFound404 } from './NotFound404';
import { redirectFromAgentsToTeam } from './team/helpers';
import { WithNavigationRouteView } from './WithNavigationRouteView';

const ChatsResolver = lazyWithRetry(
  () => import('routes/chats-new/ChatsResolver' /* webpackPrefetch: true, webpackChunkName: "chats-resolver" */),
);
const Engage = lazyWithRetry(
  () => import('routes/engage/Engage' /* webpackPrefetch: true, webpackChunkName: "engage" */),
);
const Subscription = lazyWithRetry(
  () => import('routes/subscription/Subscription' /* webpackPrefetch: true, webpackChunkName: "subscription" */),
);
const Automate = lazyWithRetry(() => import('routes/automate/Automate' /* webpackChunkName: "automate" */));
const LogoutWrapper = lazyWithRetry(() => import('routes/logout/LogoutWrapper' /* webpackChunkName: "logout" */));
const InstallCodeReminder = lazyWithRetry(
  () => import('routes/onboarding/install-code-reminder/InstallCodeReminder' /* webpackChunkName: "install-code" */),
);
const Home = lazyWithRetry(() => import('routes/home/HomeScreen' /* webpackChunkName: "home-screen" */));
const Reports = lazyWithRetry(() => import('routes/reports/Reports' /* webpackChunkName: "reports" */));
const Tickets = lazyWithRetry(() => import('routes/tickets/Tickets' /* webpackChunkName: "tickets" */));
const Team = lazyWithRetry(() => import('routes/team/Team' /* webpackChunkName: "team" */));
const Onboarding = lazyWithRetry(
  () => import('routes/onboarding/OnboardingRouter' /* webpackChunkName: "onboarding" */),
);
const Apps = lazyWithRetry(() => import('routes/apps/AppsRouter' /* webpackChunkName: "apps" */));
const Archives = lazyWithRetry(
  () => import('routes/archives/ArchivesRouter' /* webpackPrefetch: true, webpackChunkName: "archives" */),
);
const MobileProfile = lazyWithRetry(
  () => import('routes/mobile-profile/MobileProfile' /* webpackChunkName: "mobile-profile" */),
);
const SettingsRouter = lazyWithRetry(() => import('routes/settings/SettingsRouter' /* webpackChunkName: "settings" */));
const SessionAbuse = lazyWithRetry(
  () => import('routes/error-pages/session-abuse/SessionAbuse' /* webpackChunkName: "session-abuse" */),
);
const SignOut = lazyWithRetry(() => import('routes/error-pages/signout/SignOut' /* webpackChunkName: "sign-out" */));
const TicketRating = lazyWithRetry(
  () => import('routes/tickets/ticket-rating/TicketRating' /* webpackChunkName: "ticket-rating" */),
);
const IPNotAllowed = lazyWithRetry(
  () => import('routes/error-pages/ip-not-allowed/IPNotAllowed' /* webpackChunkName: "ip-not-allowed" */),
);
const LicenseExpired = lazyWithRetry(
  () => import('routes/error-pages/license-expired/LicenseExpired' /* webpackChunkName: "license-expired" */),
);
const Suspended = lazyWithRetry(
  () => import('routes/error-pages/suspended/Suspended' /* webpackChunkName: "license-suspended" */),
);
const TooManyAgents = lazyWithRetry(
  () => import('routes/error-pages/too-many-agents/TooManyAgents' /* webpackChunkName: "too-many-agents" */),
);
const WaitingForApproval = lazyWithRetry(
  () =>
    import('routes/error-pages/waiting-for-approval/WaitingForApproval' /* webpackChunkName: "waiting-for-approval" */),
);
const GlobalError = lazyWithRetry(
  () => import('routes/error-pages/GlobalError' /* webpackChunkName: "global-error" */),
);
const Unsubscribe = lazyWithRetry(() => import('./unsubscribe/Unsubscribe' /* webpackChunkName: "unsubscribe" */));
const LicenseInaccessible = lazyWithRetry(
  () =>
    import(
      'routes/error-pages/license-inaccessible/LicenseInaccessible' /* webpackChunkName: "license-inaccessible" */
    ),
);
const DesktopAppRedirectPage = lazyWithRetry(
  () => import('routes/desktop-app/DesktopAppRedirectPage' /* webpackChunkName: "desktop-app-redirect" */),
);
const GlobalModals = lazyWithRetry(() => import('components/global-modals/GlobalModals'));

const MainRouter: FC = () => {
  const location = useLocation();
  const { setTheme } = useAppTheme();
  const [shouldApplyTheme, setShouldApplyTheme] = useState(() => shouldApplySelectedTheme(location.pathname));

  const loggedInAgent = useSelector(getLoggedInAgent);
  const areNativeTicketsDisabled = useSelector(selectIsDisableNativeTicketsExperiment);
  const currentTheme = useSelector(getCurrentTheme);
  const canUseCopilotChat = useSelector(getCanUseCopilotPopover);
  const isHelpDeskInstalled = useSelector(getIsHelpDeskInstalled);
  const isInErrorMode = !!useSelector(getSignInError);

  const {
    shouldShowOnboarding,
    shouldShowInstallCode,
    hasAccessToAppRoutes,
    shouldShowStartupError,
    shouldShowNetworkIssues,
    shouldShowDesktopLogin,
  } = useRouteOverride();

  const shouldDisableTicketsRoutes = areNativeTicketsDisabled || isHelpDeskInstalled;
  const shouldShowApp = hasAccessToAppRoutes && !shouldShowOnboarding && !shouldShowInstallCode;

  useEffect(() => {
    const shouldApply = shouldApplySelectedTheme(location.pathname);
    if (shouldApply !== shouldApplyTheme) {
      setShouldApplyTheme(shouldApply);
      setTheme(getThemeAvailableOnCurrentSection(currentTheme));
    }
  }, [currentTheme, location.pathname, setTheme, shouldApplyTheme]);

  useUpdateOnlyEffect(() => {
    setTheme(getThemeAvailableOnCurrentSection(currentTheme));
  }, [currentTheme, setTheme]);

  const dispatch = useDispatch();

  useEffect(() => {
    const pathname = location.pathname;
    const section = getSectionFromPathname(pathname);

    if (!section) {
      return;
    }

    dispatch(RoutingActions.setCurrentSection(section));
  }, [location.pathname, dispatch]);

  return (
    <QueryClientProvider client={getQueryClient()}>
      <Routes>
        {/* START: Always available routes */}
        {isInErrorMode && (
          <Route
            path="subscription/*"
            element={
              <NoNavigationRouteView title="Subscription">
                <Suspense fallback={<SubscriptionSkeleton isExpired={false} showOnlyTabs />}>
                  <Subscription />
                </Suspense>
              </NoNavigationRouteView>
            }
          />
        )}
        <Route
          path="keep-on-chatting-with-customers"
          element={
            <SuspenseWrapper>
              <LogoutWrapper loggedInAgent={loggedInAgent} />
            </SuspenseWrapper>
          }
        />
        <Route
          path="ticket_rating/:ticketId/:rate/:hash"
          element={
            <NoNavigationRouteView>
              <TicketRating />
            </NoNavigationRouteView>
          }
        />
        <Route
          path="unsubscribe/:type/:hash"
          element={
            <NoNavigationRouteView>
              <Unsubscribe />
            </NoNavigationRouteView>
          }
        />
        <Route
          path="access-blocked"
          element={
            <NoNavigationRouteView title="Access Blocked">
              <SessionAbuse />
            </NoNavigationRouteView>
          }
        />
        <Route
          path="signout"
          element={
            <NoNavigationRouteView>
              <SignOut />
            </NoNavigationRouteView>
          }
        />
        <Route
          path="error.html"
          element={
            <NoNavigationRouteView>
              <GlobalError />
            </NoNavigationRouteView>
          }
        />
        {/* END: Always available routes */}

        {/* START: Popup routes */}
        {shouldShowOnboarding && (
          <Route
            path="welcome/*"
            element={
              <FullPageWrapper>
                <WithNavigationRouteView>
                  <Onboarding />
                </WithNavigationRouteView>
              </FullPageWrapper>
            }
          />
        )}
        {shouldShowInstallCode && (
          <Route
            path="install-code"
            element={
              <FullPageWrapper>
                <WithNavigationRouteView title="Install LiveChat code">
                  <InstallCodeReminder />
                </WithNavigationRouteView>
              </FullPageWrapper>
            }
          />
        )}
        {shouldShowStartupError && (
          <Route
            path="startup-error"
            element={
              <NoNavigationRouteView>
                <StartupError />
              </NoNavigationRouteView>
            }
          />
        )}
        {shouldShowNetworkIssues && (
          <Route
            path="network-issues"
            element={
              <NoNavigationRouteView>
                <NetworkIssues />
              </NoNavigationRouteView>
            }
          />
        )}
        {shouldShowDesktopLogin && (
          <Route
            path="app-signin"
            element={
              <SuspenseWrapper>
                <DesktopAppRedirectPage />
              </SuspenseWrapper>
            }
          />
        )}
        {/* END: Popup routes */}

        {/* START: App Router */}
        {shouldShowApp && (
          <>
            <Route
              path="apps/*"
              element={
                <WithNavigationRouteView title="Apps">
                  <Apps />
                </WithNavigationRouteView>
              }
            />
            <Route path="app/*" element={<NavigateWithParams to="/apps/*" />} />
            <Route
              path="subscription/*"
              element={
                <WithNavigationRouteView title="Subscription">
                  <Suspense fallback={<SubscriptionSkeleton isExpired={false} showOnlyTabs />}>
                    <Subscription />
                  </Suspense>
                </WithNavigationRouteView>
              }
            />
            <Route
              path="home"
              element={
                <WithNavigationRouteView title="Home">
                  <Home />
                </WithNavigationRouteView>
              }
            />
            <Route
              path="automate/*"
              element={
                <WithNavigationRouteView title="Automate">
                  <Automate />
                </WithNavigationRouteView>
              }
            />
            <Route
              path={`${NavigationPath.Team}/*`}
              element={
                <WithNavigationRouteView title="Team">
                  <Team />
                </WithNavigationRouteView>
              }
            />
            <Route
              path={`${NavigationPath.Agents}/*`}
              element={<Navigate to={redirectFromAgentsToTeam(location.pathname)} replace />}
            />
            <Route
              path="chats/*"
              element={
                <WithNavigationRouteView title="Chats">
                  <Routes>
                    <Route path="/" element={<ChatsResolver />} />
                    <Route path="/:chatId" element={<ChatsResolver />} />
                    <Route path="/:chatId/:threadId" element={<ChatsResolver />} />
                  </Routes>
                </WithNavigationRouteView>
              }
            />
            <Route path="traffic/*" element={<Navigate to="/engage/traffic" replace />} />
            <Route
              path="engage/*"
              element={
                <WithNavigationRouteView title="Engage">
                  <Engage />
                </WithNavigationRouteView>
              }
            />
            <Route
              path="archives/*"
              element={
                <WithNavigationRouteView title="Archives">
                  <Archives />
                </WithNavigationRouteView>
              }
            />
            <Route
              path="tickets/*"
              element={
                !shouldDisableTicketsRoutes ? (
                  <WithNavigationRouteView title="Tickets">
                    <Tickets />
                  </WithNavigationRouteView>
                ) : (
                  <Navigate to="/reports/queue-abandonment" replace />
                )
              }
            />
            <Route
              path="reports/*"
              element={
                <WithNavigationRouteView title="LiveChat Reports">
                  <ApplicationsDataSuspender>
                    <Reports />
                  </ApplicationsDataSuspender>
                </WithNavigationRouteView>
              }
            />
            <Route
              path="settings/*"
              element={
                <WithNavigationRouteView>
                  <SettingsRouter />
                </WithNavigationRouteView>
              }
            />
            <Route
              path="mobile-profile"
              element={
                <WithNavigationRouteView title="Profile">
                  <MobileProfile />
                </WithNavigationRouteView>
              }
            />
            {!isHelpDeskInstalled && (
              <Route
                path="helpdesk-in-livechat"
                element={
                  <WithNavigationRouteView title="HelpDesk">
                    <HelpDeskInLivechat />
                  </WithNavigationRouteView>
                }
              />
            )}
            <Route path="chatbot-in-livechat" element={<Navigate to="/automate/chatbots" replace />} />
          </>
        )}
        {/* END: App Router */}

        {/* START: Error Pages */}
        {isInErrorMode && (
          <>
            <Route
              path="license-expired"
              element={
                <NoNavigationRouteView title="License expired">
                  <LicenseExpired />
                </NoNavigationRouteView>
              }
            />
            <Route
              path="too-many-agents"
              element={
                <NoNavigationRouteView title="Too many agents">
                  <TooManyAgents />
                </NoNavigationRouteView>
              }
            />
            <Route
              path="suspended"
              element={
                <NoNavigationRouteView title="Suspended">
                  <Suspended />
                </NoNavigationRouteView>
              }
            />
            <Route
              path="waiting-for-approval"
              element={
                <NoNavigationRouteView title="Waiting for approval">
                  <WaitingForApproval />
                </NoNavigationRouteView>
              }
            />
            <Route
              path="ip-not-allowed"
              element={
                <NoNavigationRouteView title="IP not allowed">
                  <IPNotAllowed />
                </NoNavigationRouteView>
              }
            />
            <Route
              path="license-inaccessible"
              element={
                <NoNavigationRouteView title="License inaccessible">
                  <LicenseInaccessible />
                </NoNavigationRouteView>
              }
            />
          </>
        )}
        {/* END: Error Pages */}

        <Route path="*" element={<NotFound404 />} />
      </Routes>
      <ReactQueryDevTools />
      <ToastContainer
        verticalPosition={ToastVerticalPosition.Top}
        horizontalPosition={ToastHorizontalPosition.Center}
        fixed
      />
      {canUseCopilotChat && (
        <CopilotMessageContextProvider>
          <CopilotNewMessageToast />
          <CopilotModal />
        </CopilotMessageContextProvider>
      )}
      <CopilotNotifications />
      <DiscardChangesModalContainer />
      <SuspenseWrapper fallback={null}>
        <GlobalModals />
      </SuspenseWrapper>
    </QueryClientProvider>
  );
};

const MainRouterWithTheme: FC = () => {
  const [theme] = useLocalStorage<ThemeName>(LocalStorageKey.UserTheme, ThemeName.Light);

  return (
    <ThemeProvider initialTheme={theme}>
      <MainRouter />
    </ThemeProvider>
  );
};

export default MainRouterWithTheme;
