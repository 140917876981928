// @ts-strict-ignore
import type { Context, ErrorEvent, EventHint, Primitive, SamplingContext, StartSpanOptions } from '@sentry/core';
import * as Sentry from '@sentry/react';

import envConfig from 'env-config';

import { isAudioPlayNotAllowedException } from './cases/audio-play-not-allowed';
import { ignoredErrors } from './cases/ignored-errors';
import {
  tracingConfig,
  CHAT_ID_REGEX,
  UUID_REGEX,
  EXCLUDED_SUBPATHS,
  SentryTraceOperation,
  SentryOrigin,
  TRACEABLE_MEASUREMENTS,
  TRANSACTION_IDLE_TIMEOUT,
  profilesSampleRate,
} from './constants';
import { sentryContext } from './context';
import { isEventWithInTheLimit } from './helpers/events-limitation';
import { isUnsupportedSystemOrBrowser } from './helpers/is-unsupported-system-or-browser';
import { type TracingConfig } from './types';

declare const BUILD_NUMBER: string;

/**
 * Handles any modifications or ignores before sending the event to Sentry.
 * @param event Sentry event.
 */
function handleBeforeSend(event: ErrorEvent, hint?: EventHint): Promise<ErrorEvent | null> | ErrorEvent | null {
  if (!isEventWithInTheLimit(event, hint)) {
    return null;
  }

  if (isAudioPlayNotAllowedException(event)) {
    return null;
  }

  return event;
}

function getIsTraceablePath(traceablePathNames: string[]): boolean {
  return (
    traceablePathNames.some((pathname) => window.location.pathname.includes(pathname)) ||
    window.location.pathname === '/'
  );
}

function handleTracesSampler(samplingContext: SamplingContext): number {
  const { attributes } = samplingContext;

  const config: TracingConfig = tracingConfig?.[attributes?.['sentry.origin']];
  if (!config) {
    return 0;
  }
  const isTraceablePath = getIsTraceablePath(config.pathNames);

  return isTraceablePath ? config.sampleRate[envConfig.env] : 0;
}

const replacePath = (originalPath: string, subPaths: string[]): string => {
  for (let i = 0; i < subPaths.length; i += 1) {
    const subPath = subPaths[i];
    if (originalPath.includes(subPath)) {
      return subPath;
    }
  }

  return originalPath;
};

function handleBeforeStartSpan(options: StartSpanOptions): StartSpanOptions {
  return {
    ...options,
    name: replacePath(
      window.location.pathname.replace(CHAT_ID_REGEX, '/:id').replace(UUID_REGEX, '/:id'),
      EXCLUDED_SUBPATHS,
    ),
  };
}

function shouldExtendEventContext(event: Sentry.Event): boolean {
  return !event.user?.license || !event.user?.login;
}

async function eventProcessor(event: Sentry.Event): Promise<Sentry.Event | null> {
  if (shouldExtendEventContext(event)) {
    try {
      const context = await sentryContext.waitForContext();
      extendEventContext(event, context);
    } catch (error) {
      Sentry.captureException(error);

      return null;
    }
  }

  if (isInvalidPageloadEvent(event)) {
    return null;
  }

  return event;
}

function extendEventContext(event: Sentry.Event, context: Context): void {
  event.tags = { ...event.tags, ...context } as { [key: string]: Primitive };
  event.user = { ...event.user, ...context } as { [key: string]: Primitive };
}

function isInvalidPageloadEvent(event: Sentry.Event): boolean {
  const { contexts, measurements } = event;
  const traceOp = contexts?.trace?.op as SentryTraceOperation;
  const traceOrigin = contexts?.trace?.origin as SentryOrigin;
  const isPageloadOp = traceOp === SentryTraceOperation.Pageload;
  const isPageloadOrigin = traceOrigin === SentryOrigin.Pageload;
  const hasValidMeasurements =
    measurements && TRACEABLE_MEASUREMENTS.some((measurement) => !!measurements[measurement]);

  return (isPageloadOp || isPageloadOrigin) && !hasValidMeasurements;
}

function initialize(): void {
  Sentry.addEventProcessor(eventProcessor);
  Sentry.init({
    dsn: envConfig.sentry_dsn,
    environment: envConfig.env,
    release: `livechat-web-${envConfig.env}-${BUILD_NUMBER}`,
    ignoreErrors: ignoredErrors,
    beforeSend: handleBeforeSend,
    integrations: [
      Sentry.browserTracingIntegration({
        beforeStartSpan: handleBeforeStartSpan,
        idleTimeout: TRANSACTION_IDLE_TIMEOUT,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampler: handleTracesSampler,
    profilesSampleRate: profilesSampleRate[envConfig.env],
    replaysOnErrorSampleRate: profilesSampleRate[envConfig.env],
  });
}

if (envConfig.sentry_dsn && !isUnsupportedSystemOrBrowser()) {
  initialize();
}
