import { useCallback, useEffect, type FC } from 'react';

import { ChevronRight } from '@livechat/design-system-icons';
import { Icon, SideNavigationGroup, SideNavigationItem } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSessionStorage } from 'hooks/use-session-storage';
import { useAllVisibleApps } from 'routes/apps/hooks/use-all-visible-apps';
import { useSelectedVisibleApps } from 'routes/apps/hooks/use-selected-visible-apps';
import {
  getFilteredInstalledAndPrivateSortedApplications,
  getUnselectedApplications,
  hasInitializedAllApplicationLists,
} from 'store/entities/applications/selectors';
import { hasFetchedAgentCustomProperties } from 'store/features/agent-custom-properties/selectors';

import { normalizeListPathname } from '../helpers';

import { AppsConfigurationList } from './AppsConfigurationList/AppsConfigurationList';
import { INSTALLED_APPS_STORAGE_KEY, SHOW_LESS_APPS_TEST_ID, SHOW_MORE_APPS_TEST_ID } from './constants';
import { YourAppsItem } from './YourAppsItem/YourAppsItem';

import { labelWrapperStyles, labelStyles, showAppsChevronStyles, animationOptimizationStyles } from './styles';

export const YourAppsList: FC = () => {
  const { pathname } = useLocation();
  const listPathname = normalizeListPathname(pathname);
  const [areAllAppsVisible, setAreAllAppsVisible] = useSessionStorage<boolean>(INSTALLED_APPS_STORAGE_KEY, false);

  const installedApplications = useSelector(getFilteredInstalledAndPrivateSortedApplications);
  const arePropertiesFetched = useSelector(hasFetchedAgentCustomProperties);
  const areAppsReady = useSelector(hasInitializedAllApplicationLists);

  const toggleAllAppsVisibility = useCallback(() => setAreAllAppsVisible((prev) => !prev), [setAreAllAppsVisible]);

  const unselectedApplications = useSelector(getUnselectedApplications);
  const [, selectedVisibleAppsIds] = useSelectedVisibleApps();
  const allApps = useAllVisibleApps();

  const hasUnselectedApps = unselectedApplications.length > 0;

  const shouldResetVisibility =
    areAppsReady &&
    arePropertiesFetched &&
    !hasUnselectedApps &&
    unselectedApplications.length !== installedApplications.length;

  useEffect(() => {
    // If all apps are selected and `Show x` gets hidden, reset the visibility state to default
    if (shouldResetVisibility) {
      setAreAllAppsVisible(false);
    }
  }, [shouldResetVisibility, setAreAllAppsVisible]);

  if (!arePropertiesFetched || !areAppsReady || !allApps.length) {
    return null;
  }

  return (
    <SideNavigationGroup
      label="Your apps"
      rightNode={<AppsConfigurationList />}
      labelClassName={labelStyles}
      labelWrapperClassName={labelWrapperStyles}
    >
      {allApps.map((app) => {
        const isVisible = areAllAppsVisible || selectedVisibleAppsIds.includes(app.id);

        return <YourAppsItem key={app.id} app={app} listPathname={listPathname} isVisible={isVisible} />;
      })}

      {hasUnselectedApps && (
        <div
          data-testid={areAllAppsVisible ? SHOW_LESS_APPS_TEST_ID : SHOW_MORE_APPS_TEST_ID}
          className={animationOptimizationStyles}
        >
          <SideNavigationItem
            leftNode={<Icon source={ChevronRight} size="small" className={showAppsChevronStyles(areAllAppsVisible)} />}
            label={areAllAppsVisible ? 'Show less' : 'Show more'}
            onClick={toggleAllAppsVisibility}
          />
        </div>
      )}
    </SideNavigationGroup>
  );
};
