import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

export const labelItem = (isActive: boolean): string => css`
  a {
    &:hover span > div,
    &:hover .your-apps-item-label span > span:nth-of-type(2) {
      opacity: unset;
    }

    .your-apps-item-label span {
      font-weight: ${isActive ? 500 : 400};

      > span:nth-of-type(2) {
        opacity: ${isActive ? 1 : 0.6};
      }
    }
  }
`;

export const nestedItem = css`
  .display-on-hover {
    display: none;
  }

  &:hover {
    .display-on-hover {
      display: flex;
    }
    .hide-siblings-on-hover + * {
      display: none;
    }
  }
`;

export const nestedItemAnchor = css`
  padding-inline: var(${SpacingToken.Spacing6});
`;

export const pin = css`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const pinIcon = css`
  width: 14px;
  height: 14px;
`;

export const badge = css`
  && {
    color: var(${DesignToken.ContentLockedWhite});
  }
`;

export const listWrapper = css`
  margin-bottom: 0;
`;
