import { type KeyMap } from 'helpers/interface';
import { type IPlanPricesResult } from 'interfaces/subscription';

import { BaseApi } from '../base-api';

import {
  type IPreviewSubscriptionRequestBody,
  type IPreviewSubscriptionRequestResult,
  type ISendHostedInvoiceEmailRequestBody,
  type ISubscriptionDTO,
} from './interfaces';

export class SubscriptionApi extends BaseApi {
  protected readonly prefix = 'subscription';
  protected readonly passAppVersionHeader = true;

  fetch = () => this.get<ISubscriptionDTO>();
  create = (params: KeyMap) => this.post('', params);
  update = (params: KeyMap) => this.put('', params);
  updateCoupon = (coupon: string) => this.post('coupon', { coupon });
  sendHostedInvoiceEmail = (params: ISendHostedInvoiceEmailRequestBody) =>
    this.post('hosted_invoice_invitation', params);

  preview = (params: Omit<IPreviewSubscriptionRequestBody, 'appsToRemove' | 'appsToSubscribe'>) =>
    this.post<IPreviewSubscriptionRequestResult>('preview', { ...params });

  fetchPlans = () => this.get<IPlanPricesResult[]>('plans');
}
