import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { isError } from 'services/connectivity/http/type-guards';
import { getWebsocketConnection } from 'services/server/websocket-connection';
import { tryToLogUserSession } from 'services/socket-activity-logger';

import { type ServerAccessPlatform } from './server-access-platform';
import type { ServerConnectOptions } from './types';

const log = debug(DebugLogsNamespace.AppServerConnection);

export function connectToServer(server: ServerAccessPlatform, options: ServerConnectOptions): Promise<void> {
  return new Promise((resolve, reject) => {
    const { isGhost, accessToken } = options;

    const connectHandler = (): void => {
      log('Connected to server');
      server.off('connect', connectHandler);
      resolve();
    };

    if (isGhost) {
      log('Not connecting to platform client (ghost mode)');
      resolve();

      return;
    }

    server.on('connect', connectHandler);

    try {
      log('Connecting to platform client');
      server.connect(accessToken);
    } catch (error: unknown) {
      log('Error connecting to platform client', error);

      if (isError(error) && error.message === 'Socket is already open or connecting.') {
        log('Already connecting');
        resolve();
      } else {
        reject(error);
      }
    }
  });
}

export async function connectAndLogin(options: ServerConnectOptions): Promise<void> {
  const { isGhost } = options;
  const server = getWebsocketConnection() as ServerAccessPlatform;

  log('Connecting to server');
  await connectToServer(server, options);

  if (isGhost) {
    log('Logging in as ghost');
    tryToLogUserSession();

    return;
  }
}
