import { type ISellingExperienceApp } from 'store/entities/applications/interfaces';

import { getConfig } from './config';

export const getInactivityReasonAppId = (): string => getConfig().inactivityReasonAppId;

export const getFBMessengerAppId = (): string => getConfig().facebookMessengerApp;

export const getGoogleAnalyticsAppId = (): string => 'analytics';

export const getABCAppId = (): string => getConfig().appleBusinessChatAppId;

export const getKBAppId = (): string => getConfig().knowledgeBaseAppId;

export const getHelpDeskAppId = (): string => getConfig().helpdeskAppId;

export const getInsightsAppId = (): string => getConfig().insightsAppId;

export const getchatAnalyserAppId = (): string => getConfig().chatsAnalyserAppId;

export const getChatSentimentAppId = (): string => getConfig().chatSentimentAppId;

export const getRtmSentimentWebhooksAppId = (): string => getConfig().rtmSentimentWebhooksAppId;

export const getAutoChatSummaryAppId = (): string => getConfig().autoChatSummaryAppId;

export const getHelpDeskAppNamespace = (): string => getConfig().helpDeskAppClientId;

export const getWhatsAppAppId = (): string => getConfig().whatsappBusinessAppId;

export const getSellingExperienceApps = (): ISellingExperienceApp[] => getConfig().sellingExperienceApps;

export const getSellingExperienceAppsIds = (): string[] => getConfig().sellingExperienceApps.map((app) => app.id);
