import { useCallback, useEffect, useRef, type FC, useState, useMemo } from 'react';

import { cx } from '@emotion/css';
import { SideNavigationGroup, SideNavigationItem, useAnimations } from '@livechat/design-system-react-components';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { Section } from 'constants/section';
import { navigate } from 'helpers/routing';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { isLegacyApplication } from 'store/entities/applications/helpers/legacy-apps';
import { ApplicationWidgetPlacement, type IApplication } from 'store/entities/applications/interfaces';

import { ApplicationListItem } from '../ApplicationListItem/ApplicationListItem';
import { animationOptimizationStyles, itemWrapperStyles } from '../styles';

import { ItemEventMap, YourAppsListSubItem } from './constants';
import { YourAppsItemBadge } from './YourAppsItemBadge';
import { YourAppsItemPin } from './YourAppsItemPin';

import { labelItem, listWrapper, nestedItem, nestedItemAnchor } from './styles';

const SINGLE_ITEM_HEIGHT = 34;

interface Props {
  app: IApplication;
  listPathname: string;
  isVisible?: boolean;
}

export const YourAppsItem: FC<Props> = ({ app, listPathname, isVisible = true }) => {
  const [listHeight, setListHeight] = useState(SINGLE_ITEM_HEIGHT);
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const itemWrapperRef = useRef<HTMLDivElement>(null);
  const {
    id: appId,
    name,
    slug,
    elements,
    alternativeFullscreenWidgetPath,
    interfaceDarkIcons,
    icons,
    isInternalProduct,
  } = app;

  const widgets = Object.values(elements.widgets || {});
  const fullscreenWidget = useMemo(
    () => widgets?.find(({ placement }) => placement === ApplicationWidgetPlacement.FullScreen),
    [widgets],
  );
  const settingsWidget = useMemo(
    () => widgets?.find(({ placement }) => placement === ApplicationWidgetPlacement.Settings),
    [widgets],
  );

  const hasFullscreenWidget = !!fullscreenWidget;
  const hasSettings = !!settingsWidget || isLegacyApplication(appId);
  const displayableWidgetsCount = [hasFullscreenWidget, hasSettings].filter(Boolean).length;
  const hasDisplayableWidgets = displayableWidgetsCount > 0;
  const appIcon = interfaceDarkIcons?.small || icons?.small;

  const overviewLink = `/${Section.AppsOverview}/${slug || appId}`;
  const settingsLink = `/${Section.AppsSettings}/${slug || appId}`;
  const fullscreenAppLink = alternativeFullscreenWidgetPath
    ? `${alternativeFullscreenWidgetPath}/${fullscreenWidget?.id}`
    : `/apps/${slug || fullscreenWidget?.id}`;

  const isOverviewPath = listPathname === overviewLink;
  const isSettingsPath = listPathname === settingsLink;
  const isFullscreenPath = listPathname === fullscreenAppLink;
  const isItemPath = isOverviewPath || isSettingsPath || isFullscreenPath;

  const handleYourAppsListSubItemClick = useCallback(
    (link: string, label: YourAppsListSubItem) => {
      navigate(link);

      trackAppsSectionEvent(ItemEventMap[label], AppsSection.SideNavigation, {
        label,
        applicationId: appId,
        applicationName: name,
      });
    },
    [appId, name],
  );
  const handleApplicationListItemClick = useCallback(() => {
    trackAppsSectionEvent(AppsEvent.YourAppsChevronItemClicked, AppsSection.SideNavigation, {
      applicationId: appId,
      applicationName: name,
    });
  }, [appId, name]);

  /* eslint-disable react-compiler/react-compiler */
  const { isMounted, isOpen, setShouldBeVisible } = useAnimations({
    isVisible: !hasDisplayableWidgets || isItemPath,
    elementRef: listWrapperRef,
  });
  const { isOpen: isItemOpen } = useAnimations({
    isVisible,
    elementRef: itemWrapperRef,
  });
  /* eslint-enable react-compiler/react-compiler */

  useEffect(() => {
    if (!isItemOpen) {
      setListHeight(SINGLE_ITEM_HEIGHT);

      return;
    }

    const updatedListHeight = (displayableWidgetsCount + 1) * SINGLE_ITEM_HEIGHT; // widgets count + group label
    setListHeight(updatedListHeight);
  }, [isItemOpen, displayableWidgetsCount]);

  return (
    <div className={cx(itemWrapperStyles(isItemOpen, listHeight), animationOptimizationStyles)} ref={itemWrapperRef}>
      <SideNavigationGroup
        label={<ApplicationListItem appIcon={appIcon} name={name} onClick={handleApplicationListItemClick} />}
        rightNode={hasFullscreenWidget && <YourAppsItemBadge widgetId={fullscreenWidget.id} />}
        labelWrapperClassName={labelItem(isOverviewPath)}
        labelClassName="your-apps-item-label"
        listWrapperClassName={listWrapper}
        isCollapsible={hasDisplayableWidgets}
        isLinkLabel={!hasDisplayableWidgets}
        isOpen={isOpen}
        isMounted={isMounted}
        isActive={isOverviewPath}
        setShouldBeVisible={setShouldBeVisible}
        listWrapperRef={listWrapperRef}
        onClick={() => handleYourAppsListSubItemClick(overviewLink, YourAppsListSubItem.Overview)}
      >
        {hasFullscreenWidget && (
          <SideNavigationItem
            label={<span className={nestedItemAnchor}>{YourAppsListSubItem.FullscreenApp}</span>}
            rightNode={!isInternalProduct ? <YourAppsItemPin appId={appId} appName={name} /> : null}
            className={nestedItem}
            isActive={isFullscreenPath}
            onClick={() => handleYourAppsListSubItemClick(fullscreenAppLink, YourAppsListSubItem.FullscreenApp)}
          />
        )}
        {hasSettings && (
          <SideNavigationItem
            label={<span className={nestedItemAnchor}>{YourAppsListSubItem.Settings}</span>}
            isActive={isSettingsPath}
            onClick={() => handleYourAppsListSubItemClick(settingsLink, YourAppsListSubItem.Settings)}
          />
        )}
      </SideNavigationGroup>
    </div>
  );
};
