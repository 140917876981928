import { type RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type IFetchPlansResult, type ISubscriptionDataV3 } from './interfaces';

export class BillingApiV3 extends BaseApi {
  protected readonly version = '3';
  

  fetchPlans = () => this.get<IFetchPlansResult>('subscription/livechat/plans');

  fetchActivatedSubscriptions = (): Promise<RequestResult<ISubscriptionDataV3>> => this.get('subscriptions');
}
