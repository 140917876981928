import { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';

import type { UserGuidedTourProperties } from './interfaces';

export const tourProperties: { [key: string]: UserGuidedTourProperties } = {
  [UserGuidedTourType.RichGreeting]: { tourDoneAgentProp: AgentCustomPropertyName.RichGreetingGuidedTourDone },
  [UserGuidedTourType.CustomSegments]: { tourDoneAgentProp: AgentCustomPropertyName.CustomersSegmentsTourDone },
  [UserGuidedTourType.ReportViews]: { tourDoneAgentProp: AgentCustomPropertyName.ReportViewsTourDone },
  [UserGuidedTourType.Home]: { tourDoneAgentProp: AgentCustomPropertyName.HomeTourDone },
};

export const eventTourName: { [key: string]: string } = {
  [UserGuidedTourType.ChatCannedResponses]: 'onboardingChatsCanneds',
  [UserGuidedTourType.TicketPrivacy]: 'onboardingTicketsMessageType',
  [UserGuidedTourType.TicketStatus]: 'onboardingTicketsStatus',
  [UserGuidedTourType.HomeScreenExplore]: 'onboardingHomeScreenExplore',
  [UserGuidedTourType.OpenChat]: 'openChat',
  [UserGuidedTourType.RichGreeting]: 'onboardingRichGreeting',
  [UserGuidedTourType.RoutingRules]: 'onboardingRoutingRules',
  [UserGuidedTourType.Home]: 'Home',
};
