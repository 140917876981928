import { css } from '@emotion/css';
import { DesignToken, SpacingToken, TransitionDurationToken } from '@livechat/design-system-react-components';

export const labelStyles = css`
  padding-inline: 0;
`;

export const labelWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(${DesignToken.ContentLockedWhite});
  padding: var(${SpacingToken.Spacing1}) var(${SpacingToken.Spacing3});
`;

export const itemWrapperStyles = (isOpen: boolean, listHeight: number): string => css`
  transition:
    opacity var(${TransitionDurationToken.Moderate1}) ease-in-out,
    max-height var(${TransitionDurationToken.Moderate1}) ease-in-out;
  opacity: ${isOpen ? 1 : 0};
  max-height: ${isOpen ? listHeight : 0}px;
`;

export const showAppsChevronStyles = (areAllAppsVisible: boolean): string => css`
  transition: all var(${TransitionDurationToken.Fast2}) ease-in-out;
  ${areAllAppsVisible && 'transform: rotate(-90deg)'};
`;

// Applied to items in lists to prevent layout shifting by 1 pixel
export const animationOptimizationStyles = css`
  will-change: opacity;
`;
