import { type ReactNode } from 'react';

import { Button } from '@livechat/design-system-react-components';

import type { BubbleStepContent, StepContent } from '../interfaces';

import { CHATS_MP4, ENGAGE_MP4, AUTOMATE_MP4 } from './constants';

export const BubbleStep: BubbleStepContent = {
  headerMessage: `Hi, welcome to your free trial!`,
  message:
    'Ready to explore? Take a quick product tour to see how LiveChat can boost your support and sales in no time.',
  cta: (handleNextStep: () => void): ReactNode => (
    <Button kind="high-contrast" size="large" onClick={handleNextStep}>
      Let&apos;s dive in
    </Button>
  ),
};

export const ChatsStep: StepContent = {
  header: 'Step into the Chats section',
  text: 'This is your main workspace: see all your conversations, chat in real time, and supervise agents and chatbots.',
  video: {
    src: CHATS_MP4,
    autoPlay: true,
    loop: true,
  },
};

export const EngageStep: StepContent = {
  header: 'Engage your website visitors',
  text: 'Monitor your website traffic, reach out to customers browsing your site, or set up automatic campaigns to invite them to chat.',
  video: {
    src: ENGAGE_MP4,
    autoPlay: true,
    loop: true,
  },
};

export const AutomateStep: StepContent = {
  header: 'Automate and supercharge your work',
  text: 'Set up a chatbot to handle chats for you, get reply suggestions based on your knowledge sources, automate tagging, and more!',
  video: {
    src: AUTOMATE_MP4,
    autoPlay: true,
    loop: true,
  },
};

export const SettingsStep: StepContent = {
  header: 'Fine-tune your settings',
  text: "Style the chat widget your customers see on your website to match your brand's look and feel.",
};

export const SpotlightStep: StepContent = {
  header: 'Search bar: your command center',
  text: 'Access everything you need—navigate LiveChat, ask Copilot, search Archives, and perform actions without missing a beat.',
};

export const CopilotStep: StepContent = {
  header: "Got questions? I've got answers!",
  text: "I'm Copilot, your AI assistant, and I'm always here to help with setup, metrics or your own business.",
};
