import { type IKeyValue } from 'helpers/url';
import type { RequestResult } from 'interfaces/api/client';
import { type IGroupProperties } from 'interfaces/entities/group-property';
import { type GroupId } from 'interfaces/groups';
import type { GroupDTO, SaveGroupLogoResponse } from 'services/api/group/interfaces';

import { BaseApi } from '../base-api';

export class GroupApi extends BaseApi {
  protected readonly passVersionInPrefix = false;

  fetchGroupsProperties = (params: IKeyValue): Promise<RequestResult<Pick<GroupDTO, 'id' | 'properties'>[]>> =>
    this.get('v2/properties/groups', params);

  fetchGroupProperties = (groupId: GroupId): Promise<RequestResult<IGroupProperties>> =>
    this.get(`v2/properties/group/${groupId}`);

  saveGroupProperties = (groupId: GroupId, params: IKeyValue): Promise<RequestResult<Partial<IGroupProperties>>> =>
    this.put(`v2/properties/group/${groupId}`, params);

  saveGroupLogo = (groupId: GroupId, url: string): Promise<RequestResult<SaveGroupLogoResponse>> =>
    this.put(`v2/logos/${groupId}`, { url });
}
