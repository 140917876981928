export const ButtonStep = {
  header: 'Work more efficiently with segments',
  text: 'Do you often use the same filters? Use segments instead. Segments allow you to automatically group customers and focus on those that are most important to your business.',
};

export const CustomSegmentsStep = {
  header: 'Easy access to target groups',
  text: 'Customer segments are available at the top of the list. Quickly see your key groups of customers.',
};

export const ManageSegmentsStep = {
  image: {
    src: 'https://cdn.livechat-static.com/api/file/lc/img/engage/onboarding/manage-segments.png',
    alt: 'Manage segments',
    width: 300,
  },
  header: 'Quickly adjust the segment list',
  text: 'Make sure segments are displayed in an efficient way for you. Change segment order and edit names or delete those that are no longer needed.',
};
