import { css } from '@emotion/css';
import { DesignToken, SpacingToken } from '@livechat/design-system-react-components';

import { mobileMediaQuery } from 'helpers/media-queries';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const logo = css`
  width: 120px;
  height: 30px;
`;

export const backButton = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing1});
  font-weight: 600;
`;

export const actionRow = css`
  display: flex;
  flex-direction: row;
  gap: var(${SpacingToken.Spacing2});
  margin-top: var(${SpacingToken.Spacing7});
`;

export const iconNode = css`
  svg {
    width: 64px;
    height: 64px;
    path {
      color: var(${DesignToken.ContentBasicDisabled});
    }
  }
`;

export const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(${SpacingToken.Spacing5}) var(${SpacingToken.Spacing20});
  border-bottom: 1px solid var(${DesignToken.BorderBasicPrimary});

  ${mobileMediaQuery} {
    padding: var(${SpacingToken.Spacing5});
  }
`;

export const heading = css`
  font-weight: 600;
`;

export const childrenWrapper = css`
  color: var(${DesignToken.ContentBasicPrimary});
`;

export const content = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(${SpacingToken.Spacing0}) var(${SpacingToken.Spacing5});
  gap: var(${SpacingToken.Spacing3});
  justify-content: center;

  > [class^='lc-'] {
    box-sizing: border-box;
  }
`;
