import {
  ShortcutCommand,
  ShortcutControl,
  ShortcutDelete,
  ShortcutOption,
  ShortcutShift,
} from '@livechat/design-system-icons';

import { type ShortcutItem } from 'components/shortcuts/interfaces';

import { isWindows, isMacOS } from '../browser';

export function getAltKeyCode(): string {
  return isWindows() ? 'alt' : '⌥';
}

export function getControlKeyCode(): string {
  return isWindows() ? 'ctrl' : '⌃';
}

export function getModKey(capitalize = false): string {
  if (isMacOS()) {
    return '⌘';
  }

  return `${capitalize ? 'C' : 'c'}trl`;
}

export function getAltKeyIcon(): ShortcutItem {
  return isWindows() ? { text: 'Alt' } : { icon: ShortcutOption };
}

export function getControlKeyIcon(): ShortcutItem {
  return isWindows() ? { text: 'Ctrl' } : { icon: ShortcutControl };
}

export function getModKeyIcon(): ShortcutItem {
  return isWindows() ? { text: 'Ctrl' } : { icon: ShortcutCommand };
}

export function getShiftKeyIcon(): ShortcutItem {
  return isWindows() ? { text: 'shift' } : { icon: ShortcutShift };
}

export function getDeleteKeyIcon(): ShortcutItem {
  return isWindows() ? { text: 'Backspace' } : { icon: ShortcutDelete };
}
