import type { RequestResult } from 'interfaces/api/client';

import { BaseApi } from '../base-api';

import { type FormId, type IFormPayload } from './interfaces';

export class FormsApi extends BaseApi {
  protected readonly version = '2';
  protected readonly prefix = 'forms';
  
  protected readonly passAppVersionHeader = true;

  getFinalPath = (id: FormId, groupId?: string): string => {
    return groupId ? `${id}/${groupId}` : id;
  };

  fetch = (id: FormId, groupId?: string): Promise<RequestResult<IFormPayload>> => {
    return this.get(this.getFinalPath(id, groupId));
  };

  update = (id: FormId, form: IFormPayload, groupId?: string): Promise<RequestResult<IFormPayload>> => {
    return this.put(this.getFinalPath(id, groupId), form);
  };
}
