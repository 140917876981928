import { BaseApi } from '../base-api';

import type { IInitChatBotParams } from './interfaces';

export class ChatbotInLivechatApi extends BaseApi {
  protected readonly prefix = 'api';
  protected readonly passVersionInPrefix = false;

  initChatbot = (payload: IInitChatBotParams) => this.post('crawl', payload);
}
