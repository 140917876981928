import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { canEasilyInstallCode } from 'helpers/device';
import { isGhostLogin } from 'services/auth/auth-storage-manager';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { getIsAutomatedCodeInstallationSupported } from 'store/entities/applications/selectors';
import {
  hasFetchedMetrics,
  isCodeInstalledAutomaticallyForLicenseSource,
} from 'store/features/agent-custom-properties/selectors';
import { getIsCodeInstalled } from 'store/features/code-installation/selectors';
import { isAtLeastAdmin } from 'store/features/session/selectors';
import { hasVisitedInstallCode } from 'store/views/install-code/selectors';
import { getShouldShowAppOnboarding } from 'store/views/onboarding/computed';
import {
  getIsNetworkIssuesVisible,
  getIsUnexpectedStartupErrorVisible,
  getSignInError,
} from 'store/views/startup-error/selectors';

interface IRouteOverride {
  shouldShowOnboarding: boolean;
  shouldShowInstallCode: boolean;
  hasAccessToAppRoutes: boolean;
  shouldShowStartupError: boolean;
  shouldShowNetworkIssues: boolean;
  shouldShowDesktopLogin: boolean;
}

export function useRouteOverride(): IRouteOverride {
  const location = useLocation();
  const loggedInAgent = useSelector(getLoggedInAgent);
  const isCodeInstalled = useSelector(getIsCodeInstalled);
  const hasAccessToCodeInstallation = useSelector(isAtLeastAdmin);
  const isCodeInstalledByOtherSource = useSelector(isCodeInstalledAutomaticallyForLicenseSource);
  const fetchedMetrics = useSelector(hasFetchedMetrics);
  const alreadyVisitedInstallCode = useSelector(hasVisitedInstallCode);
  const isInErrorMode = !!useSelector(getSignInError);
  const shouldShowDesktopLogin = location.pathname.includes('/app-signin');
  const isGhost = isGhostLogin();
  const notInstalledByIntegration = !isCodeInstalledByOtherSource && fetchedMetrics;
  const canPerformCodeInstallation = canEasilyInstallCode();

  const shouldShowStartupError = useSelector(getIsUnexpectedStartupErrorVisible);
  const shouldShowAppOnboarding = useSelector(getShouldShowAppOnboarding);

  const hasAccessToAppRoutes = !isInErrorMode && !!loggedInAgent && !shouldShowStartupError;
  const shouldShowOnboarding = hasAccessToAppRoutes && shouldShowAppOnboarding && !isGhost;
  const isAutomatedCodeInstallationSupported = useSelector(getIsAutomatedCodeInstallationSupported);
  const shouldShowInstallCode =
    !alreadyVisitedInstallCode &&
    !isGhost &&
    canPerformCodeInstallation &&
    hasAccessToCodeInstallation &&
    !isCodeInstalled &&
    notInstalledByIntegration &&
    !shouldShowOnboarding &&
    !location.pathname.includes('/subscription') &&
    !shouldShowDesktopLogin &&
    !isAutomatedCodeInstallationSupported;

  const shouldShowNetworkIssues = useSelector(getIsNetworkIssuesVisible);

  return {
    hasAccessToAppRoutes,
    shouldShowOnboarding,
    shouldShowInstallCode,
    shouldShowStartupError,
    shouldShowNetworkIssues,
    shouldShowDesktopLogin,
  };
}
