export enum ChatOperatorEvent {
  SuperviseStarted = 'Supervise started',
}

export const enum IncomingMulticastType {
  Lc2 = 'lc2',
  Lc2Iwcs = 'lc2_iwcs',
}

export const enum IncomingMulticastIwcsCommand {
  AgentStatusUpdate = 'IWCS0014R',
}

export const enum IncomingMulticastEventName {
  AccountBlocked = 'account_blocked',
  BanListUpdate = 'ban_list_update',
  CannedResponseAdd = 'canned_response_add',
  CannedResponseRemove = 'canned_response_remove',
  CannedResponseUpdate = 'canned_response_update',
  IntegrationDisable = 'integration_disable',
  IntegrationEnable = 'integration_enable',
  LicensePropertiesUpdate = 'license_properties_update',
  TeamChatIncomingMessage = 'team_chat_incoming_message',
  TicketSummaryUpdate = 'ticket_summary_update',
  TicketUpdate = 'ticket_update',
}

export const enum ThreadCustomPropertyName {
  PriorityChat = 'priority_chat',
  PriorityChatAuthorId = 'priority_chat_author_id',
}
