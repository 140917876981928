import type { Properties } from 'interfaces/property';
import type { RequestFailure } from 'store/entities/interfaces';

export interface IIntegrationLicenseProperties extends Properties {}
export interface IFetchLicensePropertiesSuccessPayload extends Properties {}
export interface IUpdateLicensePropertiesDataPayload extends Properties {}
export interface IFetchLicensePropertiesFailurePayload extends RequestFailure {}

export interface IFetchForNamespacePayload {
  namespace: string;
}

export interface IIntegrationLicensePropertiesState {
  entities: {
    integrationLicenseProperties: IIntegrationLicenseProperties;
  };
}

export interface IUpdateLicensePropertiesPayload {
  properties: Properties;
}

export enum IntegrationLicensePropertiesNames {
  MessagingInQueueEnabled = 'messaging_in_queue_enabled',
  AvailabilityPromoEnabled = 'availability_promo_enabled',
  ChatTakeOverDisabled = 'chat_take_over_disabled',
  TextEnhancementsStatus = 'text_enhancements_status',
  DarkModeSwitchEnabled = 'dark_mode_switch_enabled',
  ChatSummaryDisabled = 'chat_summary_disabled',
  CompressWebsocket = 'compress_websocket',
  ReplySuggestionsStatus = 'knowledge_suggestions_status',
  ReplySuggestionsVersion = 'knowledge_suggestions_version',
  OneChatStatus = 'live_assistant_chat',
  ReportTargets = 'report_targets',
  CustomerIntentEnabled = 'customer_intent_enabled',
  OnboardingIntegrations = 'onboarding_integrations',
  CampaignEcommerceAgentTrainer = 'campaign_ecommerce_agent_trainer',
  CampaignEcommerceBothAppLicenses = 'campaign_ecommerce_both_app_license',
  CampaignEcommerceWabLicenses = 'campaign_ecommerce_wab_licenses',
  CampaignEcommerceMessengerLicenses = 'campaign_ecommerce_messenger_licenses',
  CampaignEcommerceEmpLicenses = 'campaign_ecommerce_emp_licenses',
  CampaignEcommerceCrmLicenses = 'campaign_ecommerce_crm_licenses',
}

export interface IExternalPaymentProvider {
  name?: string;
  url?: string;
  widgetId?: string;
}
