import { type FC } from 'react';

import { Close } from '@livechat/design-system-icons';
import { Avatar, Button, Icon } from '@livechat/design-system-react-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import logo from 'assets/img/teamchat/teamchat-logo.svg';

import { type TeamChatNotification } from '../../../_hooks/use-teamchat-notifications';

import * as styles from './styles';

const ANIMATION_TIMEOUT_MS = 200;

interface TeamChatNotificationsListProps {
  notifications: TeamChatNotification[];
  onNotificationClick: (notification: TeamChatNotification) => void;
  onClose: (e: React.MouseEvent) => void;
}

export const TeamChatNotificationsList: FC<TeamChatNotificationsListProps> = ({
  notifications,
  onNotificationClick,
  onClose,
}) => {
  return (
    <TransitionGroup component={null}>
      {notifications.map((notification, index) => (
        <CSSTransition
          key={notification.id}
          classNames={{
            enter: styles.notificationAppearSlide,
            enterActive: styles.notificationAppearActiveSlide,
            exit: styles.notificationExitSlide,
            exitActive: styles.notificationExitActiveSlide,
          }}
          timeout={ANIMATION_TIMEOUT_MS}
        >
          {(() => {
            const isStackedNotification = index < notifications.length - 1;

            return (
              <button
                type="button"
                className={`${styles.notification} ${isStackedNotification ? styles.stackedNotification : ''}`}
                onClick={() => onNotificationClick(notification)}
                aria-label={`TeamChat notification from ${notification.authorName}: ${notification.message}`}
              >
                <div className={styles.avatar}>
                  <Avatar
                    type={notification.authorAvatar ? 'image' : 'text'}
                    src={`${notification.authorAvatar}?impolicy=thumbnail `}
                    text={notification.authorName}
                    size="large"
                  />

                  <img src={logo} alt="TeamChat logo" className={styles.avatarLogo} />
                </div>

                <div className={styles.content}>
                  <div className={styles.header}>
                    <div className={styles.headerAuthor}>{notification.authorName}</div>
                    <div className={styles.headerTime}>{notification.time}</div>
                  </div>

                  <div className={styles.message}>{notification.message}</div>
                </div>

                <div className="close-button-wrapper">
                  <Button
                    aria-label="Close notification"
                    kind="plain"
                    icon={<Icon source={Close} size="medium" />}
                    onClick={onClose}
                  />
                </div>
              </button>
            );
          })()}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
