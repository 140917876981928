// @ts-strict-ignore
import type { IKeyAny, IKeyValue } from 'helpers/url';
import type { RequestResult } from 'interfaces/api/client';
import type { ApiVersionPrefix } from 'services/api/interfaces/api-version-prefix';

import type { ApiClient, UploadEvents } from './types';

export abstract class BaseApi {
  protected readonly api: ApiClient;
  protected readonly prefix: string;
  protected readonly version: ApiVersionPrefix = '2';
  protected readonly passVersionInPrefix: boolean = true;
  protected readonly passAppVersionHeader: boolean = false;

  constructor(api: ApiClient) {
    this.api = api;
  }

  private get headers(): IKeyValue {
    if (!this.passAppVersionHeader) {
      return null;
    }

    return {
      'x-application': `WebApp ${BUILD_INFO_TIMESTAMP} ${COMMIT_HASH}`,
    };
  }

  private combineHeaders(headers: IKeyValue): IKeyValue {
    if (!this.headers && !headers) {
      return null;
    }

    return { ...this.headers, ...headers };
  }

  private buildVersionPrefix(versionOverride?: ApiVersionPrefix): string {
    if (!this.passVersionInPrefix) {
      return '';
    }

    return `v${versionOverride ?? this.version}`;
  }

  private getPath(path: string, prefixOverride?: string, versionOverride?: ApiVersionPrefix): string {
    const versionPrefix = this.buildVersionPrefix(versionOverride);
    const prefix = prefixOverride || this.prefix || '';

    return [versionPrefix, prefix, path].filter(Boolean).join('/');
  }

  protected get<TResult>(
    path = '',
    params?: IKeyValue,
    headers?: IKeyValue,
    prefixOverride?: string,
    versionOverride?: ApiVersionPrefix,
  ): Promise<RequestResult<TResult, unknown>> {
    const combinedPath = this.getPath(path, prefixOverride, versionOverride);
    const combinedHeaders = this.combineHeaders(headers);

    if (!params && !combinedHeaders) {
      return this.api.get(combinedPath);
    }

    return !combinedHeaders ? this.api.get(combinedPath, params) : this.api.get(combinedPath, params, combinedHeaders);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected post<TResult, TError = any>(
    path = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string,
    versionOverride?: ApiVersionPrefix,
  ): Promise<RequestResult<TResult, TError>> {
    const combinedPath = this.getPath(path, prefixOverride, versionOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];

    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }

    return this.api.post.call(this.api, ...args) as Promise<RequestResult<TResult, TError>>;
  }

  protected delete<TResult>(
    path = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string,
    versionOverride?: ApiVersionPrefix,
  ): Promise<RequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride, versionOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }

    return this.api.delete.call(this.api, ...args) as Promise<RequestResult<TResult>>;
  }

  protected put<TResult>(
    path = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string,
    versionOverride?: ApiVersionPrefix,
  ): Promise<RequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride, versionOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }

    return this.api.put.call(this.api, ...args) as Promise<RequestResult<TResult>>;
  }

  protected patch<TResult>(
    path = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string,
    versionOverride?: ApiVersionPrefix,
  ): Promise<RequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride, versionOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }

    return this.api.patch.call(this.api, ...args) as Promise<RequestResult<TResult>>;
  }

  protected upload<TResult>(
    path = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    options?: UploadEvents,
    prefixOverride?: string,
  ): Promise<RequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders, options];
    } else {
      args = [...args, options];
    }

    return this.api.upload.call(this.api, ...args) as Promise<RequestResult<TResult>>;
  }
}
