import { BaseApi } from '../base-api';

import type { ICreateChatbotParams } from './interfaces';

export class ChatbotApi extends BaseApi {
  protected readonly prefix = 'livechat';
  protected readonly passVersionInPrefix = false;

  fetchConnectedBots = () => this.get();
  createChatbot = (payload: ICreateChatbotParams) => this.post('create', payload);
}
