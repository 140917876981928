import { BaseApi } from '../../base-api';
import { type IFetchNewArchivesCountParams } from '../interfaces';

import { mapFetchNewArchivesCountParamsToBody } from './helpers';
import { type IFetchNewArchivesCountResult, type IFetchNewArchivesCountBody } from './interfaces';

export default class ArchiveNotifierApi extends BaseApi {
  protected readonly prefix = 'chats';
  protected readonly passAppVersionHeader = true;

  fetchNewArchivesCount = <TResult = IFetchNewArchivesCountResult>(
    sinceTimestamp: number,
    params?: IFetchNewArchivesCountParams
  ) => {
    if (!params) {
      return this.get<TResult>(`new/${sinceTimestamp}`);
    }

    const body: IFetchNewArchivesCountBody = mapFetchNewArchivesCountParamsToBody(params);

    return this.get<TResult>(`new/${sinceTimestamp}`, { ...body });
  };
}
