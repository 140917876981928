// @ts-strict-ignore
import '@livechat/design-system-react-components/dist/style.css';
import * as Sentry from '@sentry/browser';

import { App } from 'config/setup';
import { EventNames, type Events } from 'constants/event-bus-events';
import { GENERAL_GROUP_ID } from 'constants/groups';
import { type LanguageCode } from 'constants/languages-available';
import { type IApplicationCore } from 'entries/base/interfaces/application-core';
import { type InitialData } from 'entries/base/interfaces/initial-data';
import { setDataLayerCustomDimensions, trackGTMEvent } from 'helpers/analytics';
import { anyToBoolean } from 'helpers/boolean';
import { getConfig } from 'helpers/config';
import { setHighchartTheme } from 'helpers/dark-mode';
import { isDesktopAppDetected } from 'helpers/desktop-app/is-detected';
import { isDevelopmentEnvironment } from 'helpers/feature-toggle';
import { redirectTo } from 'helpers/redirect-to';
import { extractRedirectTo, redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import { navigate } from 'helpers/routing';
import { getAppRoot, getHashParam, getUrlParam } from 'helpers/url';
import { SignoutReason, type ISession } from 'interfaces/session';
import { getLastActivityInDays, isWebsiteActivityStored } from 'services/activity/website-last-activity';
import { AmplitudeUserProperty, setUserIdForAmplitude, setUserPropertiesForAmplitude } from 'services/amplitude';
import { getAccessToken, loadCredentialsFromCookies, storeCredentials } from 'services/auth/auth-storage-manager';
import ChatWidget from 'services/chat-widget';
import { consoleLogHistoryManager } from 'services/console-log-history-manager';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { loadDevTools } from 'services/dev-tools/load-dev-tools';
import { EventBus } from 'services/event-bus';
import { initializeQueryClient } from 'services/query-client/initialize';
import { isMainLayoutRendered, renderMainLayout } from 'services/render-main-layout';
import { saveLog } from 'services/save-log';
import { sentryContext } from 'services/sentry/context';
import { connectAndLogin } from 'services/server/connect-and-login';
import { logout as logoutFromServer } from 'services/server/logout';
import { reinitializeApp } from 'services/server/reinitialize-app';
import { endSession, removeAllSessions, removeSession } from 'services/session';
import { clearSocketActivity } from 'services/socket-activity-logger';
import { initializeApp } from 'services/startup/initialize/initialize-app';
import { initializeExternalApi } from 'services/startup/initialize/initialize-external-api';
import { initializeServer } from 'services/startup/initialize/initialize-server';
import { initializeTrackingTools } from 'services/startup/initialize/initialize-tracking-tools';
import {
  getAgentPermission,
  getAgents,
  getLoggedInAgent,
  getLoggedInAgentLogin,
} from 'store/entities/agents/selectors';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { ExperimentsActions } from 'store/entities/experiments/actions';
import { getGroupLanguageCode } from 'store/entities/groups/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { getChatsListSentimentVisible, getCurrentTheme } from 'store/features/agent-custom-properties/selectors';
import { SessionActions } from 'store/features/session/actions';
import { getLicenseId, getPlanType, getSubscriptionType } from 'store/features/session/selectors';
import { runAppSagas } from 'store/sagas/app-sagas';
import { store } from 'store/store';
import { SettingsViewActions } from 'store/views/settings/actions';
import { LanguageViewActions } from 'store/views/settings/language/actions';
import { TicketsViewActions } from 'store/views/tickets/actions';
import { injectGlobalStyles } from 'styles/global-styles';

import './polyfills/from-entries';
import './polyfills/structured-clone';

consoleLogHistoryManager.startCapture();

/* eslint-disable @typescript-eslint/no-require-imports */
// #if process.env.AA_MOCKED === 'true'
// require('fake-api/src/mock-hook');
// #endif
/* eslint-enable @typescript-eslint/no-require-imports */

runAppSagas();
injectGlobalStyles();

// track google analytics page views with 2-sec intervals
// - we can't easily do it because
//   some URLs should not be tracked (they are used as redirection)
//
//   For example:
//     @navigate '/agents'           <--- this would be tracked in Router
//     @navigate '/agents/john@....' <--- this would be tracked in Router
//
//   We don't want both URLs to be tracked, just the last one
App.initGoogleAnalytics = function initGoogleAnalytics(this: IApplicationCore): void {
  this.lastGAUrl = null;
  setInterval(() => {
    if (!isMainLayoutRendered()) {
      return;
    }

    let url = window.location.pathname.replace(/^\//, '');

    // don't track too many similar URLs
    // such as /archives/<ID> or /chats/<ID>
    url = url.replace(/^archives\/.*/, 'archives');
    url = url.replace(/^chats\/.*/, 'chats');

    if (url === this.lastGAUrl) {
      return;
    }

    this.lastGAUrl = url;

    // if app models are inited, we can store some information
    // in Google Analytics dimensions
    if (this.INITED) {
      const state = store.getState();

      // data may not be available due to unsuccessful startup
      const lastActivityInDays = isWebsiteActivityStored() ? getLastActivityInDays() : undefined;
      const licenseId = getLicenseId(state);
      const licenseType = getSubscriptionType(state);

      window.ga('set', {
        dimension1: licenseId,
        dimension2: licenseType,
        dimension3: getPlanType(state),
        dimension4: lastActivityInDays < 3 ? '1' : '0',
      });
      window.ga('global.set', {
        dimension1: licenseId,
        dimension2: licenseType,
        dimension3: getPlanType(state),
        dimension4: lastActivityInDays < 3 ? '1' : '0',
      });
    }

    let ua = navigator.userAgent.toString();

    const desktopApp = getDesktopApp();

    if (isDesktopAppDetected()) {
      // put "LiveChatSmartClient" at the beginning of the string to make sure
      // it won't be truncated when storing in Google Analytics
      const scVersion = desktopApp.getVersion();
      ua = ua.replace(/LiveChatSmartClient(\/[0-9.]+)?/g, '');
      if (scVersion !== null) {
        ua = `LiveChatSmartClient/${scVersion} ${ua}`;
      } else {
        ua = `LiveChatSmartClient ${ua}`;
      }
    }

    // customvar's name & key combined must not exceed 128 chars
    ua = ua.substring(0, 126);
    window.ga('set', 'dimension5', ua);
    window.ga('send', 'pageview', `/${url}`);

    window.ga('global.set', 'dimension5', ua);
    window.ga('global.send', 'pageview', `/${url}`);
  }, 2000);
};

App.setupGTM = function setupGTM(this: IApplicationCore): void {
  this.lastGTMUrl = null;
  setInterval(() => {
    if (!isMainLayoutRendered()) {
      return;
    }

    const url = window.location.pathname.replace(/^\//, '');

    if (url === this.lastGTMUrl) {
      return;
    }

    this.lastGTMUrl = url;

    if (this.INITED && window.dataLayer != null) {
      const state = store.getState();
      const lastActivityInDays = isWebsiteActivityStored() ? getLastActivityInDays() : undefined;

      const desktopApp = getDesktopApp();
      let ua = navigator.userAgent.toString();
      if (isDesktopAppDetected()) {
        const scVersion = desktopApp.getVersion();
        ua = ua.replace(/LiveChatSmartClient(\/[0-9.]+)?/g, '');
        if (scVersion !== null) {
          ua = `LiveChatSmartClient/${scVersion} ${ua}`;
        } else {
          ua = `LiveChatSmartClient ${ua}`;
        }
      }
      // customvar's name & key combined must not exceed 128 chars
      ua = ua.substring(0, 126);

      const licenseId = getLicenseId(state);
      const licenseType = getSubscriptionType(state);
      const role = getAgentPermission(state, getLoggedInAgentLogin(state));

      setDataLayerCustomDimensions({
        license: licenseId,
        licenseType,
        agentRole: role,
        salesPlan: getPlanType(state),
        codeInstalled: lastActivityInDays > 0 ? '1' : '0',
        ua,
      });

      trackGTMEvent('VirtualPageview', {
        virtualPageURL: `/${url}`,
        virtualPageTitle: document.title,
      });
    }
  }, 2000);
};

App.setupAmplitudeUserProperties = function setupAmplitudeUserProperties(): void {
  const state = store.getState();
  const myProfile = getLoggedInAgent(state);
  const currentTheme = getCurrentTheme(state);
  const isChatListSentimentVisible = getChatsListSentimentVisible(state);
  const licenseId = getLicenseId(state);
  const licenseType = getSubscriptionType(state);
  const plan = getPlanType(state);

  if (!myProfile) {
    return;
  }

  setUserIdForAmplitude(myProfile.login);

  setUserPropertiesForAmplitude({
    [AmplitudeUserProperty.AccountType]: licenseType,
    [AmplitudeUserProperty.License]: licenseId,
    [AmplitudeUserProperty.Permission]: myProfile.permission,
    [AmplitudeUserProperty.Plan]: plan,
    [AmplitudeUserProperty.Sentiment]: isChatListSentimentVisible,
    [AmplitudeUserProperty.Theme]: currentTheme,
  });
};

App.init = function init(this: IApplicationCore): void {
  if (this.INITED) {
    return;
  }

  this.INITED = true;

  const state = store.getState();
  const currentAgentLogin = getLoggedInAgentLogin(state);
  const licenseId = getLicenseId(state)?.toString();

  if (!currentAgentLogin || !licenseId) {
    Sentry.captureException(
      new Error(`Empty data in app init. Agent login: ${currentAgentLogin}, license id: ${licenseId}.`),
    );
  }

  sentryContext.setContext({
    login: currentAgentLogin,
    license: licenseId,
    plan: getPlanType(state),
  });

  ChatWidget.updateCustomerDetails();

  store.dispatch(AgentCustomPropertiesActions.fetchMertics());

  const groupId = getUrlParam('group') || GENERAL_GROUP_ID;
  const language = getGroupLanguageCode(store.getState(), groupId);

  store.dispatch(SettingsViewActions.setSelectedGroupId(groupId || GENERAL_GROUP_ID));
  store.dispatch(LanguageViewActions.setCurrentLanguageCode({ languageCode: language as LanguageCode }));
  store.dispatch(ApplicationsActions.fetchCollection({}));
  store.dispatch(TicketsViewActions.fetchTicketCounters());

  initializeQueryClient();
  setHighchartTheme();

  renderMainLayout();

  store.dispatch({ type: 'APP_READY' });

  if (isDevelopmentEnvironment()) {
    /**
     * The 'load' event is sometimes not detected by Playwright or Percy. This causes tests to hang indefinitely until test timeout is reached.
     * Fire event maunally to avoid this issue.
     */
    dispatchEvent(new Event('load'));
  }
};

App.loginWithCookies = async function loginWithCookies(this: IApplicationCore): Promise<boolean> {
  loadCredentialsFromCookies();

  const accessToken = getAccessToken();
  if (accessToken) {
    const isGhost = false;
    await this.loginWithAccessToken(accessToken, isGhost, true);

    return true;
  }

  return false;
};

App.visitInfoPage = function visitInfoPage(url: string): void {
  const targetUrl = url.endsWith('/') ? url.substring(-1) : url;
  navigate(targetUrl);
};

App.destroy = async function destroy(
  this: IApplicationCore,
  options: Partial<{
    remote: boolean;
    licenseBlocked: boolean;
    manual: boolean;
    currentOnly: boolean;
    sessionsList: ISession[];
    currentSession: { sessionId: string };
  }> = {},
): Promise<void> {
  clearSocketActivity();

  const getSignoutReason = (): SignoutReason | null => {
    if (options.remote) {
      return SignoutReason.SignedOutRemotely;
    }
    if (options.licenseBlocked) {
      return SignoutReason.LicenseBlocked;
    }

    return null;
  };

  saveLog('info', `App reset! State: ${getSignoutReason()}`);

  const redirectOutUnexpectedly = (): void => {
    redirectToAccountsSignout(getSignoutReason());
  };

  const redirectOutNormally = (): void => {
    endSession();
    const rootUrl = getAppRoot();
    const rootPath = rootUrl.startsWith('/') ? rootUrl : `/${rootUrl}`;
    redirectTo(`${rootPath}keep-on-chatting-with-customers`);
  };

  if (options.manual) {
    this.manualLogout = true;

    await logoutFromServer();
    if (options.currentOnly && options.sessionsList) {
      if (!options.currentSession) {
        redirectOutNormally();
      } else {
        try {
          await removeSession(options.currentSession.sessionId);
          redirectOutNormally();
        } catch {
          redirectOutUnexpectedly();
        }
      }
    } else {
      try {
        await removeAllSessions();
        redirectOutNormally();
      } catch {
        redirectOutUnexpectedly();
      }
    }
  }
  // redirect to signout only if user is not logging out manually
  // - in that case skip it because other redirection is already in place
  if (!this.manualLogout) {
    redirectOutUnexpectedly();
  }
};

App.loginWithAccessToken = async function loginWithAccessToken(
  this: IApplicationCore,
  accessToken: string,
  isGhost: boolean,
  reinitializeAll: boolean,
): Promise<void> {
  store.dispatch(SessionActions.updateRights());
  store.dispatch(SessionActions.saveGhostStatus({ isGhost }));
  store.dispatch(ExperimentsActions.fetchExperiments());

  this.init();

  if (reinitializeAll) {
    await reinitializeApp({ accessToken, isGhost });
  } else {
    await connectAndLogin({ accessToken, isGhost });
  }
};

App.logout = function logout(this: IApplicationCore): void {
  void logoutFromServer();
};

App.start = function start(initialData: InitialData): void {
  const { accessToken, scopes, isGhost, expiresIn, startupData } = initialData;

  storeCredentials({ token: accessToken, scopesString: scopes.join(','), isGhost, expiresIn });

  const redirectToUrl = extractRedirectTo(getHashParam('state'));
  if (redirectToUrl) {
    redirectTo(`${window.location.protocol}//${window.location.host}${redirectToUrl}`);

    return;
  }

  void loadDevTools();

  const compressWebsocket = anyToBoolean(
    startupData.licenseProperties[getConfig().accountsClientId]?.compress_websocket,
  );
  initializeServer({ compressWebsocket });
  initializeApp(startupData);
  initializeExternalApi();

  const email = startupData.account.email;

  if (!email) {
    Sentry.captureException(new Error('Empty email value in account data.'));
  }

  const state = store.getState();
  const login = getLoggedInAgentLogin(state);
  const agent = getLoggedInAgent(state);

  if (!agent) {
    const agents = getAgents(state);
    Sentry.captureException(
      new Error(
        `Agent not found for login ${login}. Agents (${agents.length}): ${agents
          .map((agent) => agent.login)
          .slice(0, 5)
          .join(', ')}`,
      ),
    );
  }

  initializeTrackingTools();

  void App.loginWithAccessToken(accessToken, isGhost, false);

  EventBus.on(EventNames.UserLoggedOut, (options: Events[EventNames.UserLoggedOut] = {}) => {
    void App.destroy({ remote: !options.manual, ...options });
  });
};
