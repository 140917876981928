import { useEffect, useState, type FC } from 'react';

import { cx } from '@emotion/css';
import { useAppFrame } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { Section } from 'constants/section';
import { EventPlace } from 'helpers/analytics';
import { anyToBoolean, type BooleanNumericFlag } from 'helpers/boolean';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { useChangeCurrentAgentStatus } from 'hooks/api/agents/use-change-current-agent-status';
import { useLicenseProperty } from 'hooks/connectivity/configuration-api/properties/use-license-property';
import { CoreProperty, PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { trackEvent } from 'services/event-tracking';
import { getSubscriptionManagersLogins } from 'store/entities/accounts/selectors';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getNotifications } from 'store/features/notifications-bar/selectors';
import { getCurrentSection } from 'store/features/routing/selectors';
import { getIsOnTrial, getTrialDaysLeft } from 'store/features/session/selectors';

import { BrowserNotificationsBar } from './browser-notifications-bar/BrowserNotificationsBar';
import { CodeNotInstalledBar } from './CodeNotInstalledBar';
import { ConnectionRestoredBar } from './ConnectionRestoredBar';
import { NOTIFICATIONS_TOP_BAR_TEST_ID } from './constants';
import { DownloadDesktopAppBar } from './DownloadDesktopAppBar';
import { EliminateLegacyStarterPlanBar } from './EliminateLegacyStarterPlanBar';
import {
  handleInstallCodeLinkClicked,
  handleNavigateToSubscription,
  handleSendCodeBtnClicked,
  handleGoToSettingsBtnClick,
} from './helpers';
import { useConnectionBarsVisibility } from './hooks/use-connection-bars-visibility';
import { NotAcceptingChatsBar } from './NotAcceptingChatsBar';
import { OfflineBar } from './OfflineBar';
import { ReconnectingBar } from './reconnecting-bar/ReconnectingBar';
import { SendPendingInvitesBar } from './SendPendingInvitesBar';
import { TrialExpiringBar } from './TrialExpiringBar';

import * as styles from './styles';

export const NotificationsBar: FC = () => {
  const dispatch = useDispatch();
  useConnectionBarsVisibility();
  const sectionName = useSelector(getCurrentSection);
  const isTrial = useSelector(getIsOnTrial);
  const isBillingSection = sectionName === Section.Subscription;
  const { handleStatusChange } = useChangeCurrentAgentStatus();
  const { data: isNonProfitLicense } = useLicenseProperty<BooleanNumericFlag>(
    PlatformNamespace.CORE,
    CoreProperty.NonProfit,
  );
  const isNonProfit = anyToBoolean(isNonProfitLicense);
  const activeNotifications = useSelector(getNotifications);
  const { data: isContinuousChatEnabled } = useLicenseProperty<BooleanNumericFlag>(
    PlatformNamespace.CORE,
    CoreProperty.ContinuousChatWidgetEnabled,
  );
  const isMessagingModeEnabled = anyToBoolean(isContinuousChatEnabled);
  const visibleNotifications = getVisibleNotifications(activeNotifications);
  const { isMobileViewEnabled } = useAppFrame();
  const [currentNotification, setCurrentNotification] = useState<TopBarNotificationType | null>(null);
  const viceOwnersEmails = useSelector(getSubscriptionManagersLogins);
  const daysLeft: number = useSelector(getTrialDaysLeft);

  const areNotificationsHidden =
    visibleNotifications.length === 0 || (isBillingSection && isTrial) || isMobileViewEnabled;
  const isTrialExpiringBarVisible = Boolean(
    !isNonProfit &&
      currentNotification &&
      [TopBarNotificationType.TrialWarning, TopBarNotificationType.TrialWarningImportant].includes(currentNotification),
  );

  const handleChangeStatusToOnline = (): void => {
    handleStatusChange({ status: LoginStatus.Online, eventPlace: EventPlace.NotificationBar });
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.StatusAway));
    trackEvent('Status changed to online', EventPlace.TopBar, { type: 'From bar' });
  };

  const handleSkipClick = (name: TopBarNotificationType): void => {
    dispatch(NotificationsBarActions.hideNotificationsBar(name));
    trackEvent('Skipped', EventPlace.TopBar, { topBar: name });
  };

  useEffect(() => {
    const shouldHideNotification = (isBillingSection && isTrial) || visibleNotifications.length === 0;
    const nextNotification = shouldHideNotification ? null : visibleNotifications[0]?.name;

    if (currentNotification !== nextNotification) {
      setCurrentNotification(nextNotification);
    }
  }, [isBillingSection, isTrial, visibleNotifications, currentNotification]);

  return (
    <div
      id="notifications-bar"
      className={cx(styles.notificationBarContainer, {
        [styles.hiddenNotificationBarContainer]: areNotificationsHidden,
      })}
      data-testid={NOTIFICATIONS_TOP_BAR_TEST_ID}
    >
      <OfflineBar isVisible={currentNotification === TopBarNotificationType.Offline} />
      <ReconnectingBar isVisible={currentNotification === TopBarNotificationType.Reconnecting} />
      <ConnectionRestoredBar isVisible={currentNotification === TopBarNotificationType.ConnectionRestored} />
      <CodeNotInstalledBar
        isVisible={currentNotification === TopBarNotificationType.InstallCode}
        onFollowLinkClicked={handleInstallCodeLinkClicked}
        onSendCodeBtnClicked={handleSendCodeBtnClicked}
        onSkip={handleSkipClick}
        handleGoToSettingsBtnClick={handleGoToSettingsBtnClick}
        {...visibleNotifications.find(({ name }) => name === TopBarNotificationType.InstallCode)?.props} // eslint-disable-line react/jsx-props-no-spreading
      />
      <BrowserNotificationsBar
        onSkip={handleSkipClick}
        shouldBeVisible={currentNotification === TopBarNotificationType.BrowserNotifications}
        {...visibleNotifications.find(({ name }) => name === TopBarNotificationType.BrowserNotifications)?.props} // eslint-disable-line react/jsx-props-no-spreading
      />
      <SendPendingInvitesBar isVisible={currentNotification === TopBarNotificationType.SendPendingInvites} />
      <TrialExpiringBar
        isVisible={isTrialExpiringBarVisible}
        onNavigateToSubscription={handleNavigateToSubscription}
        daysLeft={daysLeft}
        viceOwnersEmails={viceOwnersEmails}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...visibleNotifications.find(
          ({ name }) => name === (TopBarNotificationType.TrialWarning || TopBarNotificationType.TrialWarningImportant),
        )?.props}
      />
      <NotAcceptingChatsBar
        isVisible={currentNotification === TopBarNotificationType.StatusAway}
        onChangeStatusToOnlineClicked={handleChangeStatusToOnline}
        isMessagingModeEnabled={isMessagingModeEnabled}
        {...visibleNotifications.find(({ name }) => name === TopBarNotificationType.StatusAway)?.props} // eslint-disable-line react/jsx-props-no-spreading
      />
      <DownloadDesktopAppBar
        isVisible={currentNotification === TopBarNotificationType.DownloadDesktopApp}
        onSkip={handleSkipClick}
        name={TopBarNotificationType.DownloadDesktopApp}
      />
      <EliminateLegacyStarterPlanBar
        isVisible={currentNotification === TopBarNotificationType.EliminateLegacyStarterPlan}
        onSkip={handleSkipClick}
      />
    </div>
  );
};
