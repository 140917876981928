import { format } from 'date-fns';

import { uniqueId } from 'helpers/string';

import { type IIncomingMulticastTeamChatIncomingMessagePayload, type TeamChatNotification } from '../interfaces';

export function deserializeTeamChatNotification(
  payload: IIncomingMulticastTeamChatIncomingMessagePayload,
): TeamChatNotification {
  const { content } = payload;

  return {
    id: uniqueId(),
    authorName: content.author.name,
    authorAvatar: content.author.avatar,
    time: format(new Date(content.created_at), 'HH:mm'),
    message: content.message,
    url: content.channel_url,
  };
}
