// @ts-strict-ignore
import { ChatEventStatus } from 'constants/chat-event-status';
import { ChatEventType } from 'constants/chat-event-type';
import { CustomFormType } from 'constants/custom-form-type';
import { SurveyType } from 'constants/survey-type';
import { UserType } from 'constants/user-type';
import { getConfig } from 'helpers/config';
import { parseSurveyFieldValue, type IFilledFormField } from 'helpers/filled-form';
import { type KeyMap } from 'helpers/interface';
import type { ChatUserResultType } from 'interfaces/api/chat';
import type { ThreadEventResult } from 'interfaces/api/event/thread';
import { FormId } from 'services/api/forms/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import type { Event } from 'services/connectivity/agent-chat-api/types/event';
import { extractCommentFromEventText, shouldCheckEventForComment } from 'services/serialization/chat-rating-event';
import { getPropertyValue } from 'services/serialization/property';
import { deserializeRichMessageDetails } from 'services/serialization/rich-message';
import { getThreadEntityTimestampInMs } from 'services/serialization/timestamp';
import type {
  ChatEventAuthor,
  ChatEventEntity,
  IMessage,
  ISystemMessage,
  IRichMessage,
  ISurveyMessage,
  IAttachmentMessage,
} from 'store/entities/chats/interfaces';
import { getThreadAgentId } from 'store/entities/chats/selectors';

import { deserializeEventUserType } from './deserialize-event-user-type';
import { type IEventDetails, type ICurrentAgent } from './helpers/common';
import { getCustomFormType } from './helpers/custom-form';
import { getChatEventType } from './helpers/get-chat-event-type';

const CHAT_SENTIMENT_NAMESPACE = getConfig().chatSentimentNamespace;
const CLIENT_ID = getConfig().accountsClientId;

const fromTypeToEnum: Record<string, SurveyType> = {
  prechat: SurveyType.Pre,
  postchat: SurveyType.Post,
  ticket: SurveyType.Ticket,
};

const formIdToSurveyType: Record<string, SurveyType> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ask_for_email: SurveyType.Custom,
};

export function deserializeChatMessage(
  event: ThreadEventResult,
  authorType: ChatEventAuthor,
  status: ChatEventStatus,
  wasSeen: boolean,
): IMessage {
  const { author_id: authorId, id, text, custom_id: customId } = event;

  const message: IMessage = {
    authorId,
    authorType,
    id,
    status,
    wasSeen,
    text,
    timestampInMs: getThreadEntityTimestampInMs(event),
    type: ChatEventType.Message,
    messageId: customId,
    properties: {},
  };

  if (event.properties?.translation) {
    message.translation = {
      sourceLangCode: getPropertyValue(event.properties, 'translation', 'source_lang_code', ''),
      targetLangCode: getPropertyValue(event.properties, 'translation', 'target_lang_code', ''),
      targetMessage: getPropertyValue(event.properties, 'translation', 'target_message', ''),
    };
  }

  if (event.properties?.[CLIENT_ID]) {
    message.properties[CLIENT_ID] = {
      messageReaction: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction', ''),
      messageReactionAuthor: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction_author'),
    };
  }

  if (event.properties?.[CHAT_SENTIMENT_NAMESPACE]) {
    message.properties[CHAT_SENTIMENT_NAMESPACE] = {
      sentimentScore: getPropertyValue(event.properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_score'),
      sentimentMagnitude: getPropertyValue(event.properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_magnitude'),
      sentimentUnknown: getPropertyValue(event.properties, CHAT_SENTIMENT_NAMESPACE, 'sentiment_error_language'),
    };
  }

  if (event.properties?.chats) {
    message.properties.chats = {
      formatting: getPropertyValue(event.properties, 'chats', 'formatting', true),
    };
  }
  if (event.properties?.lc2) {
    message.properties.lc2 = {
      welcomeMessage: getPropertyValue(event.properties, 'lc2', 'welcome_message', false),
    };
  }

  return message;
}

export function deserializeSystemMessage(event: ThreadEventResult): ISystemMessage {
  const { system_message_type: subType, id, text: eventText, text_vars: textVariables } = event;

  const systemMessageBase = {
    id,
    subType,
    text: eventText,
    timestampInMs: getThreadEntityTimestampInMs(event),
    type: ChatEventType.Event,
    textVariables,
  };

  if (shouldCheckEventForComment(subType)) {
    const { text, comment } = extractCommentFromEventText(event.text);

    if (comment) {
      return {
        ...systemMessageBase,
        text,
        textVariables: { ...(textVariables || {}), comment },
      };
    }
  }

  return systemMessageBase;
}

export function deserializeRichMessage(
  event: ThreadEventResult,
  authorType: ChatEventAuthor,
  status: ChatEventStatus,
  wasSeen: boolean,
): IRichMessage {
  const { author_id: authorId, id, text, template_id: template, elements } = event;

  const message = {
    authorId,
    authorType,
    id,
    status,
    wasSeen,
    text,
    timestampInMs: getThreadEntityTimestampInMs(event),
    type: ChatEventType.RichMessage,
    richMessageTemplate: template,
    richMessageDetails: deserializeRichMessageDetails(elements),
    properties: {},
  };

  if (event.properties?.[CLIENT_ID]) {
    message.properties[CLIENT_ID] = {
      messageReaction: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction', ''),
      messageReactionAuthor: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction_author'),
    };
  }

  return message;
}

function getSurveyType(event: ThreadEventResult): SurveyType | undefined {
  const { properties, form_id: formId } = event;
  const formTypeLC2 = fromTypeToEnum[getPropertyValue<string>(properties, 'lc2', 'form_type')];

  return formTypeLC2 !== undefined ? formTypeLC2 : formIdToSurveyType[formId];
}

function deserializeCustomFormSurvey(event: ThreadEventResult, authorType: ChatEventAuthor): ISurveyMessage {
  const customFormType = getCustomFormType(event);

  const { id, author_id: authorId, fields } = event;
  const [field] = fields;
  let surveyResponses = [];

  switch (customFormType) {
    case CustomFormType.AskForEmail: {
      surveyResponses = [
        {
          answer: parseSurveyFieldValue(field),
          question: 'E-mail:',
          id: field.id,
        },
      ];
    }
  }

  return {
    id,
    type: ChatEventType.FilledForm,
    timestampInMs: getThreadEntityTimestampInMs(event),
    surveyType: SurveyType.Custom,
    customFormType,
    authorId,
    authorType,
    responses: surveyResponses,
  };
}

export function deserializeSurvey(event: ThreadEventResult, authorType: ChatEventAuthor): ISurveyMessage | null {
  const { id, author_id: authorId } = event;

  const surveyType = getSurveyType(event);

  switch (surveyType) {
    case SurveyType.Custom: {
      return deserializeCustomFormSurvey(event, authorType);
    }
    default:
      break;
  }

  if (surveyType === undefined) {
    return null;
  }

  const fields = event.fields.filter((field) => !['title', 'information'].includes(field.type));
  const surveyResponses = fields.map((field: IFilledFormField) => ({
    answer: parseSurveyFieldValue(field),
    question: field.label,
    id: field.id,
    type: field.type,
  }));

  return {
    id,
    type: ChatEventType.FilledForm,
    timestampInMs: getThreadEntityTimestampInMs(event),
    surveyType,
    authorId,
    authorType,
    responses: surveyResponses,
  };
}

export function deserializeAttachmentMessage(
  event: ThreadEventResult,
  authorType: ChatEventAuthor,
  status: ChatEventStatus,
  wasSeen: boolean,
): IAttachmentMessage {
  const { author_id: authorId, content_type: contentType, id, size, url, width, height, properties, name } = event;
  const safetyConfirmation = getPropertyValue<boolean>(properties, 'lc2', 'file_safety_confirmation');
  const messageReactionsProperty = {};

  if (event.properties?.[CLIENT_ID]) {
    messageReactionsProperty[CLIENT_ID] = {
      ...event.properties?.[CLIENT_ID],
      messageReaction: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction', ''),
      messageReactionAuthor: getPropertyValue(event.properties, CLIENT_ID, 'message_reaction_author'),
    };
  }

  return {
    name,
    authorId,
    authorType,
    id,
    status,
    wasSeen,
    contentType,
    size,
    url,
    width,
    height,
    safetyConfirmation,
    timestampInMs: getThreadEntityTimestampInMs(event),
    type: ChatEventType.Attachmment,
    properties: messageReactionsProperty,
  };
}

export function getIsMarkdownEvent(event: ThreadEventResult): boolean {
  if (event.form_type === FormId.AskForEmail) {
    return true;
  }

  return false;
}

export function deserializeInactivityMessage(event: ThreadEventResult, threadId: string, wasSeen: boolean): IMessage {
  const { id, fields } = event;
  const eventText = fields[0]?.label;

  const state = AppStateProvider.getState();
  const authorId = getThreadAgentId(state, threadId);

  return {
    id,
    type: ChatEventType.Message,
    timestampInMs: getThreadEntityTimestampInMs(event),
    text: eventText,
    authorId,
    authorType: UserType.Automatic,
    wasSeen,
    isMarkdownEvent: getIsMarkdownEvent(event),
    status: ChatEventStatus.Delivered,
  };
}

function deserializeForm(
  event: ThreadEventResult,
  authorType: UserType,
  status: ChatEventStatus,
  wasSeen: boolean,
  threadId: string,
): IMessage {
  const customFormType = getCustomFormType(event);

  switch (customFormType) {
    case CustomFormType.InactivityMessage: {
      return deserializeInactivityMessage(event, threadId, wasSeen);
    }

    default:
      break;
  }

  return null;
}

export function deserializeThreadEvent(
  event: ThreadEventResult | Event,
  chatUsers: ChatUserResultType[],
  currentAgentId: string,
  resolveWasEventSeen: (event: IEventDetails, currentAgent: ICurrentAgent, chatUsers: ChatUserResultType[]) => boolean,
  threadId: string,
): ChatEventEntity {
  const { type } = event;
  const authorId = 'author_id' in event ? event.author_id : undefined;
  const chatEventType = getChatEventType(type);
  const authorUser = chatUsers.find((u) => u.id === authorId);
  const currentAgentUser = chatUsers.find((u) => u.id === currentAgentId);
  const authorType = deserializeEventUserType(event, authorUser);
  const currentAgentAuthorType = deserializeEventUserType(event, currentAgentUser);
  const currentAgent = {
    id: currentAgentId,
    userType: currentAgentAuthorType,
  };
  const currentEvent = {
    authorId,
    timestampInMs: getThreadEntityTimestampInMs(event),
  };
  const wasSeen = resolveWasEventSeen(currentEvent, currentAgent, chatUsers);
  const status = wasSeen ? ChatEventStatus.Read : ChatEventStatus.Delivered;

  const deserializers: KeyMap<
    (
      event: ThreadEventResult | Event,
      authorType: ChatEventAuthor,
      status: ChatEventStatus,
      wasSeen: boolean,
      threadId: string,
    ) => ChatEventEntity
  > = {
    [ChatEventType.Message]: deserializeChatMessage,
    [ChatEventType.Event]: deserializeSystemMessage,
    [ChatEventType.RichMessage]: deserializeRichMessage,
    [ChatEventType.FilledForm]: deserializeSurvey,
    [ChatEventType.Form]: deserializeForm,
    [ChatEventType.File]: deserializeAttachmentMessage,
  };

  return deserializers[chatEventType]?.(event, authorType as ChatEventAuthor, status, wasSeen, threadId);
}
