import {
  type IIncomingMulticastBotEnginePayload,
  type IIncomingMulticastLc2IwcsPayload,
  type IIncomingMulticastLC2Payload,
  type IncomingMulticastPayload,
  type IIncomingMulticastAgentMessageSneakPeekPayload,
  type IIncomingMulticastInsightsPayload,
  type IIncomingMulticastInactivityReasonsUpdatedPayload,
  type IIncomingMulticastTeamChatIncomingMessagePayload,
} from '../../interfaces';
import { IncomingMulticastType } from '../constants';

export const isMulticastLc2Payload = (payload: IncomingMulticastPayload): payload is IIncomingMulticastLC2Payload => {
  return (payload as IIncomingMulticastLC2Payload).type === IncomingMulticastType.Lc2;
};

export const isMulticastLc2IwcsPayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastLc2IwcsPayload => {
  return (payload as IIncomingMulticastLc2IwcsPayload).type === IncomingMulticastType.Lc2Iwcs;
};

export const isMulticastBotEnginePayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastBotEnginePayload => {
  return !!(payload as IIncomingMulticastBotEnginePayload).content.bot_engine;
};

export const isMulticastInsightsPayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastInsightsPayload => {
  return !!(payload as IIncomingMulticastInsightsPayload).content.insights;
};

export const isMulticastAgentMessageSneakPeekPayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastAgentMessageSneakPeekPayload =>
  !!(payload as IIncomingMulticastAgentMessageSneakPeekPayload).content.agent_message_sneak_peek;

export const isMulticastInactivityReasonsUpdatedPayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastInactivityReasonsUpdatedPayload =>
  !!(payload as IIncomingMulticastInactivityReasonsUpdatedPayload).content.custom_statuses;

export const isMulticastTeamChatIncomingMessagePayload = (
  payload: IncomingMulticastPayload,
): payload is IIncomingMulticastTeamChatIncomingMessagePayload =>
  !!(payload as IIncomingMulticastTeamChatIncomingMessagePayload).content?.channel_url &&
  typeof (payload as IIncomingMulticastTeamChatIncomingMessagePayload).content?.message === 'string' &&
  typeof (payload as IIncomingMulticastTeamChatIncomingMessagePayload).content?.author?.name === 'string';
