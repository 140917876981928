import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKey } from 'constants/query-key';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { openInNewCard } from 'helpers/routing';
import { getQueryClient } from 'services/query-client/client';
import { getNotifications } from 'store/features/notifications-bar/selectors';

export type TeamChatNotification = {
  id: string;
  authorName: string;
  authorAvatar: string;
  time: string;
  message: string;
  url: string;
};

export const useTeamchatNotifications = (): {
  hasVisibleNotifications: boolean;
  teamchatNotifications: TeamChatNotification[];
  removeNotifications: () => void;
  handleNotificationClick: (notification: TeamChatNotification) => void;
} => {
  const notifications = useSelector(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  const queryClient = getQueryClient();

  const { data: teamchatNotifications = [] } = useQuery(
    [QueryKey.TeamChatNotifications],
    () => queryClient.getQueryData<TeamChatNotification[]>([QueryKey.TeamChatNotifications]) || [],
  );

  const removeNotifications = (): void => {
    queryClient.setQueryData([QueryKey.TeamChatNotifications], () => []);
  };

  const handleNotificationClick = (notification: TeamChatNotification): void => {
    openInNewCard(notification.url);
    removeNotifications();
  };

  return {
    hasVisibleNotifications,
    teamchatNotifications,
    removeNotifications,
    handleNotificationClick,
  };
};
