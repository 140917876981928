import { useCallback, type FC } from 'react';

import { SideNavigationGroup, SideNavigationItem } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { AppsEvent, AppsSection } from 'constants/apps/events';
import { navigateToMarketplace, preloadMarketplacePage } from 'helpers/routing';
import { trackAppsSectionEvent } from 'services/event-tracking';
import { AppsViewActions } from 'store/views/apps/actions';
import { getCurrentMarketplaceRoute } from 'store/views/apps/selectors';

import { MARKETPLACE_LINKS } from '../constants';
import { checkIfActive } from '../helpers';
import { animationOptimizationStyles } from '../your-apps-list/styles';

const [, ...campaignLinks] = MARKETPLACE_LINKS;

export const IntegrationsList: FC = () => {
  const currentMarketplaceRoute = useSelector(getCurrentMarketplaceRoute);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (link: string): void => {
      navigateToMarketplace(link);
      dispatch(AppsViewActions.setMarketplaceRoute(link));
      trackAppsSectionEvent(AppsEvent.ExternalIntegrationsClicked, AppsSection.SideNavigation);
    },
    [dispatch],
  );

  return (
    <SideNavigationGroup>
      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {campaignLinks.map(({ label, link, icon: Icon }) => (
        <SideNavigationItem
          isActive={checkIfActive(link, currentMarketplaceRoute)}
          key={link}
          url={link}
          leftNode={<Icon />}
          label={label}
          onClick={() => handleClick(link)}
          onItemHover={() => preloadMarketplacePage(link)}
          className={animationOptimizationStyles}
        />
      ))}
    </SideNavigationGroup>
  );
};
