import type { RequestResult } from 'interfaces/api/client';
import { type IApiSession } from 'interfaces/session';

import { BaseApi } from '../base-api';

export class SessionApi extends BaseApi {
  protected readonly prefix = 'sessions';

  fetchAll = (): Promise<RequestResult<IApiSession[]>> => this.get('');
  deleteSingle = (sessionId: string): Promise<RequestResult<void>> => this.delete(`${sessionId}`);
  deleteAll = (): Promise<RequestResult<void>> => this.delete('');
}
