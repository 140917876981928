import { type KeyMap } from 'helpers/interface';
import { type ChatEventEntity } from 'store/entities/chats/interfaces';

export const getIsTheSameMessage = (threadEvents: KeyMap<ChatEventEntity>, messageId: string): boolean => {
  const threadArray = Object.values(threadEvents);
  const threadLastMessage = threadArray[threadArray.length - 1];
  const isTheSameMessage = threadLastMessage?.id === messageId;

  return isTheSameMessage;
};
