// @ts-strict-ignore
import { QueryKey } from 'constants/query-key';
import { type TeamChatNotification } from 'hooks/use-teamchat-notifications';
import { type IAppState } from 'services/app-state-provider';
import { type ListInactivityReasonsResponse } from 'services/connectivity/custom-status-api/inactivity-reasons/types';
import { getQueryClient } from 'services/query-client/client';
import { ApplicationsActions } from 'store/entities/applications/actions';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';

import { type IncomingMulticastPayload } from '../interfaces';
import { deserializeTeamChatNotification } from '../serialization/deserialize-teamchat-notification';

import { IncomingMulticastEventName } from './constants';
import {
  isMulticastAgentMessageSneakPeekPayload,
  isMulticastLc2Payload,
  isMulticastInactivityReasonsUpdatedPayload,
  isMulticastTeamChatIncomingMessagePayload,
} from './helpers/incoming-multicast';

/**
 * Handle specific cases comes from `incoming_multicast` event
 * 1. Fetch data from dev platform after integration uninstall
 * 2. Update sneak peek status
 * @param payload incoming_multicast message payload
 */
export function handleIncomingMulticast(payload: IncomingMulticastPayload, store: IAppState): void {
  if (isMulticastLc2Payload(payload)) {
    const {
      content: { name },
    } = payload;
    /**
     * 1. Fetch data from dev platform after integration uninstall
     * It will refresh the available intergations in the message box, preventing to use uninstalled ones
     */
    if (name === IncomingMulticastEventName.IntegrationDisable) {
      store.dispatch(ApplicationsActions.refreshInstalledApplicationsData({ notifyMarketplace: true }));
    }
  }

  /**
   * 2. Update sneak peek status
   */
  if (isMulticastAgentMessageSneakPeekPayload(payload)) {
    const { content } = payload;

    store.dispatch(
      ChatsEntitiesActions.setAgentSneakPeek({
        isTyping: content.is_typing,
        threadId: content.thread_id,
        text: content.message,
      }),
    );
  }

  if (isMulticastInactivityReasonsUpdatedPayload(payload)) {
    const queryClient = getQueryClient();
    queryClient.setQueryData<ListInactivityReasonsResponse>(
      [QueryKey.InactivityReasons],
      () => payload.content.custom_statuses,
    );
    void queryClient.invalidateQueries([QueryKey.InactivityReasons]);
    void queryClient.invalidateQueries([QueryKey.MyInactivity]);
  }

  if (isMulticastTeamChatIncomingMessagePayload(payload)) {
    const queryClient = getQueryClient();
    queryClient.setQueryData([QueryKey.TeamChatNotifications], (currentNotifications: TeamChatNotification[] = []) => [
      ...currentNotifications,
      deserializeTeamChatNotification(payload),
    ]);
  }
}
