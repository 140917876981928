import { BaseApi } from '../base-api';

import type { TagDTO } from './interfaces';

export class TagApi extends BaseApi {
  protected readonly prefix = 'tags';
  protected readonly version = '2';

  fetchAll = () => this.get<TagDTO[]>('');
}
