import { type IFeaturesResponse, type Features, FeatureNames, type ResponseFeature } from './interfaces';

const knownFeatures = new Map<string, string>([
  ['testFeature', 'test_feature'],
  [FeatureNames.ChatsListPagination, 'chats_list_pagination'],
  [FeatureNames.RichTextEditor, 'rich_text_editor'],
  [FeatureNames.DisturbingContentDetection, 'disturbing_content_detection'],
  [FeatureNames.CustomerIntentEnabled, 'customer_intent_enabled'],
  [FeatureNames.ReviewsReportEnabled, 'reviews_report_enabled'],
  [FeatureNames.InsightsReportsEnabled, 'recent_insights_enabled'],
  [FeatureNames.ShowTeamChatProduct, 'show_teamchat_product'],
  [FeatureNames.AISuggestions, 'ai_suggestions'],
  [FeatureNames.ProductSwitcher, 'product_switcher'],
  [FeatureNames.PriorityChat, 'priority_chat'],
  [FeatureNames.KnowledgeHubAddWebsiteOnboarding, 'knowledge_hub_add_website_onboarding'],
  [FeatureNames.AICannedInChips, 'ai_canned_in_chips'],
  [FeatureNames.ReplySuggestionsFullMessageContext, 'rs-full-messages-context'],
  [FeatureNames.KnowledgeHubPdfCustomLimit, 'kh_custom_pdf_limit'],
]);

export const defaultFeatures: Features = {
  [FeatureNames.TestFeature]: false,
  [FeatureNames.ChatsListPagination]: false,
  [FeatureNames.RichTextEditor]: false,
  [FeatureNames.DisturbingContentDetection]: false,
  [FeatureNames.CustomerIntentEnabled]: false,
  [FeatureNames.ReviewsReportEnabled]: true,
  [FeatureNames.InsightsReportsEnabled]: false,
  [FeatureNames.ShowTeamChatProduct]: false,
  [FeatureNames.AISuggestions]: false,
  [FeatureNames.ProductSwitcher]: false,
  [FeatureNames.PriorityChat]: false,
  [FeatureNames.KnowledgeHubAddWebsiteOnboarding]: false,
  [FeatureNames.AICannedInChips]: false,
  [FeatureNames.ReplySuggestionsFullMessageContext]: false,
  [FeatureNames.KnowledgeHubPdfCustomLimit]: {
    enabled: false,
    limit: 0,
  },
};

const CustomFeaturesSerializer = {
  [FeatureNames.KnowledgeHubPdfCustomLimit]: (feature: ResponseFeature) => {
    return {
      enabled: feature.value,
      limit: feature.variant?.payload?.value ? parseInt(feature.variant.payload.value, 10) : 0,
    };
  },
};

export function deserializeFeatures(features: IFeaturesResponse): Features {
  return Object.entries(defaultFeatures).reduce((result, [key, value]) => {
    const feature = features.feature_flags.find(({ name }) => name === knownFeatures.get(key));

    if (feature && key in CustomFeaturesSerializer) {
      result[key] = CustomFeaturesSerializer[key as keyof typeof CustomFeaturesSerializer](feature);
    } else {
      result[key] = feature?.value ?? value;
    }

    return result;
  }, {} as Features);
}
