import { BaseApi } from '../base-api';

import {
  type ILegacyApplicationUpdateResult,
  type ILegacyApplicationFetchResult,
  type ILegacyIntegrationUpdatePayload,
} from './interfaces';

export class LegacyIntegrationsApi extends BaseApi {
  
  protected readonly version = '2';
  protected readonly prefix = 'integrations';

  fetch = (applicationId: string) => this.get<ILegacyApplicationFetchResult>(applicationId);
  update = (applicationId: string, payload: ILegacyIntegrationUpdatePayload) =>
    this.put<ILegacyApplicationUpdateResult>(applicationId, payload);
}
