import { type FC } from 'react';

import { OpenInNew } from '@livechat/design-system-icons';
import { Icon, NavigationTopBarAlert } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { TopBarNotificationType } from 'constants/notifications';
import { EventPlace } from 'helpers/analytics';
import { openInNewCard } from 'helpers/routing';
import { useProductData } from 'hooks/api/use-product-data';
import { useAgentCustomProperty } from 'hooks/use-agent-custom-property';
import { trackEvent } from 'services/event-tracking';
import * as selectors from 'store/entities/agents/selectors';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

const PRICING_PAGE =
  'https://www.livechat.com/pricing/?utm_source=my.livechat.com&utm_medium=referral&utm_campaign=starter-sunset ';

type Props = {
  isVisible: boolean;
  onSkip(name: TopBarNotificationType): void;
};

export const EliminateLegacyStarterPlanBar: FC<Props> = ({ onSkip, isVisible }) => {
  const dispatch = useDispatch();
  const { subscriptions } = useProductData();
  const [, setBannerSeen] = useAgentCustomProperty(AgentCustomPropertyName.EliminateLegacyStarterPlanBannerSeen);
  const { permission } = useSelector(selectors.getLoggedInAgent);
  const { plan, quantity: seats } = subscriptions.livechat ?? {};

  const handleActionClick = (eventName: string): void => {
    setBannerSeen('1');
    trackEvent(eventName, EventPlace.TopBar, { permission, plan, seats });
    dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.EliminateLegacyStarterPlan));
    onSkip(TopBarNotificationType.EliminateLegacyStarterPlan);
  };

  const handleLearnMoreButtonClick = (): void => {
    handleActionClick('Eliminate legacy starter plan banner learn more clicked');
    openInNewCard(PRICING_PAGE);
  };

  const handleSkip = (): void => {
    handleActionClick('Eliminate legacy starter plan Banner skip clicked');
  };

  return (
    <NavigationTopBarAlert
      kind="info"
      isVisible={isVisible}
      primaryCta={{
        label: 'Learn more',
        onClick: handleLearnMoreButtonClick,
        size: 'compact',
        icon: <Icon source={OpenInNew} />,
      }}
      closeButton={{
        onClick: handleSkip,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'aria-label': 'Close the prompt about legacy Starter plan changes',
      }}
    >
      Starter is now a single-user plan. On your renewal after Mar 1, you&apos;ll be moved to Team with 25% off.
    </NavigationTopBarAlert>
  );
};
