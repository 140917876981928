// @ts-strict-ignore
import { UserGuidedTourType } from 'constants/user-guided-tour-type';

import { eventTourName } from './constants';
import { customSegmentsSteps } from './customers-custom-segments/steps';
import { homeScreenSteps } from './home-screen/steps';
import { type TourStep } from './interfaces';
import { reportViewsSteps } from './report-views/steps';

export const getTourStepsCount = (tourId: UserGuidedTourType): number => getTourSteps(tourId).length;

export const getTourEventName = (tourId: UserGuidedTourType): string => eventTourName[tourId] || tourId;

export const getTourStepNumber = (tourId: UserGuidedTourType, stepId: string): number =>
  getTourSteps(tourId).findIndex((s) => s.id === stepId) + 1;

export const getTourSteps = (tourId: UserGuidedTourType): TourStep[] => {
  const tourStepsConfig = tourSteps[tourId];

  if (tourStepsTypeguard(tourStepsConfig)) {
    return tourStepsConfig;
  }

  return tourStepsConfig();
};

const tourSteps: Partial<Record<UserGuidedTourType, TourStep[] | (() => TourStep[])>> = {
  [UserGuidedTourType.CustomSegments]: customSegmentsSteps,
  [UserGuidedTourType.ReportViews]: reportViewsSteps,
  [UserGuidedTourType.Home]: homeScreenSteps,
};

function tourStepsTypeguard(tourSteps: unknown): tourSteps is TourStep[] {
  return Array.isArray(tourSteps as TourStep[]);
}
