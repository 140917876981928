import { type TourStep } from '../interfaces';

import { ButtonStep, CustomSegmentsStep, ManageSegmentsStep } from './tour-steps';

import * as styles from './styles';

export enum CustomSegmentsTourSteps {
  Button = 'save-segment-button',
  CustomSegments = 'custom-segments',
  ManageSegments = 'manage-segments',
}

export const customSegmentsSteps: TourStep[] = [
  {
    id: CustomSegmentsTourSteps.Button,
    stepContent: ButtonStep,
  },
  {
    id: CustomSegmentsTourSteps.CustomSegments,
    stepContent: CustomSegmentsStep,
    elementClassName: styles.customSegmentsStepStyles,
    placement: 'bottom',
  },
  {
    id: CustomSegmentsTourSteps.ManageSegments,
    stepContent: ManageSegmentsStep,
    elementClassName: styles.customSegmentsStepStyles,
    placement: 'left-start',
  },
];
