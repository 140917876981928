import { type FC, useCallback } from 'react';

import { NavigationTopBarAlert, Text } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';

import { getSellingExperienceApps } from 'helpers/applications';
import { type ISellingExperienceApp } from 'store/entities/applications/interfaces';
import { getInstalledApplicationsForChatNotInstalledBanner } from 'store/entities/applications/selectors';

import * as styles from './styles';

interface IProps {
  onGoToSettingsButtonClick: (slug: string) => void;
  isVisible: boolean;
}

export const AutomatedCodeInstallationAvailableBar: FC<IProps> = ({ onGoToSettingsButtonClick, isVisible }) => {
  const applications = useSelector(getInstalledApplicationsForChatNotInstalledBanner);

  const applicationId = applications[0]?.id;

  const handleGoToSettingsButtonClick = useCallback(() => {
    if (applications[0]?.slug) {
      onGoToSettingsButtonClick(applications[0].slug);
    }
  }, [onGoToSettingsButtonClick, applications]);

  const sellingExperienceApps = getSellingExperienceApps();

  const applicationName =
    sellingExperienceApps.find((app: ISellingExperienceApp) => app.id === applicationId)?.name ?? 'application';

  return (
    <NavigationTopBarAlert
      kind="warning"
      isVisible={isVisible}
      primaryCta={{
        label: 'Go to Settings',
        onClick: handleGoToSettingsButtonClick,
        size: 'compact',
        kind: 'secondary',
      }}
    >
      <Text as="span" bold className={styles.codeNotInstalledBarText}>
        Chat widget is not visible to your customers. Add chat widget to your {applicationName} store to start chatting
      </Text>
    </NavigationTopBarAlert>
  );
};
